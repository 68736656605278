/**
 * @file
 * Section
 *
 * Base styles for all section content.
 */

.section {
  margin: 2rem 0;

  @media (min-width: $break-large) {
    margin: 3rem 0;
  }

  &__header {
    margin: 0 0 1.1rem;

    @media (min-width: $break-medium) {
      margin: 0 0 1.6rem;
    }
  
    @media (min-width: $break-large) {
      margin: 0 0 1.3rem;
    }

    h2 {
      margin: 0;
    }
  }

  &__intro {
    margin: 0 0 2.5rem;
    font-size: 1.6rem;

    @media (min-width: $break-medium) {
      font-size: 1.8rem;
    }

    @media (min-width: $break-large) {
      margin: 0 0 3.9rem;
    }
  }

  &__cta a{
    @include cta-button;
    text-align: center;
  }
}
