.learn-stats {
  position: relative;
  padding: 4rem 0 3.5rem;
  color: $color-white;

  @media (min-width: $break-medium) {
    padding: 4.5rem 0 3.5rem;
  }

  @media (min-width: $break-large) {
    padding: 10.2rem 0 5.2rem;
  }

  &:before {
    content: '';
    @include fullwidth-with-sidebar;
    background-color: $color-blue;
  }

  .text-box {
    background-color: $color-blue;
  }

  &__header {
    margin: 0 0 3.1rem;

    @media (min-width: $break-medium) {
      margin: 0 0 2.5rem;
    }

    @media (min-width: $break-large) {
      margin: 0 0 6.3rem;
      padding: 0;
    }

    .lined-header__title {
      margin: 0;
    }
  }

  .lined-header__subtile {
    margin: 0 0 0.6rem;

    @media (min-width: $break-medium) {
      margin: 0 0 0.7rem;
    }

    @media (min-width: $break-large) {
      margin: 0 0 1.7rem;
    }

    &::before {
      @media (min-width: $break-medium) {
        top: 1.1rem;
      }
    }
  }

  &__list {
    @media (min-width: $break-medium) {
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;
    }

    @media (min-width: $break-large) {
      flex-wrap: nowrap;
    }
  }

  &__item {
    padding: 1.2rem 0 0;
    margin: 0 0 2.1rem;
    border-top: 0.5rem solid $color-yellow;

    @media (min-width: $break-medium) {
      width: calc(50% - 1rem);
      padding: 1.7rem 0 0;
      margin: 0 0 2.8rem;
    }

    @media (min-width: $break-large) {
      width: calc(100% / 3);
    }

    &:last-child {
      margin: 0;
    }
  }

  &__title {
    margin: 0 0 1.6rem;
    font-weight: 300;
    font-size: 2.2rem;
    line-height: 2.6rem;

    @media (min-width: $break-medium) {
      margin: 0 0 2rem;
      font-size: 3rem;
      line-height: 3rem;
    }

    @media (min-width: $break-large) {
      font-size: 3.6rem;
      line-height: 4.2rem;
    }
  }

  &__value {
    display: flex;
    margin: 0 0 1.2rem;

    & + .learn-stats__value {
      @media (min-width: $break-medium) {
        margin-top: 2.1rem;
      }
    }

    & + {
      .learn-stats__cta {
        @media (min-width: $break-medium) {
          margin-top: 3.5rem;
        }
      }
    }
  }

  &__number {
    min-width: 3rem;
    padding: 0.5rem 0.5rem 0.4rem;
    margin: 0 1.2rem 0 0;
    letter-spacing: -0.6px;
    background-color: $color-yellow;
    border-radius: 2rem;
    color: $color-dark-navy;
    font-weight: 900;
    text-align: center;

    @media (min-width: $break-medium) {
      min-width: 3.5rem;
      padding: 0.7rem 0.5rem 0.6rem;
      font-size: 2rem;
      line-height: 2.2rem;
      letter-spacing: -0.1rem;
    }
  }

  &__stat {
    padding: 0.5rem 0 0;
    font-weight: 700;

    @media (min-width: $break-medium) {
      font-size: 2rem;
    }
  }

  &__body {
    margin: 0 0 1.1rem;

    @media (min-width: $break-medium) {
      margin: 0 0 1.4rem;
      font-size: 1.8rem;
    }
  }

  &__cta {
    letter-spacing: 0.4px;
  }
}