body, html {
  scroll-behavior: smooth;
}

.card-carousel {
 
  position: relative;
  color: $color-white;

  &::before {
    content: '';
    @include fullwidth-with-sidebar();
    background-color: $color-dark-navy;
  }

  @media (min-width: $break-medium) and (max-width: $break-large - 1)  {
    &.is-scrollable.sticky {
      .card-carousel__header {
        position: absolute;
        z-index: 100;
      }

      .type-text {
        top: auto !important;
      }

      .card-carousel__wrapper.is-scrollable.inview:before {
        content: none;
      }
    }
    .card-carousel__wrapper.is-scrollable.inview:before {
      content: none;
    }
    &.inview-md {
      .card-carousel__header {
        position: sticky;
        top: 0;
        left: 0;
        z-index: 100;
      }

      .card-carousel__text {
        position: fixed;
        bottom: 0;
        right: 0;
      }

      .type-text,
      .card-carousel__dots {
        position: fixed;
      }

      .card-carousel__dots {
        transform: translateY(25px);
      }
    }

  }

  &__header {
    padding: 6rem 0;

    @media (min-width: $break-medium) {
      padding: 8rem 0;
    }

    @media (min-width: $break-large) {
      padding: 10rem 3rem; 
    }
  

    &::before {
      content: '';
      @include fullwidth-with-sidebar();
      background-color: $color-dark-navy;
    }
  }

  &__dots {
    position: relative;
    top: 36.5rem;
    z-index: 4000;
    padding-left: 2rem;

    @media (min-width: $break-medium) {
      margin-left: 50%;
      top: 3rem;
      padding-left: 3rem;
      position: absolute;

    }

    @media (min-width: $break-large) {
      top: 8rem;
      padding-left: 4rem;
      margin-left: calc(100% - 410px);
    }

    a {
      width: 10px;
      height: 10px;
      border-radius: 100em;
      border: 1px solid white;
      display: block;
      margin-bottom: 5px;
      background: transparent;

      @media (min-width: $break-medium) {
        border-color: $color-blue;
        @include focus-dark-navy();
      }

      &.active {
        &:after {
          content: "";
          color: $color-blue;
          position: absolute;
          border: 4px solid;
          border-radius: 100%;
        }

        @media (min-width: $break-medium) {
          background-color: $color-black;
        }
      }
    }
  }

  &__inner {
    height: 100%;
    @media (min-width: $break-medium) {
      display: flex;
    }
  }

  &__image {
    position: relative;


    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $color-blue;
      opacity: 0;
      transition: all 300ms;
      mix-blend-mode: color;
    }
    @media (min-width: $break-medium) {
      width: 50%;
    }

    @media (min-width: $break-large) {
      width: calc(100% - 410px);
    }
  }

  img {
    height: 100%;
  }

  .card-carousel__item:first-child {
    .card-carousel__text {
      @media (max-width: $break-medium - 1) {
        min-height: 34vw;
        max-height: 1000px;
      }
    }
  }

  &__text {
    background-color: $color-white;
    padding: 2.5rem 2rem;
    width: 100%;

    .card-carousel__text__wrap {
      opacity: 1;
    }

    @media (min-width: $break-medium) {
      padding: 4rem;
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
    }

    @media (min-width: $break-large) {
      width: 410px;
    }

    a {
      @include focus-dark-navy();
    }

    h3 {
      margin-top: 0;
      text-transform: none;
      line-height: 1.1;

      @extend h4;
      color: $color-dark-navy;
      background: none;

      @media (min-width: $break-medium) {
        margin-bottom: 2.5rem;
      }
    }
  }

  &__subtile {
    position: relative;
    margin: 0 0 1.3rem;
    font-family: $font-roboto-condensed;
    font-size: 1.8rem;
    line-height: 1.2;
    font-weight: 700;
    letter-spacing: 0.09rem;
    
    &:before {
      content: '';
      display: none;
      position: absolute;
      top: 1.3rem;
      width: 100%;
      height: 1px;
      background-color: $color-white;
      z-index: 1;

      @media (min-width: $break-medium) {
        display: block;
      }

      @media (min-width: $break-large) {
        top: 1.1rem;
      }
    }

    @media (min-width: $break-medium) {
      margin: 0 0 2rem;
      font-size: 2rem;
      line-height: 1.36;
      letter-spacing: 0.1rem;
    }

    @media (min-width: $break-large) {
      margin: 0 0 2.5rem;
    }

    .text-box {
      position: relative;
      display: inline-block;
      padding-right: 1.3rem;
      background-color: $color-dark-navy;
      z-index: 2;
    }
  }

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  &__title {
    margin: 0 0 1.5rem;

    @media (min-width: $break-medium) {
      margin: 0 0 2.8rem;
    }

    @media (min-width: $break-large) {
      margin: 0 0 3.6rem;
    }
  }

  &__wrapper {
    margin: 0 -2rem;
    position: relative;

    @media (min-width: $break-medium) {
      margin: 0 -3rem;
    }

    @media (min-width: $break-large) {
      margin: 0 -5rem;
    }

    .card-carousel__dots a { 
      display: none;
    }

    &.is-scrollable {
      .type-text { 
        opacity: 0;
      }

      .card-carousel__dots a { 
        display: block;
      }
  

      .card-carousel__dots { 
        a {
          display: block;
        }
      }
  
      .card-carousel__image {
        overflow: hidden;
        height: calc(100vh - 100px);

        @media (max-width: $break-medium - 1) {
          height: calc(60vh - 50px);
        }
      }
      .card-carousel__image:after {
        opacity: 0.6;
      }
  
      .card-carousel__text {
        height: calc(100vh - 100px);

        @media (max-width: $break-medium - 1) {
          height: calc(40vh - 50px);
        }

        @media (max-width: $break-medium - 1) {
          max-height: 0px;
          overflow: hidden;
        }
      }
  
      .card-carousel__text .card-carousel__text__wrap {
        opacity: 0;
      }
  
      &.sticky {
        @media (max-width: $break-medium - 1) {
          padding-bottom:  calc(40vh - 50px);;
        }
        &:before {
          content: "";
          position: absolute;
          bottom: calc(100vh - 100px);
          height: 100px;
          left: 0;
          width: 100%;
          z-index: 1000;
          background-color: $color-dark-navy;
        }
        .card-carousel__dots {
          position: absolute;
          bottom: calc(100vh - 200px);
          top: auto;
        }
        .card-carousel__text {
          position: absolute;
          z-index: 1000;
          right: 0;
          bottom: 0;
          height: calc(100vh - 100px);

          @media (max-width: $break-medium - 1) {
            height: calc(40vh - 50px);
          }
        }

        .type-text {
          position: absolute;
          bottom: calc(100vh - 200px);
        }
    
        .inview   .card-carousel__text {
          
          @media (max-width: $break-medium - 1) {
            max-height: 1000px;
          }
        }
      }
    
      &.inview {
        @media (max-width: $break-medium - 1) {
          padding-bottom:  calc(40vh - 50px);;
        }
        .card-carousel__dots { 
          opacity: 1;            
          position: sticky;
                    
          @media (max-width: $break-medium - 1) {
            top: 60vh !important;
          }

          @media (min-width: $break-medium) {
            top: 13rem;
          }

          @media (min-width: $break-large) {
            top: 14rem !important;
          }
        }
        &:before {
          content: "";
          position: fixed;
          top: 0;
          height: 100px;
          left: 0;
          width: 100%;
          z-index: 1000;
          background-color: $color-dark-navy;
        }
        .card-carousel__text {
          position: fixed;
          bottom: 0;
          z-index: 1000;
          right: 0;
          left: 0;
          overflow: auto;
          height: calc(100vh - 100px);

          @media (min-width: $break-medium) {
            left: auto;
          }
    
          @media (min-width: $content-max) {
            right: calc((100vw - 1800px) / 2);
          }
    
          @media (max-width: $break-medium - 1) {
            bottom: 0;
            top: auto !important;
            max-height: 1000px;
            height: calc(40vh - 50px);
    
          }
        }
    
        .type-text {
          position: fixed;
          z-index: 3000;
          width: auto;
    
          @media (max-width: $break-medium - 1) {
            top: 100px !important;
          }
        
          @media (min-width: $break-large) {
            top: 100px !important;
          }
        }
      }
  
      .card-carousel__item.inview {
        .card-carousel__text {
          z-index: 2000;
          padding: 2.5rem 2rem;
  
          @media (min-width: $break-medium) {
            padding: 4rem
          }
        }
        .card-carousel__text__wrap {
          opacity: 1;
          transition: all 500ms;
        }
  
        .type-text {
          opacity: 1;
        }

        &.inview {
          .card-carousel__image:after {
            opacity: 0;
            transition: all 600ms;
          }
        }
      }
  
      .card-carousel__item {
        &.animate-in {
          .card-carousel__image:after {
            opacity: 0;
            transition: all 600ms;
          }
        }

        &.animate-out {
          .type-text {
            opacity: 1;
          }
          .card-carousel__text__wrap {
            opacity: 0;
          }
        }
      }
    }
  }

  .type-text {
    z-index: 1000;
    min-height: 1.35em;
    position: absolute;

    @media (min-width: $break-medium) {
      max-width: 50%;
    }

    @media (min-width: $break-large) {
      font-size: 10rem;
      max-width: 65.5%;
    }
  }

  // slides transition styles
  .slick-list {
    transition: height .3s ease;
    background-color: $color-blue;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 5.4rem;
      background-color: $color-dark-navy;
    }
  }

}