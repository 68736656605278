.breadcrumbs {
  position: relative;
  padding: 1rem 0;
  width: 100%;
  display: none;

  &:before {
    content: "";
    @include fullwidth-with-sidebar();
    background: $color-blue;
    z-index: 1;
  }

  @media (min-width: $break-large) {
    display: block;
  }

  &:before {
    content: "";
    @include fullwidth-with-sidebar();
    background-color: $color-blue;
  }

  .container {
    @media (min-width: $break-large) {
      padding-left: 6rem;
      padding-right: 6rem;
    }
  }

  .breadcrumbs__list {
    position: relative;
    padding: 0;
    margin: 0;
    display: flex;
    z-index: 2;

    &-item {
      position: relative;
      margin: 0;
      list-style: none;

      &::marker {
        content: none;
        color: transparent;
      }

      &:not(:last-child) {
        padding: 0 3rem 0 0;
        
        &:after {
          content: "";
          position: absolute;
          top: 0.9rem;
          right: 1.3rem;
          width: 0.5rem;
          height: 0.5rem;
          border-top: 1px solid $color-white;
          border-right: 1px solid $color-white;
          transform: rotate(45deg);
        }
      }

      a {
        background: none;
        color: $color-white;
        font-weight: 400;

        &:hover {
          color: $color-yellow;
        }
      }
    }
  }
}