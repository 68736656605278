/**
 * Base theme styles
 * This file contains theme base styles.
 *
 * For example:
 * - WYSIWYG styles and layouts
 * - Images and Videos
 * - Tables
 */

 @font-face {
	font-family: 'Karla';
	src: url('../assets/fonts/Karla-Regular.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}


 @font-face {
	font-family: 'Bitter';
	src: url('../assets/fonts/Bitter-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}


@font-face {
	font-family: 'Bitter';
	src: url('../assets/fonts/Bitter-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Bitter';
	src: url('../assets/fonts/Bitter-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Bitter';
	src: url('../assets/fonts/Bitter-SemiBoldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Bitter';
	src: url('../assets/fonts/Bitter-Bold.ttf');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Bitter';
	src: url('../assets/fonts/Bitter-BoldItalic.ttf');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Roboto';
	src: url('../assets/fonts/Roboto-Bold.ttf');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('../assets/fonts/Roboto-Black.ttf');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('../assets/fonts/Roboto-BoldItalic.ttf');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Roboto';
	src: url('../assets/fonts/Roboto-Italic.ttf');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Roboto';
	src: url('../assets/fonts/Roboto-Medium.ttf');
	font-weight: 500;
}

@font-face {
	font-family: 'Roboto';
	src: url('../assets/fonts/Roboto-MediumItalic.ttf');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Roboto';
	src: url('../assets/fonts/Roboto-Regular.ttf');
	font-weight: 400;
}

@font-face {
	font-family: 'Roboto Condensed';
	src: url('../assets/fonts/RobotoCondensed-Bold.ttf');
	font-weight: 700;
}

@font-face {
	font-family: 'Roboto Condensed';
	src: url('../assets/fonts/RobotoCondensed-BoldItalic.ttf');
	font-weight: 700;
  font-style: italic;
}

@font-face {
	font-family: 'Roboto Condensed';
	src: url('../assets/fonts/RobotoCondensed-Regular.ttf');
	font-weight: 400;
}

@font-face {
	font-family: 'Roboto Condensed';
	src: url('../assets/fonts/RobotoCondensed-Light.ttf');
	font-weight: 300;
}

@font-face {
	font-family: 'Roboto Condensed';
	src: url('../assets/fonts/RobotoCondensed-Italic.ttf');
	font-weight: 400;
  font-style: italic;
}

html {
  font-size: 10px;
  color: $color-dark-navy;
}

html,
body {
  overflow-x: clip;

  &.main-menu-active {
    overflow: hidden;
  }
}

body {
  font-size: 1.6rem;
  font-family: $font-roboto;
  font-weight: 300;
  line-height: 1.3;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  @media (min-width: $break-large) {
    font-size: 1.8rem;
  }

  //** Fixed class to prevent scrolling
  &.js-fixed {
    overflow: hidden;
  }
}

#main {
  position: relative;

  &:before {
    content: '';
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(100% - var(--main-header-bottom-offset));
    z-index: 99;
    background-color: rgba($color-dark-navy, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s ease, visibility .3s ease;
  }

  .main-menu-active & {
    &:before {
      opacity: 1;
      visibility: visible;
      transition: opacity .3s ease, visibility .3s ease;
    }
  }

  & > .content-wrapper {
    // clearfix for pages with side-nav (prevent collapse of page content)
    &::after {
      content: '';
      display: block;
      width: 100%;
      clear: both;
    }
  }
}

//## Remove margin on last elements
* >  {
  p:last-child,
  ul:last-child,
  ol:last-child {
    margin-bottom: 0;
  }
}

//## Visually hidden
.visually-hidden {
  @include element-invisible;
}

//## Skip to content
a.skip-link {
  position: absolute;
  left: 0;
  top: 0;
  color: $color-black;
  @include focus-dark-navy();
  outline-offset: -3px;

  &:hover {
    color: $color-blue;
  }

  &.visually-hidden {
    @include element-focusable;

    &:focus {
      background-color: $color-white;
      padding: 3px 10px;
      position: absolute !important;
      z-index: 10000;
    }
  }
}

//## Floated content
.float-right {
  float: right;
}

.float-left {
  float: left;
}

//## Links
a {
  color: $color-yellow;
  background-image: linear-gradient(to right,$color-yellow 50%,$color-yellow 50%);
  background-position: left 95%;
  background-size: 100% 1px;
  background-repeat: repeat-x;
  cursor: pointer;
  font-weight: 700;
  text-decoration: none;
  transition: 0.2s ease;

  &:hover {
    color: $color-white;
    background-image: linear-gradient(to right,$color-white 50%,$color-white 50%);
  }
}


.cta--button {
  @include cta-button();
}

.cta--link {
  @include cta-arrow($color-yellow, $color-white);
}

//## Images
img {
  display: block;
  max-width: 100%;
  height: auto;
}

figure {
  position: relative;
  margin: 0;
}

figcaption {
  font-size: 1.4rem;
  line-height: 1.2;
  margin: 0.6rem 0 0;
  padding-bottom: 0.6rem;
  border-bottom: 1px solid $color-white;

  @media (min-width: $break-medium) {
    background: linear-gradient(180deg, rgba(27, 62, 148, 0) 0%, rgba(9, 14, 55, 0.9) 100%);
    position: absolute;
    bottom: 0;
    padding: 3rem 2rem 2rem;
    width: 100%;
  }

 
}

//## Inline images
.img-inline {
  &--full {
    margin: 2rem 0 3rem;
    position: relative;
  
    @media (min-width: $break-medium) {
      margin: 3rem 0 4rem;
    }

    @media (min-width: $break-large) {
      margin: 3rem 0 6rem;
    }

    img {
      width: 100%;
    }
  }

  &--float {
    margin: 2rem 0 3rem;

    @media (max-width: $break-medium - 1px) {
      display: inline-block;
      float: none;
    }

    &.float-right {

      @media (min-width: $break-medium) {
        margin: 0.5rem  0 2rem 2rem;
      }

      @media (min-width: $break-large) {
        margin: 0.5rem  0 3rem 3rem;
      }
    }

    &.float-left {
      @media (min-width: $break-medium) {
        margin: 0.5rem 2rem 2rem 0;
      }

      @media (min-width: $break-large) {
        margin: 0.5rem 3rem 3rem 0;
      }
    }
  }
}

.section-nav {
  @media (min-width: $break-large) {
    max-width: 24rem;
    margin-left: 4rem;
    float: left;
    position: sticky;
    top: 0;

    & + .container {
      width: calc(100% - 28rem);
      float: right;
      padding: 0 8rem 0 4rem;

      .narrow-block {
        margin-left: 0 !important;
        margin-right: 0 !important;
        max-width: 100%;
      }
    }
  }
}

main {
  @media (min-width: $break-large) {
    max-width: $content-max;
    margin: 0 auto;
  }
}

.quote {
  color: $color-white;
  background-color: $color-blue;
  margin: 0 -2rem;
  padding: 2rem 2.5rem;
  position: relative;

  @media (min-width: $break-medium) {
    padding: 5rem;
    margin: 0 -3rem;
  }

  &:before {
    content: "";
    @include fullwidth-with-sidebar();
    background: $color-blue;
    transform: none;
    left: calc(var(--bg-offset) * -1);
    margin-left: 0;
    z-index: 0;
  }

  &.quote.quote {
    &:before {
      margin-left: 0;
    }
  }

  &__copy {
    font-family: $font-bitter;
    font-size: 2.4rem;
    font-weight: 500;
    position: relative;
    z-index: 1;

    @media (min-width: $break-medium) {
      font-size: 2.8rem;
    }

    @media (min-width: $break-large) {
      font-size: 3.4rem;
    }

    &:before {
      content: '“';
      line-height: 1.3;
      font-size: 3.4rem;
      display: inline-block;
      vertical-align: text-top;
      height: 0.5em;
      margin-top: -2px;
      text-indent: -1.3rem;
    }

    &:after {
      content: '”';
      line-height: 1.3;
      font-size: 3.4rem;
      display: inline-block;
      vertical-align: text-top;
      height: 0.5em;
      margin-top: -2px;
    }
  }
  &__footer {
    font-family: $font-roboto-condensed;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    margin-top: 1.4rem;
    position: relative;
    z-index: 1;

    @media (min-width: $break-medium) {
      margin-top: 2rem;

    }
  }
}

.type-text {
  background: $color-yellow;
  color: $color-black;
  font-family: $font-roboto-condensed;
  font-size: 3.8rem;
  font-weight: 900;
  text-transform: uppercase;
  padding: 0.5rem 1.2rem;
  display: inline-block;
  line-height: 1;
  margin: -0.5em 0 -28px;
  position: relative;
  z-index: 10;

  @media (min-width: $break-medium) {
    padding: 1.1rem 2rem;
    font-size: 6.5rem;
    margin: -25px 0 -61.5px;
  }

  @media (min-width: $break-large) {
    padding: 1.3rem;
    font-size: 6.8rem;
    margin: -40px 0 -50px;
  }
}

a.type-text, 
button.type-text {
  &:hover {
    background-image: none;
    background-color: $color-gray-blue;
    color: $color-black;
  }
}