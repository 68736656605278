/**
 * @file
 * Generic Block: 2 Column
 *
 * Styles for Generic Block: 2 Column object.
 */

.gen2col {
  margin: 0;
  padding: 6rem 0 2.7rem;
  color: $color-white;
  position: relative;

  @media (min-width: $break-medium) {
    padding: 5.1rem 0 1.3rem;
  }

  @media (min-width: $break-large) {
    padding: 5.1rem 0 3.9rem;
  }

  &:before {
    content: "";
    @include fullwidth-with-sidebar();
    background: $color-dark-navy;
  }

  .scroll-animate-box {
    &.scroll-animate {
      @include slide-fade-in;
    }
  }

  &__intro {
    margin: 0 0 3.3rem;

    @media (min-width: $break-medium) {
      margin: 0 0 2.3rem;
    }

    @media (min-width: $break-large) {
      margin: 0 0 3.9rem;
    }
  }
}