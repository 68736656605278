.footer-sock {
  position: relative;
  --image-shift: calc(((100vw - var(--scrollbar-width)) - 100%) / 2);
  clear: both;

  @media (min-width: $break-medium) {
    display: flex;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: calc(100vw - var(--scrollbar-width));
    height: 100%;
    background-color: $color-blue;
    transform: translateX(-50%);
    z-index: -1;

    @media (min-width: $break-large) {
      left: calc(50% - 2rem);
    }
  }

  .section-nav + .container & {
    @media (min-width: $break-large) {
      margin-left: -24rem;
    }
  }

  .content-holder {
    padding: 2.5rem 0 0;

    @media (min-width: $break-medium) {
      padding: 3.3rem 3rem 5.5rem 0;
      flex-grow: 1;
    }

    @media (min-width: $break-large) {
      display: flex;
      padding: 8.3rem 3rem 8.3rem 3.2rem;
    }

    .section-nav + .container & {
      @media (min-width: $break-large) {
        padding: 8.3rem 3rem 8.3rem 0;
      }
    }
  }

  .section__header {
    margin: 0 0 2rem;
    color: $color-white;
    flex-shrink: 0;

    @media (min-width: $break-medium) {
      margin: 0 0 3.9rem;
    }

    @media (min-width: $break-large) {
      width: 100%;
      max-width: 72.8%;
      padding-right: 4rem;
      margin: 0;
    }

    h2 {
      line-height: 3.3rem;

      @media (min-width: $break-medium) {
        line-height: 5.28rem;
      }

      @media (min-width: $break-large) {
        font-size: 6rem;
        line-height: 6.6rem;
      }
    }
  }

  .cta-list {
    position: relative;
    list-style: none;
    padding: 0;
    margin: 0 0 -1.5rem;
    z-index: 2;

    &__item {
      margin: 0 0 1rem;
      padding: 0;
      list-style: none;
      
      &::marker {
        content: '';
        color: transparent;
        display: none;
      }
    }

    &__holder {
      margin: 0 0 -2.7rem;

      @media (min-width: $break-large) {
        margin: 0 -27.2% 0 0;
        flex-shrink: 0;
      }
    }

    .cta--button {
      margin: 0;
      padding: 0.4rem 3rem 0.4rem 1.2rem;

      @media (min-width: $break-large) {
        padding: 0.6rem 3rem 0.5rem 1.2rem;
        font-size: 3rem;
        line-height: 3.5rem;
      }

      &:hover {
        color: $color-dark-navy;
      }

      &:after {
        width: 0.7rem;
        height: 0.7rem;

        @media (min-width: $break-medium) {
          width: 1.1rem;
          height: 1.1rem;
        }

        @media (min-width: $break-large) {
          border-width: 0.4rem;
        }
      }
    }
  }
  
  .image-holder {
    width: calc(100% + 4rem);
    margin: 0 -2rem;

    @media (min-width: $break-medium) {
      width: calc(38.8% + 3rem);
      margin: 0 -3rem 0 0;
      flex-shrink: 0;
    }

    @media (min-width: $break-large) {
      width: calc(40% + var(--image-shift));
      margin: 0 calc(var(--image-shift) * -1) 0 0;

      .section-nav + .container & {
        @media (min-width: $break-large) {
          width: calc(39.45% + var(--image-shift));
        }
      }
    }

    img {
      max-width: calc(100vw - var(--scrollbar-width));
      width: calc(100vw - var(--scrollbar-width));
      aspect-ratio: 320/191;
      object-fit: cover;

      @media (min-width: $break-medium) {
        width: 100%;
        aspect-ratio: auto;
        height: 100%;
      }

      @media (min-width: $break-large) {

      }
    }
  }

  .go-to-top-button {
    position: absolute;
    top: 0;
    right: -1rem;
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 50%;
    border: none;
    background-color: $color-yellow;
    transition: background-color .3s ease;
    transform: translateY(-50%);
    @include focus-dark-navy;
    outline-offset: -4px;

    @media (min-width: $break-medium) {
      right: -1.4rem;
      width: 6rem;
      height: 6rem;
    }

    &:hover {
      background-color: $color-gray-blue;
    }

    &::before {
      content: '';
      position: absolute;
      border-right: 3px solid $color-dark-navy;
      border-top: 3px solid $color-dark-navy;
      top: calc(50% + 0.3rem);
      left: 50%;
      width: 1.2rem;
      height: 1.2rem;
      transform: translate(-50%, -50%) rotate(-45deg);

      @media (min-width: $break-medium) {
        top: calc(50% + 0.5rem);
        width: 1.6rem;
        height: 1.6rem;
      }
    }
  }
}