.support {
  position: relative;
  padding: 6rem 0;

  @media (min-width: $break-medium) {
    width: calc(100% + 2rem);
    padding: 8.5rem 0;
    margin: 0 -1rem;
  }

  @media (min-width: $break-large) {
    padding: 10.1rem 0;
  }

  &::before {
    content: '';
    @include fullwidth-with-sidebar;
    background-color: $color-dark-navy;
  }

  &__header {
    background-color: $color-white;
    color: $color-dark-navy;

    @media (min-width: $break-large) {
      padding: 0;
      display: flex;
    }

    .lined-header {
      &__subtile {
        margin: 0 0 0.7rem;

        @media (min-width: $break-medium) {
          margin: 0 0 1.1rem;
        }

        &::before {
          background-color: $color-dark-navy;
        }

        .text-box {
          background: $color-white;
        }
      }

      &__title {
        margin: 0;
        font-size: 2.6rem;
        line-height: 2.9rem;

        @media (min-width: $break-medium) {
          font-size: 4rem;
          line-height: 4.8rem;
        }

        @media (min-width: $break-large) {
          font-size: 4.8rem;
          line-height: 6rem;
        }
      }
    }

    .text-holder {
      padding: 2.5rem 2rem 2.3rem;

      @media (min-width: $break-medium) {
        padding: 3.5rem 3rem 4.1rem;
      }

      @media (min-width: $break-large) {
        padding: 4.7rem 8rem 4.1rem 4rem;
      }
    }

    .image-box {
      @media (min-width: $break-large) {
        width: 33.3%;
        flex-shrink: 0;
      }

      img {
        aspect-ratio: 280/132;
        object-fit: cover;
        width: 100%;

        @media (min-width: $break-large) {
          aspect-ratio: auto;
          height: 100%;
        }
      }
    }
  }

  .content-holder {
    padding: 2.3rem 2rem 1.7rem;
    background-color: $color-blue;

    @media (min-width: $break-medium) {
      padding: 3.6rem 3rem 3.7rem;
    }

    @media (min-width: $break-large) {
      padding: 4.2rem 4rem 7.4rem;
    }
  }

  &__list {
    color: $color-white;

    @media (min-width: $break-medium) {
      display: flex;
      flex-wrap: wrap;
      gap: 3rem;
    }

    @media (min-width: $break-large) {
      flex-wrap: nowrap;
      gap: 8rem;
    }
  }

  &__item {
    margin: 0 0 3.3rem;

    @media (min-width: $break-medium) {
      width: calc(50% - 1.5rem);
      margin: 0 0 2.3rem;
    }

    @media (min-width: $break-large) {
      width: calc(100% / 3 - 16rem / 3);
      margin: 0;
      display: flex;
      flex-direction: column;
    }

    &:last-child {
      margin: 0;
    }
  }

  &__title {
    margin: 0 0 0.8rem;
    font-size: 2.2rem;
    line-height: 2.6rem;

    @media (min-width: $break-medium) {
      margin: 0 0 1.3rem;
      font-size: 3rem;
      line-height: 1;
    }

    @media (min-width: $break-large) {
      margin: 0 0 0.8rem;
      font-size: 3.6rem;
      line-height: 4.2rem;
    }
  }

  &__body {
    margin: 0 0 1.5rem;

    @media (min-width: $break-medium) {
      margin: 0 0 1.8rem;
      font-size: 1.8rem;
    }

    @media (min-width: $break-large) {
      margin: 0 0 2.3rem;
    }
  }

  &__cta {
    @media (min-width: $break-large) {
      margin-top: auto;
    }
  }
}