/**
 * @file
 * Events 3 Up Feature
 *
 * Contains styles for Events 3 Up content object.
 */

.events3up {
  margin: 3.5rem 0;

  @media (min-width: $break-medium) {
    margin: 5.2rem 0 4.8rem;
  }

  @media (min-width: $break-large) {
    margin: 5.1rem 0 5.9rem;
  }

  a {
    &:focus {
      @include focus-dark-navy();
    }

  }

  &__header {
    @media (min-width: $break-medium) {
      display: flex;
      align-items: flex-end;
      margin: 0 0 1.9rem;
    }

    &--title {
      padding-right: 4rem;
      margin-right: auto;
    }

    &--cta {
      display: none;
      flex-shrink: 0;

      @media (min-width: $break-medium) {
        display: block;
      }

      @media (min-width: $break-large) {
        padding: 0 0 1.5rem;
      }

      a {
        margin: 0;
      }
    }
  }

  .section__intro {
    margin: 0 0 2.2rem;

    @media (min-width: $break-medium) {
      margin: 0 0 3.9rem;
    }
  }

  &__item {
    display: flex;
    gap: 1.3rem;
    margin: 0 0 4rem;

    @media (min-width: $break-medium) {
      flex-direction: column;
      gap: 0;
      margin: 0;
    }
  }

  &__date {
    width: 100%;
    max-width: 7rem;
    height: 8.3rem;
    padding: 0.2rem 0 0;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background-color: $color-yellow;
    text-transform: uppercase;

    @media (min-width: $break-medium) {
      max-width: 9.4rem;
      height: 11.1rem;
      margin: 0 0 1rem;
    }
  }

  &__month {
    font-size: 1.4rem;
    line-height: 1.2;
    font-weight: 500;

    @media (min-width: $break-medium) {
      font-size: 1.6rem;
    }
  }

  &__day {
    padding-bottom: 0.3rem;
    font-family: $font-roboto-condensed;
    font-size: 4rem;
    line-height: 1;
    font-weight: 700;

    @media (min-width: $break-medium) {
      padding-bottom: 0.7rem;
      font-size: 5.5rem;
    }
  }

  h3 {
    margin: 0 0 1.2rem;
    line-height: 1.14;
    vertical-align: top;

    @media (min-width: $break-medium) {
      margin: 0 0 0.6rem;
    }

    @media (min-width: $break-large) {
      margin: 0 0 0.9rem;
    }

    .cta--link {
      margin: 0;
      font-size: 2rem;
      line-height: inherit;
      letter-spacing: 0.04rem;
      color: $color-blue;
      display: block;

      @media (min-width: $break-medium) {
        font-size: 2.4rem;
        letter-spacing: 0;
      }

      &:hover {
        color: $color-dark-navy;

        &:after {
          border-color: $color-dark-navy;
        }
      }

      &:after {
        border-color: $color-blue;
      }
    }
  }

  &__data {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    @media (min-width: $break-medium) {
      font-size: 1.8rem;
      padding-right: 1rem;
    }
  }

  &__time {
    position: relative;
    padding: 0 0.7rem 0 0;
    margin: 0 0.5rem 0 0;

    @media (min-width: $break-large) {
      margin: 0 0.7rem 0 0;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0.3rem;
      right: 0;
      height: calc(100% - 0.7rem);
      width: 1px;
      background-color: $color-dark-navy;
    }
  }

  &__section-cta {
    margin: 3.9rem 0 0;

    @media (min-width: $break-medium) {
      display: none;
    }
    
    a {
      margin: 0;
    }
  }
}