/**
 * @file
 * Statistic: 3 up feature
 *
 * Styles for Statistick: 3 up feature object.
 */

.stat3up {  
  padding: 3.5rem 0 3.2rem;
  margin: 0;
  position: relative;
  color: $color-white;

  @media (min-width: $break-medium) {
    padding: 5.1rem 0 3.3rem;
  }

  @media (min-width: $break-large) {
    padding: 5.1rem 0 5.2rem;
  }

  &:before {
    content: '';
    @include fullwidth-with-sidebar();
    background-color: $color-blue;
  }

  &__header {
    @media (min-width: $break-large) {
      margin: 0 0 1.9rem;
    }
  }

  &__intro {
    margin: 0 0 2.2rem;

    @media (min-width: $break-medium) {
      margin: 0 0 2.4rem;
    }

    @media (min-width: $break-large) {
      margin: 0 0 3.9rem;
    }
  }

  &__item {
    margin: 0 0 1.5rem;

    @media (min-width: $break-medium) {
      margin: 0;
    }
  }

  &__number {
    display: inline-block;
    min-width: 20rem;
    padding: 0.4rem 0 0.3rem;
    margin: 0 0 1.2rem;
    color: $color-dark-navy;
    font-size: 7rem;
    line-height: 8.4rem;
    font-weight: 900;
    background-color: $color-yellow;
    text-align: center;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;

    @media (min-width: $break-medium) {
      min-width: 100%;
      padding: 0;
      margin: 0 0 2rem;
      font-size: 8.5rem;
      line-height: 10.2rem;
    }

    @media (min-width: $break-large) {
      padding: 0.5rem 1.5rem;
      margin: 0 0 1.7rem;
      font-size: 10rem;
      line-height: 12rem;
    }
  }

  &__cta {
    .cta--link {
      font-size: 2.4rem;
      letter-spacing: 0.05rem;
    }
  }

  &__caption {
    margin: 0 0 0.6rem;

    @media (min-width: $break-medium) {
      padding-right: 0.2rem;
      margin: 0 0 1.3rem;
      font-size: 1.8rem;
    }

    @media (min-width: $break-large) {
      margin: 0 0 0.8rem;
    }
  }

  &__section-cta {
    padding: 1.3rem 0 0;

    @media (min-width: $break-medium) {
      padding: 1.5rem 0 0;
    }

    @media (min-width: $break-large) {
      padding: 3.5rem 0 0;
    }
  }
}