/**
 * @file
 * Program
 *
 * Contains styles for programs and program view modes.
 */
 .program-finder {
  color: $color-white;
  position: relative;
  padding-top: 30px;
  padding-bottom: 40px;

  &:before {
    content: "";
    @include full-width();
    position: absolute;
    top: 0;
    height: 100%;
    background-color: $color-dark-navy;
    z-index: -1;
  }

  @media (min-width: $break-large) {
    display: flex;
    align-items: flex-start;
    padding-top: 50px;
    padding-bottom: 100px;
  }
 }

 .main .program__filters {
  color: $color-dark-navy;
  display: none;
  border-top: 1px solid;
  padding: 2rem 2rem 0.5rem;
  background-color: $color-white;

  @media (min-width: $break-medium) {
    padding: 2rem 2rem 3rem;
  }

  @media (min-width: $break-large) {
    display: block;
    width: 277px;
    margin-right: 60px;
    padding: 2rem 2rem 0;
  }

  .program--list__wrapper {

    @media (min-width: $break-medium) {
    column-count: 2;
    column-gap: 3rem;
    }

    @media (min-width: $break-large) {
      column-count: 1;
    }
  }

  .program--list__type {
    border: 1px solid;
    width: 2.4rem;
    height: 2.4rem;
  }

  &__open {
    font-weight: 700;
    font-family: $font-roboto-condensed;
    font-size: 2rem;
    text-transform: uppercase;
    text-align: left;
    letter-spacing: 0.05em;
    background-color: $color-white;
    display: block;
    border: 0;
    padding: 2rem;
    width: 100%;
    position: relative;
    color: $color-dark-navy;

    &[aria-expanded="true"] {
      .filter-icon {
        background-color: $color-gray-blue;
        &:after {
          height: 0;
          border: 0;
        }
      }
    
    }

    .filter-icon {
      width: 25px;
      height: 25px;
      border-radius: 100em;
      background: $color-yellow;
      position: absolute;
      right: 2rem;
      top: 50%;
      transform: translateY(-50%);
      transition: all 300ms;

      &:before,
      &:after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        width: 10px;
        border-top: 2px solid;
        transform: translate(-50%, -50%);
      }

      &:after {
        transform: translate(-50%, -50%) rotate(90deg);
      }

    }

    @media (min-width: $break-large) {
      display: none;
    }
  }

  &__fieldset {
    border: 0;
    padding: 0;
    margin-bottom: 2rem;
    position: relative;
    break-inside: avoid;

    &:before {
      content: "";
      position: absolute;
      top: -25px;
      width: 100%;
      height: 1px;
      border-top: 1px solid $color-black;
    }

    legend {
      font-weight: 700;
      font-family: $font-roboto-condensed;
      font-size: 2rem;
      text-transform: uppercase;
      margin-bottom: 1rem;
      padding-right: 20px;
      letter-spacing: 0.05em;
      background-color: $color-white;
      position: relative;
      z-index: 1;
    }
  }

  &__field {
    padding: 0 0 0 25px;
    position: relative;
    display: flex;
    margin-bottom: 1rem;

    &.form__item:not(.form--fieldset) label {
      font-size: 1.8rem;
      font-weight: 400;
      margin-bottom: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;

      &:before {
        content: "";
        width: 14px;
        height: 14px;
        border: 1px solid;
        display: inline-block;
        vertical-align: middle;
        position: absolute;
        left: 0;
        top: 3px;
      }
    }

    input[type="checkbox"]:checked + label:before {
      background-color: $color-yellow;
      border: 0;
    }

    input[type="checkbox"]:focus {
      outline: 0;
    }


    input[type="checkbox"]:checked + label:after {
      content: "";
      position: absolute;
      left: 5px;
      top: 5.5px;
      width: 4px;
      height: 8px;
      border-right: 2px solid $color-black;
      border-bottom: 2px solid $color-black;
      transform: rotate(45deg);
    }

    input[type="checkbox"]:focus + label:before {
      outline: 1px solid;
      outline-offset: 4px;
      transition: none;
      @include focus-dark-navy();
    }
  }
}

.main .program--list {
  margin-top: 4.5rem;
  width: 100%;

  &__sort {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      bottom: 2rem;
      right: 1.7rem;
      width: 6px;
      height: 6px;
      border-right: 1px solid;
      border-bottom: 1px solid;
      transform: rotate(45deg);
    }


    &.form__item {
      margin-bottom: 3rem;
    }
   
    label {
      font-weight: 700;
      font-family: $font-roboto-condensed;
      font-size: 2rem;
      text-transform: uppercase;
      font-weight: 700;
      margin-bottom: 1rem;
    }
  }

  h2 {
    font-family: $font-roboto-condensed;
    font-weight: 300;
    font-size: 2.6rem;
    text-transform: uppercase;
    margin: 0;

    @media (min-width: $break-medium) {
      font-size: 3.6rem;
    }
  }

  a {
    background: none;
    display: table-row;
  }

  &__items:last-child {
    padding: 0;
    margin: 1.5rem 0 5.5rem;

    @media (min-width: $break-medium) {
      margin: 1.5rem 0 7.5rem;
    }
  }

  &__creds {
    display: flex;
    gap: 4px;
    margin-left: 0.8rem;
    margin-top: -2px;

    @media (min-width: $break-medium) {
      margin-left: 1.2rem;
    }

    @media (min-width: $break-large) {
      margin-top: -3px;

    }
  }

  &__cred {
    color: $color-white;
    font-size: 1.2rem;
    font-weight: 700;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $color-white;

    @media (min-width: $break-large) {
      font-size: 1.4rem;
      width: 36px;
      height: 36px;
    }
  }

  &__item {
    padding: 0;
    margin: 1.3rem 0;
    display: flex;
    list-style: none;

    @media (min-width: $break-large) {
      margin: 2rem 0;
    }

    &::marker {
      content: none;
      display: none;
    }
  }

  &__title {
    font-family: $font-roboto-condensed;
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: 700;
    max-width: calc(100% - 100px);

    @media (min-width: $break-medium) {
      font-size: 2.4rem;
    }
  }

  &__block {
    margin-bottom: 3rem;
  }
}

.program--full {
  .wysiwyg {
    padding-top: 6rem;
    padding-bottom: 5.5rem;

    @media (min-width: $break-medium) {
      padding-top: 7rem;
      padding-bottom: 7rem;
      font-size: 1.8rem;
    }

    @media (min-width: $break-large) {
      padding-top: 5rem;
      padding-bottom: 10rem;
    }

    h2 {
      margin-bottom: 1.7rem;

      @media (min-width: $break-large) {
        margin-bottom: 1.4rem;
      }
    }

    * {
      &:first-child {
        margin-top: 0;
      }
    }

    .img-inline--full {
      margin: 2.6rem 0 4.2rem;

      @media (min-width: $break-medium) {
        margin: 3rem 0 4rem;
      }

      @media (min-width: $break-large) {
        margin: 3.2rem 0 2.7rem;
      }
      
      figcaption {
        padding-bottom: 0.9rem;
        margin: 1rem 0 0;
        line-height: 1.2;
        border-bottom-width: 1px;

        @media (min-width: $break-medium) {
          padding: 7.6rem 2rem 1.6rem;
        }

        @media (min-width: $break-large) {
          padding: 10rem 2rem 1.8rem;
        }
      }
    }
  }
}