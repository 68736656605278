/**
 * @file
 * Video
 *
 * Contains styles for inline video objects.
 */

.vid-inline {
  overflow: hidden;
  position: relative;

  * {
    &::after,
    &::before {
      box-sizing: border-box;
    }
  }

  &__container {
    position: relative;
    margin: 0 0 1.2rem;

    @media (min-width: $break-medium) {
      margin: 0;
    }
  }

  &__cover {
    position: relative;
    z-index: 2;
    cursor: pointer;
    opacity: 1;
    transition: 0.3s ease;
    color: $color-black;

    &:focus {
      outline: 1px solid;
      @include focus-white;
      outline-offset: -4px;
      
    }

    &:hover {
      &:before,
      &:after {
        opacity: 1;
      }

      &:after {
        background-color: $color-gray-blue;
      }
    }

    &:after {
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      content: '';
    }

    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 16px;
      height: 14px;
      margin-top: -0.7rem;
      margin-left: -0.5rem;
      z-index: 2;
      transition: 0.2s all;

      @media screen and (min-width: $break-medium) {
        border-width: 1.2rem 0 1.2rem 2.2rem;
        margin-top: -1.1rem;
        margin-left: -0.9rem;
        width: 25.4px;
        height: 22px;
      }
    }

    &:after {
      width: 5rem;
      height: 5rem;
      border-radius: 100%;
      background: $color-yellow;
      transition: 0.2s all;
      transform: translate(-50%, -50%);

      @media screen and (min-width: $break-medium) {
        width: 8rem;
        height: 8rem;
      }
    }

    &.js-loading {
      &:hover {
        &:before {
          opacity: 1;
        }
      }

      &:before {
        margin: 0;
        height: 60px;
        width: 60px;
        background-image: url('data:image/svg+xml;charset=UTF-8,%3csvg xmlns:svg=\'http://www.w3.org/2000/svg\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' version=\'1.0\' width=\'60px\' height=\'60px\' viewBox=\'0 0 128 128\' xml:space=\'preserve\'%3e%3cg%3e%3cpath d=\'M78.75 16.18V1.56a64.1 64.1 0 0 1 47.7 47.7H111.8a49.98 49.98 0 0 0-33.07-33.08zM16.43 49.25H1.8a64.1 64.1 0 0 1 47.7-47.7V16.2a49.98 49.98 0 0 0-33.07 33.07zm33.07 62.32v14.62A64.1 64.1 0 0 1 1.8 78.5h14.63a49.98 49.98 0 0 0 33.07 33.07zm62.32-33.07h14.62a64.1 64.1 0 0 1-47.7 47.7v-14.63a49.98 49.98 0 0 0 33.08-33.07z\' fill=\'%23000000\' fill-opacity=\'1\'/%3e%3canimateTransform attributeName=\'transform\' type=\'rotate\' from=\'0 64 64\' to=\'-90 64 64\' dur=\'800ms\' repeatCount=\'indefinite\'%3e%3c/animateTransform%3e%3c/g%3e%3c/svg%3e');
        background-position: center;
        background-size: contain;
        border: 0;
        transform: translate(-50%, -50%);
      }

      svg {
        display: none;
      }

      &:after {
        display: none;
      }
    }

    &.js-hide {
      display: block;
      z-index: 1;
      opacity: 0;
    }
  }

  &__embed {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.5s ease;

    &.js-show {
      z-index: 2;
      opacity: 1;

      iframe {
        visibility: visible;
      }
    }
  }

  iframe {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border: 0;
    visibility: hidden;
  }

  &__caption {
    @extend figcaption;
    padding-bottom: 0.9rem;
    z-index: 3;

    @media screen and (min-width: $break-medium) {
      padding-bottom: 2rem;
    }
  }
}
