/**
 * @file
 * Faculty
 *
 * Contains styles for faculty detail and view modes.
 */

.faculty {
  &__hero {
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    color: $color-white;

    h1 {
      padding-bottom: 1rem;

      @media (min-width: $break-medium) {
        padding-top: 6.9rem;
        padding-bottom: 1.4rem;
      }

      @media (min-width: $break-large) {
        font-size: 8rem;
        padding-top: 14.1rem;
        padding-right: 5.5rem;
        padding-bottom: 1.7rem;
        padding-left: 5.5rem;
      }
    }
    
    .hero__subtitle {
      padding: 0 2rem 2.7rem;
      font-family: $font-roboto-condensed;
      font-size: 2rem;
      line-height: 1.14;
      letter-spacing: 0.04rem;
      font-weight: 500;
      text-transform: uppercase;

      @media (min-width: $break-medium) {
        padding: 0 3rem 4rem;
        font-size: 2.4rem;
        letter-spacing: 0.06rem;
      }

      @media (min-width: $break-large) {
        padding: 0 5.5rem 2.6rem;
      }
    }
  }

  &--full {
    position: relative;
    max-width: 80rem;
    margin: 0 auto;
    padding: 6rem 0;

    @media (min-width: $break-medium) {
      padding: 7rem 0;
    }

    @media (min-width: $break-large) {
      padding: 8.4rem 0 7.4rem;
    }

    &:before {
      content: "";
      @include fullwidth-with-sidebar();
      background: $color-dark-navy;
    }

    .faculty__contact {
      &-box {
        padding: 3.7rem 2.7rem 3.3rem;
        background-color: $color-white;

        a:focus {
          @include focus-dark-navy();
        }

        @media (min-width: $break-medium) {
          padding: 5.1rem 5rem 3.3rem;
        }

        @media (min-width: $break-large) {
          padding: 7rem 7.5rem 6.3rem;
        }
      }

      &-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 0 0 2.6rem;

        @media (min-width: $break-medium) {
          font-size: 1.8rem;
          margin: 0 0 2.4rem;
        } 

        &:last-child {
          margin: 0;
        }

        .faculty-email,
        .faculty-phone {
          color: $color-blue;
          background-image: linear-gradient(to right, $color-blue 50%, $color-blue 50%);
          background-position-y: calc(100% - 3px);

          &:hover {
            color: $color-yellow;
            background-image: linear-gradient(to right, $color-yellow 50%, $color-yellow 50%);
          }

          @media (min-width: $break-medium) {
            font-size: 1.8rem;
            line-height: 2.3rem;
          }
        }

        .faculty-email {
          margin: 0 0 0.2rem;

          @media (min-width: $break-medium) {
            margin: 0;
          }
        }

        .cta--button {
          margin: -0.2rem 0 0;

          @media (min-width: $break-medium) {
            margin: 1.4rem 0 0;
          }

          @media (min-width: $break-large) {
            margin: -0.2rem 0 0;
          }

          &:hover {
            color: $color-dark-navy;
          }
        }
      }

      &-title {
        text-transform: uppercase;
        font-size: 1.8rem;
        font-weight: 700;
        font-family: $font-roboto-condensed;
        line-height: 1.2;
        letter-spacing: 0.1rem;
        color: $color-dark-navy;

        @media (min-width: $break-medium) {
          font-size: 2rem;
          line-height: 1.36;
        }
      }
    }

    .faculty__content {
      color: $color-white;

      & > *:first-child {
        margin-top: 0;
      }

      @media (min-width: $break-medium) {
        font-size: 1.8rem;
      }

      .img-inline--full {
        margin: 2.3rem 0 1rem;

        @media (min-width: $break-medium) {
          margin: 3.2rem 0 4rem;
        }

        @media (min-width: $break-large) {
          margin: 3.2rem 0 3rem;
        }

        figcaption {
          padding: 1rem 1.8rem 1.4rem;
          margin: 0;

          @media (min-width: $break-medium) {
            padding: 7.6rem 1.8rem 1.4rem;
          }

          @media (min-width: $break-large) {
            padding: 10rem 2rem 1.8rem;
          }
        }
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    margin: 0 0 6.1rem;

    @media (min-width: $break-medium) {
      flex-direction: row;
      margin: 0 0 7.1rem;
    }

    @media (min-width: $break-large) {
      margin: 0 0 8.1rem;
    }
  }

  &__image,
  &__contact-box {
    width: 100%;
  }

  &__image {
    @media (min-width: $break-medium) {
      flex-shrink: 0;
      flex-grow: 0;
      width: 49.15%;
    }

    img {
      width: 100%;
    }
  }

  // List
  &.faculty--listing {
    .faculty {
      &__item {
        display: flex;
        align-items: flex-start;
        margin: 0 0 2em;
      }

      &__image {
        flex: 0 0 auto;
        margin-right: 2em;
      }
    }
  }

  // Directory
  &.faculty--directory {
    position: relative;
    padding-top: 3rem;
    // margin-top: -3.5rem;

    @media (min-width: $break-medium) {
      padding-top: 10rem;
      margin-top: -3rem;
    }

    @media (min-width: $break-large) {
      margin-top: 0rem;
      padding: 10rem 3rem 0;
    }
    
    &:before {
      content: "";
      @include full-width();
      background-color: $color-dark-navy;
      position: absolute;
      height: 100%;
      top: 0;
      z-index: -1;
    }
    .faculty__list{
      padding: 0 0 2.5em;

      @media (min-width: $break-medium) {
        padding: 0 0 0.4em;
      }

      .filter-btn-drop {
        display: block;
        appearance: none;
        background: $color-light-gray;
        padding: 24px 21px;
        margin: 0 0 15px;
        border: none;
        width: 100%;
        max-width: 290px;
        text-align: left;
        color: $color-black;

        @media (min-width: $break-large) {
          display: none;
        }

        &.expanded {
          span {
            &:after {
              transform: rotate(-135deg);
            }
          }
        }

         span {
          position: relative;
          display: block;
          font-weight: 700;

          &:after {
            position: absolute;
            display: block;
            top: calc(50% - 4px);
            right: 0;
            width: 3px;
            height: 3px;
            border-width: 0 2px 2px 0;
            border-style: solid;
            border-color: $color-black;
            transform: rotate(45deg);
            transition: transform 0.3s ease;
            content: '';
          }
        }
      }
    }
  }

  .heading-table {
    margin: 0 0 6rem;
    background-color: $color-white;

    @media (min-width: $break-medium) {
      margin: 0 0 70px;
    }

    .mobile-filter-drop {
      padding: 0 0 1.9rem;

      @media (min-width: $break-medium) {
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;
        padding-bottom: 0;
      }

      @media (min-width: $break-large) {
        display: block;

        &:before {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          height: 50px;
          background: $color-white;
          width: 30px;
          z-index: 10;
        }
      }


    }

    .toolbar__tabs {
      @media (min-width: $break-medium) {
        width: 50%;
        float: left;
      }

      @media (min-width: $break-large) {

        float: none;
        width: 100%;
      }
    }

    .toolbar{
      padding: 8px 0 0;

      @media (min-width: $break-medium) {
        width: 50%;
        float: right;
        padding-top: 0;
        background: $color-light-gray;
        display: flex;
        align-items: center;
      }

      @media (min-width: $break-large) {
        display: block;
        float: none;
        width: 100%;
        position: relative;
        padding: 0;
      }
    }

    .filter-label {
      font-family: $font-roboto-condensed;
      font-size: 1.8rem;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 0.05em;
      padding: 2.4rem 2rem 0;
      margin: 0;
      background-color: $color-white;

      &:before {
        @media (min-width: $break-large) {
          content: "";
          position: absolute;
          top: 50%;
          width: calc(100vw - 170px - var(--scrollbar-width));
          left: 100%;
          z-index: 0;
          height: 1px ;
          background-color: $color-dark-navy;
        }
      }

      @media (min-width: $break-medium) {
        position: absolute;
        top: 3.5rem;
        padding: 0 3rem;
      }

      @media (min-width: $break-large) {
        padding: 0 1rem 0 3rem;
        top: 3rem;
      }
    }
    .toolbar__tabs {
      @media (max-width: $break-medium - 1px) {
        display: none;
      }

      @media (min-width: $break-medium) {
        padding: 7rem 0 2rem;
      }

      @media (min-width: $break-large) {
        display: flex;
        flex-wrap: wrap;
        padding: 8.5rem 7rem 0 0;
      }
    }

    .clear-table {
      color: $color-gray-blue;
      text-indent: -1000px;
      overflow: hidden;
      background: none;
      border: none;
      padding: 0;
      position: absolute;
      top: 2rem;
      right: 1.5rem;
      width: 23px;
      height: 23px;

      svg {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        &.active {
          color: $color-dark-navy;
        }
      }

      @media (min-width: $break-medium) {
        width: 30px;
        height: 30px;
        left: 50%;
        margin-left: -50px;
        top: 3rem;
      }

      @media (min-width: $break-large) {
        position: absolute;
        display: block;
        opacity: 1;
        left: auto;
        right: 3rem;
        top: -5.5rem;
        width: 35px;
        height: 35px;
      }
    }

    .filter-box {
      line-height: 1;
      position: static;

      &:first-child {
        .form__item {
          @media (min-width: $break-medium) {
            display: block;
          }
        }
      }

      &:last-child {
        width: 100%
      }


      @media (min-width: $break-large) {
        margin: 0;
       
      }

      &:nth-child(3),
      &:nth-child(4){
        display: none;
      }
    }

    .form__item,
    .dataTables_filter {
      display: none;
    }

    .form__label {
      position: relative;
      display: block;
      margin: 0 0 12px;
      text-transform: capitalize;

      &:after {
        position: absolute;
        left: 20px;
        bottom: -57px;
        width: calc(100% - 40px);
        height: 1px;
        background: $color-black;
        content: '';

        @media (min-width: $break-large) {
          left: 23px;
          bottom: -60px;
        }
      }
    }

    .toggle-subfilters {
      color: $color-blue;
      font-family: $font-roboto-condensed;
      font-size: 2.2rem;
      font-weight: 300;
      text-transform: uppercase;
      background-color: transparent;
      border: 0;
      padding: 1rem 2rem 1.3rem;
      position: relative;
      transition: color .3s ease;

      &:focus {
        @include focus-dark-navy();
      }

      @media (min-width: $break-medium) {
        display: none;
        font-size: 3rem;
        padding: 1.25rem 3rem;
      }

      @media (min-width: $break-large) {
        font-size: 3.6rem;
      }

      &:hover {
        color: $color-dark-navy;

        &:after {
          border-color: $color-dark-navy;
        }
      }

      &[aria-expanded="true"] {
        color: $color-dark-navy;

        &:after {
          transform: rotate(-135deg);
          bottom: 0.75em;
          border-color: $color-dark-navy;
        }
      }

      &[aria-selected="true"] {
        color: $color-dark-navy;

        &:after {
          transform: rotate(135deg);
          border-color: $color-dark-navy;

          @media (min-width: $break-large) {
            transform: rotate(-135deg);
            bottom: 1em;
          }
        }

        &:before {
          width: 100%;
        }
      }

      &:after {
        content: "";
        position: absolute;
        bottom: 2.3rem;
        right: -0.5rem;
        width: 1.2rem;
        height: 1.2rem;
        border-right: 1px solid;
        border-bottom: 1px solid;
        transform: rotate(45deg);
        transition: all 300ms;

        @media (min-width: $break-medium) {
          transform: rotate(-45deg);
          bottom: 0.55em;

          border-right: 2px solid;
          border-bottom: 2px solid;

        }

        @media (min-width: $break-large) {
          bottom: 1.2em;
          transform: rotate(45deg);
        }
      }

      &.tab {
        display: none;

        @media (min-width: $break-medium) {
          display: block;
          padding: 0 25px 0.5rem 0;
          margin:  1.25rem 4rem 0.5rem 3rem;

          &:before {
            content: "";
            width: 0;
            position: absolute;
            left: 0;
            bottom: 0;
            border-bottom: 6px solid transparent;
            transition: all 300ms;
          }

          &[aria-selected="true"] {
            &:before {
              content: "";
              width: 100%;
              position: absolute;
              left: 0;
              bottom: 0;
              border-bottom: 6px solid $color-yellow;
            }
           
          }
        }
        @media (min-width: $break-large) {
          padding: 0px 25px 2.5rem 0;
          margin:  1.25rem 4rem 0 3rem;
        }
      }

      &:after {
        content: "";
        position: absolute;
        bottom: 2.3rem;
        right: -0.5rem;
        width: 1.2rem;
        height: 1.2rem;
        border-right: 1px solid;
        border-bottom: 1px solid;
        transform: rotate(45deg);
        transition: all 300ms;

        @media (min-width: $break-medium) {
          transform: rotate(-45deg);
          bottom: 0.55em;

          border-right: 2px solid;
          border-bottom: 2px solid;

        }

        @media (min-width: $break-large) {
          bottom: 1.2em;
          transform: rotate(45deg);
        }
      }

      &.tab {
        display: none;
        transition: color .3s ease;

        &[aria-selected="true"],
        &:hover {
          color: $color-dark-navy;

          &:after {
            border-color: $color-dark-navy;
          }
        }

        &:after {
          border-color: $color-blue;
        }

        @media (min-width: $break-medium) {
          display: block;
          padding: 0 25px 0.5rem 0;
          margin:  1.25rem 4rem 0.5rem 3rem;

          &:before {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            border-bottom: 6px solid transparent;
          }

          &[aria-selected="true"] {
            &:before {
              width: 100%;
        
            }
           
          }
        }
        @media (min-width: $break-large) {
          padding: 0px 25px 2.5rem 0;
          margin:  1.25rem 4rem 0 3rem;
        }
      }
    }

    select {
      width: 100%;
      padding: 23px 34px 23px 21px;
      background: $color-light-gray;
      border: 0;
      background-size: 8px;
    }

    .search-title {
      display: none;
    }


    input[type="search"]{
      color: $color-dark-navy;
      border: 0;
      border-bottom: 1px solid $color-dark-navy;
      padding: 1rem 0;
      width: 100%;
      background: transparent;

      &::placeholder {
        color: $color-dark-navy;
      }

      @media (min-width: $break-medium) {
        padding: 0.9rem 0 1.1rem;
      }
    }
  }

  .wrapper-table {
    line-height: 1.2;
    margin: 0 0 18px;
    background-color: $color-white;
    border-top: 44px solid $color-yellow;
    padding-top: 20px;

    @media (min-width: $break-medium) {
      border-top: 0;
      padding-top: 0;
    }

    @media (min-width: $break-large) {
      margin: 0 0 26px;

      &:after{
        display: none;
      }
    }

    .table-holder {
      @media (min-width: $break-large) {
        margin-right: 0;
      }
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
      // min-width: 100%;
      border-bottom: 0;
      display: block;

      @media (min-width: $break-medium) {
        display: table;
      }
    }

    th {
      @media (min-width: $break-medium) {
        font-size: 1.8rem;
        padding: 10px 20px;
        text-align: left;
      }
    }

    td {
      padding: 0;
      display: block;
      margin: 0 0 20px;
      border-bottom: 0;

      @media (min-width: $break-medium) {
        font-size: 1.8rem;
        padding: 20px;
        vertical-align: middle;
        min-width: 200px;
      }

       @media (min-width: $break-medium) {
        display: table-cell;
        margin: 0;
      }

      &:last-child {
        margin: 0;
      }
    }

    tbody {
      @media (max-width: $break-medium - 1) {
        display: block;
      }

    }

    thead {
      @media (max-width: $break-medium - 1) {
        display: none;
      }

      tr {
        @media (min-width: $break-medium) {
          border-bottom: 0;
        }
      }
    }

    tr {
      @media (max-width: $break-medium - 1) {
        display: block;
        padding: 0 20px 30px;
        margin: 0 0 20px;
        border-bottom: 1px solid;
      }

      &:last-child{
        margin: 0;
      }
    }

    .row-section {
      display: block;

      @media (min-width: $break-medium) {
        display: block;
      }
    }

    .row-label {
      display: block;
      font-weight: 700;
      margin-bottom: 0.5rem;

      @media (min-width: $break-medium) {
        display: none;
      }
    }

    .email {
      word-break: break-all;
    }

    h2 {
      font-size: 2.4rem;
      margin: 1rem 0 0.5rem;

      @media (min-width: $break-medium) {
        font-size: 2.8rem;
        margin: 0 0 0.5rem;
      }

      @media (min-width: $break-large) {
        font-size: 3.4rem;
      }

      a {
        font-weight: 500;

        @include cta-arrow( $font-styles: false);

        &:after {
          bottom: 0.2em;
          margin-left: 6px;

          @media (min-width: $break-large) {
            width: 10px;
            height: 10px;
            border-right: 4px solid $color-blue;
            border-top: 4px solid $color-blue;
          }
        }
      }
    }

    .email,
    .link-name {
      position: relative;
      vertical-align: top;
    }

    .link-name {
      line-height: 1;
      background-image: none;
    }

    .type {
      display: none;
    }

    .title {
      display: block;

      @media (min-width: $break-medium) {
        padding: 0;
      }
    }
  }

  .footer-table {
    @media (min-width: $break-medium) {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;
    }

    .table-info {
      display: none;
    }

    select {
      background-color: $color-light-gray;
    }
    .dataTables_length {
      position: relative;
    }
  }

  .heading-table-filter {
    position: relative;
    overflow: hidden;

    .form__item {
      margin: 0;
      padding: 1rem 2rem 2rem;
      background-color: $color-light-gray;
      border-top: 6px solid $color-yellow;

      @media (min-width: $break-medium) {
        border-top: 0;
      }

      @media (min-width: $break-large) {
        padding: 3rem 3rem 3rem 3.5rem;
        column-count: 2;
        max-width: 800px;
        border-top: 0;
      }

      &:not(.dataTables_filter) {
        @media (min-width: $break-medium) {
          width: 200%;
        }
      }
    }

    .form__checkbox {
      break-inside: avoid;

      @media (min-width: $break-large) {
        margin: 0 0 1.1rem;
        display: inline-block;
        width: 300px;      
      }

      label {
        font-weight: 400;
        padding-left: 2.4rem;

        @media (min-width: $break-large) {
          margin: 0
        }

        &:before {
          top: 0.1rem;
          left: 0;
          background-color: $color-white;
        }

        &:after{
          top: 0.5rem;
          left: 0.3rem;
        }
      }
    }

    @media (min-width: $break-large) {
      padding: 0;
    }

    &[aria-hidden="false"] {
      .clear-table {
        opacity: 1;
      }
    }
  }

  .dataTables_paginate {
    display: inline-flex;
    align-items: center;

    .ellipsis {
      margin: 8px 9px 4px 5px;
    }

    > span a {
      font-family: $font-roboto-condensed;
      font-weight: 500;
      margin: 4px 3px;
      width: 32px;
      height: 32px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-image: none; 

      @media (min-width: $break-large) {
        margin: 4px 0;
        width: 40px;
        height: 40px;
      }

      &.current {
        font-weight: 700;
        color: $color-dark-navy;
        background-color: $color-white;
        border-radius: 100em;

        &:first-child {
          margin-left: 10px;
        }
      }
    }

    .previous,
    .next {
      font-size: 0;
      line-height: 0;
      position: relative;
      display: block;
      width: 10px;
      height: 20px;
      background-image: none;

      &:hover {
        &:before {
          border-color: $color-white;
        }
      }

      &.disabled {
        pointer-events: none;
        
        &:before {
          opacity: 0.5;
          border-color: $color-light-gray;
        }
      }

      &:before {
        position: absolute;
        left: 1px;
        top: 2px;
        width: 12px;
        height: 12px;
        border-top: 3px solid $color-yellow;
        border-left: 3px solid $color-yellow;
        transform: rotate(-45deg);
        content: '';
        transition: border-color .3s ease;
      }

      &.previous {
        margin: 0;
      }

      &.next {
        margin: 0 0 0 -5px;

        @media (min-width: $break-medium) {
          margin: 0;
        }

        &:before {
          transform: rotate(135deg);

          @media (min-width: $break-medium) {
            left: -2px;
          }
        }
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.faculty__results {
  @include cta-button();
  position: sticky;
  bottom: 0;
  display: block;
  display: none;

  width: 100%;
  margin: 0 0 -1.9rem;

  @media (min-width: $break-medium) {
    margin: 0;
  }

  &:after {
    content: none;
  }
}


.faculty .heading-table-filter .form__item.dataTables_filter {
  position: relative;
  margin: 1px 0 19px;
  flex-grow: 1;
  padding: 3rem 2rem 1.7rem;
  background-color: $color-light-gray;
  border-top: 6px solid $color-yellow;

  @media (min-width: $break-medium) {
    border-top: 0;
  }

  @media (min-width: $break-large) {
    margin: 0;
    padding: 5rem 3rem 3rem;
    width: 100%;
    column-count: 1;
    max-width: 1040px;

  }

  label {
    position: relative;
  }

  input {
    @media (min-width: $break-large) {
      font-size: 2.5rem;
    }
  }


  label:after {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    right: 0;
    bottom: 1rem;
    background: url(../assets/icons/search.svg) no-repeat center / contain;
  }
}