
.alert {
  @media (min-width: $break-medium) {
    position: relative;
  }

  &--minor {
    background-color: $color-blue;
    color: $color-white;
  }

  &--medium {
    background-color: $color-yellow;

    .alert__icon {
      background-color: $color-white;
    }

    .cta--button {
      @include focus-dark-navy;

      &:not(:hover) {
        background-color: $color-white;
      }
    }
  }

  &--major {
    background-color: $color-red;
    color: $color-white;
  }

  &__content {
    padding: 2.2rem 2rem;

    @media (min-width: $break-medium) {
      padding: 2.1rem 6rem 2.2rem 11.7rem;
    }

    @media (min-width: $break-large) {
      display: flex;
      align-items: flex-start;
      padding: 2.4rem 4rem 0.3rem 12.7rem;
    }
  }

  &__icon {
    width: 4rem;
    height: 4rem;
    margin: 0 0 1.6rem;
    background-color: $color-yellow;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../assets/icons/alert-icon.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 0.5rem 2rem;

    @media (min-width: $break-medium) {
      position: absolute;
      top: 2.2rem;
      left: 3rem;
      width: 6rem;
      height: 6rem;
      background-size: 0.8rem 2.8rem;
    }

    @media (min-width: $break-large) {
      top: 2.4rem;
      left: 4rem;
    }
  }

  &__title {
    margin: 0 0 1.1rem;
    letter-spacing: 0.3px;
    font-family: $font-roboto-condensed;
    font-size: 2rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.3px;

    @media (min-width: $break-medium) {
      margin: 0 0 1rem;
      font-size: 2.4rem;
    }

    @media (min-width: $break-large) {
      margin: 0 0 0.9rem;
    }
  }

  &__message {
    margin: 0 0 2rem;
    
    @media (min-width: $break-medium) {
      padding: 0.2rem 0 0;
      font-size: 1.8rem;
    }

    @media (min-width: $break-large) {
      padding-right: 8rem;
    }
  }

  .cta--button {
    margin: 0;
    columns: $color-dark-navy;

    @media (min-width: $break-large) {
      flex-shrink: 0;
      margin: 2.4rem 0 0;
    }
  }
}