/**
 * @file
 * Calendar
 *
 * Contains styles for the Calendar search Page
 */

 .calendar-search {
  &__picker {
    display: inline-block;
    background-image: url("../../assets/icons/calendar.png");
    width: 16px;
    height: 16px;
  }

  &__col {
    margin-left: 20px;
  }

  label {
    display: block;
  }

  &-results {
    .results {
      &__header {
        font-weight: 700;
        border-bottom: 1px solid $color-black;
      }

       ul {
        padding: 0;
      }

      li {
        list-style: none;
      }

      time {
        display: block;
      }
    }
  }
 }