/**
 * @file
 * Accordion
 *
 * Contains styles for accordion content object.
 */

.accordion {
  margin: 0;
  position: relative;
  padding: 3rem 0;
  color: $color-white;

  &-box {
    @media (min-width: $break-large) {
      max-width: 80rem;
    }
  }

  @media (min-width: $break-medium) {
    padding: 5.1rem 0 4.1rem;
  }

  @media (min-width: $break-large) {
    padding: 5.1rem 0 4.6rem;
  }

  &:before {
    content: "";
    @include fullwidth-with-sidebar();
    background: $color-blue;
  }

  // Expand/collapse all wrapper
  &__all {
    display: flex;
    flex-direction: column;
    padding: 1.1rem 2rem;
    align-items: flex-start;
    margin: 3.3rem 0 0;
    background: $color-yellow;

    @media (min-width: $break-medium) {
      flex-direction: row;
      padding: 1rem 3.1rem 0.9rem;
      margin: 2.3rem 0 0;
    }

    @media (min-width: $break-large) {
      padding: 1.2rem 3.1rem 0.7rem;
      margin: 3.9rem 0 0;
    }

    button {
      position: relative;
      padding: 0 0 0 2.4rem;
      margin: 0 0 1.2rem;
      background-color: transparent;
      border: 0;
      color: $color-dark-navy;
      font-family: $font-roboto-condensed;
      font-size: 1.8rem;
      font-weight: 700;
      line-height: 2.2rem;
      letter-spacing: 0.1rem;
      text-transform: uppercase;
      transition: 0.3s ease-in-out;
      
      &:focus {
        @include focus-dark-navy();
      }

      @media (min-width: $break-medium) {
        font-size: 2rem;
        line-height: 2.7rem;
        margin: 0 3.7rem 0 0;
      }

      &:last-child {
        margin: 0;
      }

      &.js-disabled {
        opacity: 0.4;
        pointer-events: none;
      }
    }
  }

  // Expand all toggle
  &__expand-all {
    margin-right: 1.8em;
    cursor: pointer;

    &:hover {
      &:before,
      &:after {
        transform: translateY(-50%) scale(1.2);
      }
    }

    &:before,
    &:after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%) scale(1);
      background-color: $color-dark-navy;
      content: '';
      transition: 0.3s ease-in-out;
      will-change: transform;
    }

    &:before {
      left: 6px;
      border-right: 2px solid;
      height: 1.4rem;
    }

    &:after {
      left: 0;
      width: 1.4rem;
      border-top: 2px solid;
    }
  }

  // Collapse all toggle
  &__collapse-all {
    cursor: pointer;

    &:hover {
      &:after {
        transform: translateY(-50%) scale(1.2);
      }
    }

    &:after {
      position: absolute;
      top: 50%;
      left: 0;
      border-top: 2px solid;
      width: 1.4rem;
      transform: translateY(-50%) scale(1);
      content: '';
      transition: 0.3s ease-in-out;
    }
  }

  // Accordion item
  &__item {
    color: $color-dark-navy;
    background-color: $color-white;
    border-bottom: 1px solid $color-dark-navy;

    &:last-child {
      margin: 0;
    }

    a,
    button {
      @include focus-dark-navy;
    }
  }

  // Item toggle
  &__toggle {
    display: block;
    position: relative;
    padding: 2.1rem 2rem 2.1rem 5.4rem;
    width: 100%;
    background: transparent;
    border: 0;
    color: $color-dark-navy;
    cursor: pointer;
    font-weight: 700;
    line-height: 1.3;
    text-align: left;
    transition: 0.3s ease-in-out;
    font-family: $font-roboto;

    &:focus {
      @include focus-inset();
      @include focus-dark-navy();
  
    }
   
    @media (min-width: $break-medium) {
      padding: 2.5rem 3.5rem 2.4rem 7rem;
    }

    &:hover {
      .accordion__icon {
        background-color: $color-gray-blue;
      }
    }

    // Expanded toggle styling
    &.js-expanded {
      .accordion__icon {
        background-color: $color-gray-blue;

        &:before {
          height: 0;
        }
      }
    }
  }

  &__icon {
    position: absolute;
    top: 2rem;
    left: 1.6rem;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background-color: $color-yellow;
    transition: background-color .3s ease;

    @media (min-width: $break-medium) {
      top: 50%;
      left: 2rem;
      transform: translateY(-50%);
      width: 3.5rem;
      height: 3.5rem;
    }

    // Psuedo elements to make up +/-
    &:before,
    &:after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
      content: '';
      transition: 0.3s ease-in-out;
    }

    &:before {
      left: 50%;
      border-right: 2px solid;

      @media (min-width: $break-medium) {
        height: 1.4rem;
      }
    }

    &:after {
      left: 50%;
      width: 1rem;
      border-top: 2px solid;

      @media (min-width: $break-medium) {
        width: 1.4rem;
      }
    }
  }

  &__content {
    display: none;
    margin-top: -1rem;
    padding: 0 2rem 2.1rem 5.4rem;
    
    @media (min-width: $break-medium) {
      margin-top: -0.6rem;
      padding: 0 2.5rem 2.6rem 7rem;
    }

    @media (min-width: $break-large) {
      margin-top: -0.1rem;
    }

    p {
      margin: 0 0 1rem;
    }

    .views-element-container {
      width: 100%;
    }

    a {
      color: $color-blue;
      background-image: linear-gradient(to right, $color-blue 50%, $color-blue 50%);

      &:hover {
        color: $color-dark-navy;
        background-image: linear-gradient(to right, $color-dark-navy 50%, $color-dark-navy 50%);
      }
    }

    ul {
      li {
        &::marker {
          color: $color-blue;
        }
      }
    }
  }

  &__copy {
    width: 100%;

    .table--wysiwyg {
      margin: 0;
      width: 100%;

      @media (max-width: $break-medium - 1px) {
        margin: 0 1em;
        width: calc(100% + 2em);
      }
    }
  }

  &__top {
    position: relative;
    margin: 0.2rem 0 0;
    padding: 0 0 0 2.8rem;
    background: transparent;
    border: 0;
    font-weight: 700;
    font-family: $font-roboto-condensed;
    text-transform: uppercase;
    font-size: 1.8rem;
    line-height: 1.2;
    letter-spacing: 0.1rem;
    color: $color-blue;

    @media (min-width: $break-medium) {
      margin: 1rem 0 0;
      font-size: 2rem;
    }

    @media (min-width: $break-large) {
      margin: 1.3rem 0 0;
    }

    &:hover {
      &::after {
        background-color: $color-gray-blue;
      }
    }

    &:before {
      position: absolute;
      left: 0.4rem;
      top: 1rem;
      height: 0.5rem;
      width: 0.5rem;
      content: '';
      border-color: $color-dark-navy;
      border-style: solid;
      border-width: 0.2rem 0 0 0.2rem;
      transform: rotate(45deg) translateY(-50%);
      z-index: 2;

      @media (min-width: $break-medium) {
        top: 1.2rem;
      }
    }

    &:after {
      position: absolute;
      left: 0;
      top: 0;
      content: '';
      width: 2rem;
      height: 2rem;
      background-color: $color-yellow;
      border-radius: 50%;
      transition: background-color .3s ease;
      z-index: 1;

      @media (min-width: $break-medium) {
        top: 0.2rem;
      }
    }
  }
}
