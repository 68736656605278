/**
 * @file
 * Instagram
 *
 * Contains styles for instagram object.
 */

.instagram {
  color: #FFFFFF;
  position: relative;
  padding: 6rem 0 3rem;
  margin: 0;

  &:before {
    content: "";
    @include fullwidth-with-sidebar();
    position: absolute;
    height: 100%;
    z-index: -1;
    top: 0;
    background-color: $color-dark-navy;
  }

  &__feed { 
    display: flex;
    border:1px solid $color-white ;
  }

  h2 {
    color: $color-white;
    margin: 0 0 2.5rem;
    display: flex;
    align-items: center;

    &:after {
      content: "";
      width: 30px;
      height: 30px;
      display: inline-block;
      margin-left: 10px;
      background: url(../assets/icons/instagram-yellow.svg) no-repeat center / 100%;

      @media (min-width: $break-medium) {
        width: 50px;
        height: 50px;
        margin-left: 15px;
      }
    }
  }

  .column--three .column__col {
    overflow: hidden;
    margin: 0;

    @media (min-width: $break-medium) {
      flex-basis: 33.33%;
      margin-bottom: 0;
    }

    a:hover {
      background-size: cover;
      transform: scale(1.1);
      
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0.6;
        background-color: rgba($color-blue, 0.6);
        mix-blend-mode: color;
      }
    }

    a:focus {
      outline-color: $color-white;
      outline-offset: -4px;
    }
  }

  &__tile {
    aspect-ratio: 1;
    padding: 0;
    flex-basis: 33.33%;
    position: relative;
    
    &:last-child {
      display: none;
    }
  }

  &__account {
    @media (min-width: $break-medium) {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }

    @media (min-width: $break-large) {
      margin-bottom: 1rem;
    }
  }

  &__link {
    @include ir;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
  }

  .cta--button {
    // position: absolute;
    // bottom: 5.5rem;
    // margin: 0;
    // z-index: 10;

    @media (min-width: $break-medium) {
      top: 6.5rem;
      bottom: auto;
      right: 0;
      margin: 0 0 1rem 0;
      position: absolute;
    }

    @media (min-width: $break-large) {
      top: 8rem;
    }
  }
}