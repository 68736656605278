/**
 * @file
 * Page introduction
 *
 * Contains styles for accordion content object.
 */

.page-intro {
  margin: 2.5rem 0;
  font-size: 1.8rem;

  @media screen and (min-width: $break-medium) {
    margin: 4.5rem 0;
    font-size: 2rem;
  }

  @media screen and (min-width: $break-large) {
    font-size: 2.5rem;
    max-width: 800px;
  }
}