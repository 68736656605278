.events-and-news {
  padding: 4.1rem 0;

  @media (min-width: $break-large) {
    padding: 10.2rem 3rem 9.9rem;
  }

  a {
    @include focus-dark-navy();
  }

  .lined-header {
    color: $color-dark-navy;

    @media (min-width: $break-large) {
      padding: 0;   
    }

    &__subtile {
      @media (min-width: $break-large) {
        margin: 0 0 1.6rem;
      }

      &:before {
        background-color: $color-dark-navy;
      }
  
      .text-box {
        background-color: $color-white;

        @media (min-width: $break-large) {
          padding-right: 3.4rem;
        }
      }
    }
  
    &__title {
      margin: 0 0 3.9rem;

      @media (min-width: $break-medium) {
        margin: 0 0 2.9rem;
      }

      @media (min-width: $break-large) {
        margin: 0 0 4.8rem;
      }
    }
  }

  .content-holder {
    @media (min-width: $break-large) {
      display: flex;
      gap: 3rem;
    }
  }

  .news-box {
    margin: 0 0 6.3rem;

    @media (min-width: $break-large) {
      flex-shrink: 0;
      width: 48.5%;
      margin: 0;
    }

    &__image {
      position: relative;

      img {
        aspect-ratio: 280/147;
        width: 100%;
        height: 100%;
        object-fit: cover;

        @media (min-width: $break-medium) {
          aspect-ratio: 279/146;
        }

        @media (min-width: $break-large) {
          aspect-ratio: 504/287;
        }
      }
    }

    .date {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 0.5rem 1rem;
      font-size: 1.4rem;
      line-height: 1.6rem;
      font-weight: 500;
      background-color: $color-yellow;

      @media (min-width: $break-medium) {
        padding: 0.5rem 1.1rem;
        font-size: 1.6rem;
        line-height: 1.9rem;
      }
    }

    &__title {
      padding: 1.5rem 2rem 1.2rem;
      margin: 0 0 1.5rem;
      background-color: $color-dark-navy;
      letter-spacing: 0.4px;
      @include focus-white();

      @media (min-width: $break-medium) {
        padding: 1.6rem 3rem 2.1rem;
        margin: 0 0 2.5rem;
        letter-spacing: 0.0px;
      }

      @media (min-width: $break-large) {
        padding: 2.8rem 2.6rem 2.8rem;
        margin: 0 0 2.5rem;
        letter-spacing: 0.05rem;
      }

      h3 {
        margin: 0;
      }

      .cta--link {
        color: $color-white;
        @include focus-white();

        &:hover {
          color: $color-yellow;

          &:after {
            border-top-color: $color-yellow;
            border-right-color: $color-yellow;
          }
        }

        @media (min-width: $break-large) {
          line-height: 3.1rem;
        }

        &:after {
          border-top-color: $color-white;
          border-right-color: $color-white;
        }
      }
    }
  }

  .events-box {
    .events3up {
      &__item {
        @media (min-width: $break-medium) {
          flex-direction: row;
          margin: 0 0 3rem;
        }

        @media (min-width: $break-large) {
          margin: 0 0 4.1rem;
        }

        &:last-child {
          margin: 0;
        }

        h3 {
          margin: 0 0 0.9rem;

          @media (min-width: $break-medium) {
            margin: 0 0 1.2rem;
            font-size: 2.4rem;
            line-height: 2.7rem;
          }
  
          .cta--link {
            margin: 0;
            letter-spacing: 0.04rem;
            color: $color-blue;

            &:hover {
              color: $color-dark-navy;

              &:after {
                border-top-color: $color-dark-navy;
                border-right-color: $color-dark-navy;
              }
            }
  
            &:after {
              border-top-color: $color-blue;
              border-right-color: $color-blue;
            }

            @media (min-width: $break-large) {
              letter-spacing: 0;
            }
          }
        }
      }

      &__details {
        @media (min-width: $break-medium) {
          padding: 0.4rem 0 0;
        }

        @media (min-width: $break-large) {
          padding: 0;
        }
      }

      &__month {
        margin: 0 0 0.3rem;
      }

      &__date {
        @media (min-width: $break-medium) {
          margin: 0 2.3rem 0 0;
        }
      }
    }

    &__cta {
      margin-top: 10px;
    }

    .events__list {
      margin: 0 0 3.4rem;

      @media (min-width: $break-medium) {
        margin: 0 0 2.4rem;
      }
    }
  }
}