/**
 * @file
 * Form
 *
 * Contains styles for forms in main content region.
 */
 .main {
  .form {
    padding: 3.1rem 2rem;
    background-color: $color-white;
    color: $color-dark-navy;

    @media (min-width: $break-medium) {
      padding: 3.1rem 3rem;
    }

    button,
    input,
    a,
    select,
    textarea  {
      &:focus {
        @include focus-dark-navy();
      }
    }

    *:before,
    *:after {
      box-sizing: border-box;
    }

    .description {
      margin: 0 0 2.6rem;

      @media (min-width: $break-medium) {
        margin: 0 0 2.4rem;
      }
    }

    label {
      &[for] {
        cursor: pointer;
      }
    }

    &__item {
      margin: 0 0 3rem;

      @media (min-width: $break-medium) {
        margin: 0 0 2.7rem;
      }

      input,
      textarea,
      select {
        &:not(
          [type="submit"],
          [type="checkbox"],
          [type="radio"],
          [type=button],
          [type=reset]
        ) {
          color: $color-dark-navy;
          border: 1px solid $color-dark-navy;
          padding: 1rem 1.3rem 1.3rem;
          width: 100%;
          background: transparent;
          -webkit-appearance: none;
          border-radius: 0;

          @media (min-width: $break-medium) {
            padding: 0.9rem 1.3rem 1.1rem;
          }
        }

        &[type="submit"] {
          border: none;
          padding-right: 2.7rem;
          background-image: url('../../assets/icons/arrow-right-bold.svg');
          background-repeat: no-repeat;
          background-position: 91% 50%;
          background-size: 1.2rem 1.5rem;
          transition: background-color .3s ease;

          @media (min-width: $break-medium) {
            padding-right: 3.2rem;
            margin: 0.7rem 0 0;
            background-position: 92% 50%;
            background-size: 1.5rem 2.2rem;
          }
        }
      }

      textarea {
        min-height: 11.9rem;
        display: block;
      }

      &.form__item {
        &.form--select {
          position: relative;
          display: inline-block;
          min-width: 245px;
        }

        &.form--select:before {
          content: "";
          position: absolute;
          bottom: 2rem;
          right: 1.7rem;
          width: 6px;
          height: 6px;
          border-right: 1px solid;
          border-bottom: 1px solid;
          transform: rotate(45deg);
        }

        select {

          @media (min-width: $break-medium) {
            max-width: 24.5rem;
          }
        }
      }

      &:not(.form--fieldset) {
        label {
          display: block;
          font-weight: 700;
          margin: 0 0 0.9rem;
        }
      }

      legend {
        display: block;
        font-weight: 700;
        margin: 0 0 1.6rem;

        @media (min-width: $break-medium) {
          margin: 0 0 1.3rem;
        }
      }
    }

    .form--fieldset {
      border: none;
      padding: 0;
      margin: 0 0 2.4rem;

      @media (min-width: $break-medium) {
        margin: 0 0 2.1rem;
      }
    }

    .form__checkbox,
    .form__radio {
      &:not(:last-child) {
        margin: 0 0 1.5rem;

        @media (min-width: $break-medium) {
          margin: 0 0 1.1rem;
        }
      }

      label {
        position: relative;
        padding-left: 2rem;

        &:before,
        &:after {
          content: '';
          position: absolute;
        }
        
        &:before {
          top: 0.3rem;
          left: -0.4rem;
          width: 1.4rem;
          height: 1.4rem;
          border: 1px solid $color-dark-navy;
          background-color: $color-white;
        }

        &:after {
          top: 0.6rem;
          left: -0.1rem;
          width: 0.8rem;
          height: 0.5rem;
          border-left: 2px solid $color-dark-navy;
          border-bottom: 2px solid $color-dark-navy;
          transform: rotate(-45deg);
          opacity: 0;
        }
      }

      input[aria-invalid="true"] + label {
        &:before {
          border: 2px solid $color-input-error;
        }
      }

      input:checked + label {
        &:before {
          border: none;
          background: $color-yellow;
        }

        &:after {
          opacity: 1;
        }
      }

      input:focus {
        outline: 0;
      }

      input:focus + label:before {
        outline: 1px solid;
        outline-offset: 4px;
        transition: none;
        @include focus-dark-navy();
      }

      input {
        opacity: 0;
      }
    }

    .form__radio {
      input {
        opacity: 0;
      }

      label {
        position: relative;
        padding-left: 2rem;

        &:before,
        &:after {
          border-radius: 50%;
        }

        &:after {
          color: $color-dark-navy;
          top: 0.7rem;
          left: 0;
          width: 0;
          border: 0.3rem solid;
          border-radius: 100%;
          opacity: 0;
        }
      }

      input:checked + label {
        &:before {
          border: none;
          background: $color-yellow;
        }

        &:after {
          opacity: 1;
        }
      }
    }
  }
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}
