.lined-header {
  @media (min-width: $break-large) {
    padding: 0 3rem;
  }

  &__subtile {
    position: relative;
    margin: 0 0 1.3rem;
    font-family: $font-roboto-condensed;
    font-size: 1.8rem;
    line-height: 1.2;
    font-weight: 700;
    letter-spacing: 0.09rem;
    
    &:before {
      content: '';
      display: none;
      position: absolute;
      top: 1.3rem;
      width: 100%;
      height: 1px;
      background-color: $color-white;
      z-index: 1;

      @media (min-width: $break-medium) {
        display: block;
      }

      @media (min-width: $break-large) {
        top: 1.1rem;
      }
    }

    @media (min-width: $break-medium) {
      margin: 0 0 2rem;
      font-size: 2rem;
      line-height: 1.36;
      letter-spacing: 0.1rem;
    }

    @media (min-width: $break-large) {
      margin: 0 0 2.5rem;
    }

    .text-box {
      position: relative;
      display: inline-block;
      padding-right: 1.3rem;
      background-color: $color-dark-navy;
      z-index: 2;
      text-transform: uppercase;
    }
  }

  &__title {
    margin: 0 0 1.5rem;

    @media (min-width: $break-medium) {
      margin: 0 0 2.8rem;
    }

    @media (min-width: $break-large) {
      margin: 0 0 3.6rem;
    }
  }
}