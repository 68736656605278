/**
 * @file
 * Columns
 *
 * Base styles for all column layouts.
 */

.column {
  @media (min-width: $break-medium) {
    display: flex;
  }

  &__col {
    margin: 0 0 2.4rem;

    @media (min-width: $break-medium) {
      margin: 0;
      flex: 0;
    }

    &:last-child {
      margin: 0;

      @media (min-width: $break-medium) {
        margin: 0;
      }
    }
  }

  &__img {
    margin: 0 0 2rem;

    @media (min-width: $break-large) {
      margin: 0 0 2rem;
    }

    img {
      width: 100%;
      aspect-ratio: 280/187;
      object-fit: cover;
    }

    a {
      @include focus-inset();
      display: block;
    }
  }

  &__title {
    margin: 0 0 1rem;
    font-family: $font-roboto-condensed;
    font-size: 2.6rem;
    line-height: 1.1;

    @media (min-width: $break-medium) {
      font-size: 3.6rem;
    }

    @media (min-width: $break-large) {
      margin: 0 0 1.3rem;
      font-size: 4.8rem;
      line-height: 1.15;
    }

    h3 {
      margin: 0;
      font-size: inherit;
      font-weight: 300;
      line-height: inherit;
      font-family: inherit;

      @media (min-width: $break-large) {
        line-height: 1.1;
      }
    }

    a, .cta--link {
      margin: 0;
      font-weight: 400;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;

      @media (min-width: $break-large) {
        line-height: 1.15;
      }

      &::after {
        width: 0.7rem;
        height: 0.7rem;
        bottom: 0.9rem;
        margin: 0 0 0 0.6rem;

        @media (min-width: $break-medium) {
          width: 1.1rem;
          height: 1.1rem;
          bottom: 1.4rem;
          margin: 0 0 0 0.3rem;
        }

        @media (min-width: $break-large) {
          width: 2rem;
          height: 2rem;
          bottom: 1.7rem;
          border-right-width: 0.4rem;
          border-top-width: 0.4rem;
        }
      }
    }
  }

  &__subtitle {
    margin: 0 0 1.1rem;
    font-family: $font-roboto-condensed;
    font-size: 1.8rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.1rem;

    @media (min-width: $break-medium) {
      font-size: 2rem;
    }
  }

  &__body {
    ul {
      margin: 0;
      padding-left: 0.5rem;

      @media (min-width: $break-medium) {
        margin: 1.9rem 0 0;
      }

      li {
        padding-left: 0.4rem;
      }
    }
  }
}

.column--two {
  @media (min-width: $break-medium) {
    justify-content: space-between;
  }

  .column__col {
    @media (min-width: $break-medium) {
      flex-basis: calc(50% - 1rem); // 50% - half container gutter
    }

    @media (min-width: $break-large) {
      flex-basis: calc(50% - 1.5rem); // 50% - half container gutter
    }
  }
}

.column--three {
  @media (min-width: $break-medium) {
    justify-content: space-between;
  }

  .column__col {
    @media (min-width: $break-medium) {
      flex-basis: calc((100% / 3) - (4rem / 3)); // 33.33% - 1/3 container gutter
    }
  }

  &.slick-slider {
    display: block;
  }
}