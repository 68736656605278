/**
 * @file
 * Generic Block: Image list
 *
 * Styles for Generic Block: Image list object.
 */

.genimglist {
  margin: 0;
  position: relative;
  padding: 5rem 0 2.4rem;
  color: $color-white;

  @media (min-width: $break-medium) {
    padding: 5rem 0 2.2rem;
  }

  &:before {
    content: "";
    @include fullwidth-with-sidebar();
    background: $color-blue;
  }

  &__item {
    margin: 0 0 2.7rem;

    @media (min-width: $break-medium) {
      display: flex;
      gap: 2rem;
      justify-content: space-between;
      margin: 0 0 2.5rem;
    }

    @media (min-width: $break-large) {
      gap: 3rem;
    }
  }

  &__img {
    margin: 0 0 1.2rem;

    @media (min-width: $break-medium) {
      flex: 0;
      flex-basis: 40%;
      margin: 0;
    }

    img {
      width: 100%;
      aspect-ratio: 280/177;
      object-fit: cover;
    }
  }

  &__content {
    @media (min-width: $break-medium) {
      flex: 0;
      flex-basis: 65.5%;
      flex-shrink: 0;
      padding-right: 1rem;
    }

    @media (min-width: $break-large) {
      flex-basis: 64.65%;
      padding-right: 0;
    }
  }

  &__title {
    margin: 0 0 1rem;

    @media (min-width: $break-large) {
      margin: 0 0 1.3rem;
    }

    h3 {
      margin: 0;
      font-family: $font-bitter;
      font-size: 2.2rem;
      line-height: 1.3;
      text-transform: none;
      font-weight: 600;

      @media (min-width: $break-medium) {
        font-size: 3.2rem;
      }

      @media (min-width: $break-large) {
        font-size: 4rem;
        line-height: 1.2;
      }
    }

    a {
      font-size: inherit;
      font-family: inherit;
      line-height: inherit;
      font-weight: inherit;
      text-transform: inherit;
    }

    .cta--link {
      margin: 0;

      &::after {
        width: 0.7rem;
        height: 0.7rem;
        bottom: 0.9rem;
        margin: 0 0 0 0.6rem;

        @media (min-width: $break-medium) {
          width: 1.1rem;
          height: 1.1rem;
          bottom: 1.4rem;
          margin: 0 0 0 0.3rem;
        }

        @media (min-width: $break-large) {
          width: 1.5rem;
          height: 1.4rem;
          bottom: 1.5rem;
          margin: 0 0 0 0.3rem;
        }
      }
    }
  }

  &__subtitle {
    margin: 0 0 1.1rem;
    font-family: $font-roboto-condensed;
    font-size: 1.8rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.1rem;

    @media (min-width: $break-medium) {
      font-size: 2rem;
    }
  }
}
