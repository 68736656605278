/**
 * @file
 * Carousel
 *
 * Contains styles for carousel object.
 */


.carousel {
  margin: 0;
  padding-top: 5rem;
  padding-bottom: 6rem;
  color: $color-white;
  position: relative;

  @media (min-width: $break-medium) {
    padding-bottom: 4rem;
  }

  &:before {
    content: "";
    @include fullwidth-with-sidebar();
    background: $color-dark-navy;
  }

  &__controls {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #FFFFFF;
    display: flex;
    align-items: center;
    gap: 1.1rem;

    @media (min-width: $break-medium) {
      position: absolute;
      bottom: 6.75rem;
      right: 2.5rem;
      border-bottom: 0;
      padding-bottom: 0;
      gap: 1.5rem;
    }

    @media (min-width: $break-large) {
      bottom: 7rem;
    }
  }

  &__wrapper {
    margin-top: 2rem;

    @media (min-width: $break-medium) {
      margin-top: 2.5rem;
    }

    @media (min-width: $break-large) {
      margin-top: 4rem;
    }
  }

  &__item {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 52%;
  }

  &__caption {
    color: $color-white;
    font-size: 1.4rem;
    margin: 1rem 0 0;
    padding-bottom: 0.6rem;
    opacity: 0;
    transition: all 300ms;

    @media (min-width: $break-medium) {
      background: linear-gradient(180deg, rgba(27, 62, 148, 0) 0%, rgba(9, 14, 55, 0.9) 100%);
      position: absolute;
      bottom: 0;
      padding: 3rem 25rem 2rem 2rem;
      width: 100%;
      border-bottom: 1px solid $color-white;

    }

  }

  .slick-slide {
    transition: all 300ms 600ms !important
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    a {
      outline-offset: -2px;
      display: block;
      height: 100%;
      background-image: none;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      opacity: 0;
      transition: opacity 300ms 300ms, transform 0ms 1200ms;
      transform: translateX(250px);
      position: relative; 
      z-index: -1;
    }
  }

  .slick-active {
    .carousel__img img {
      opacity: 1; 
      transform: translateX(0px);
      transition: all 500ms 600ms;;
      // animation: fadeInLeft 300ms 600ms forwards;
    }

    .carousel__caption {
      opacity: 1;
      transition: all 300ms 1100ms;
    }
  }
}

@keyframes fadeInLeft {
  from {  opacity: 0; transform: translateX(150px);}
  to {  opacity: 1; transform: translateX(0px);}
}
