/**
 * @file
 * Typography
 * Base typography for the theme
 */

//## Headers
h1 {
  color: $color-white;
  font-family: $font-bitter;
  font-size: 3.9rem;
  font-weight: 500;
  line-height: 1;
  margin: 0;

  @media (min-width: $break-medium) {
    font-size: 6rem;
  }

  @media (min-width: $break-large) {
    font-size: 10rem;
  }
}

h2 {
  font-family: $font-bitter;
  margin: 5rem 0 1rem;
  font-size: 3rem;
  font-weight: 500;
  line-height: 1.1;

  @media (min-width: $break-medium) {
    font-size: 4.8rem;
    margin: 6rem 0 1.5rem;
  }

  @media (min-width: $break-large) {
    font-size: 6.4rem;
    line-height: 1.2;
    margin: 5rem 0 1.5rem;
  }
}

h3 {
  font-family: $font-roboto-condensed;
  margin: 5rem 0 1rem;
  font-size: 2.6rem;
  font-weight: 300;
  line-height: 1.21;
  text-transform: uppercase;

  @media (min-width: $break-medium) {
    margin: 6rem 0 1.5rem;
    font-size: 3.6rem;
  }

  @media (min-width: $break-large) {
    font-size: 4.8rem;
  }
}

h4 {
  font-family: $font-bitter;
  margin: 5rem 0 1rem;
  font-size: 2.2rem;
  font-weight: 600;
  line-height: 1.21;

  @media (min-width: $break-medium) {
    margin: 6rem 0 1.5rem;
    font-size: 3.2rem;
  }

  @media (min-width: $break-large) {
    font-size: 4rem;
  }
}

h5 {
  font-family: $font-roboto-condensed;
  margin: 5rem 0 1rem;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.21;
  text-transform: uppercase;
  letter-spacing: 0.02em;

  @media (min-width: $break-medium) {
    margin: 6rem 0 1.5rem;
    font-size: 2.4rem;
  }
}

h6 {
  font-family: $font-roboto-condensed;
  margin: 5rem 0 1rem;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.21;
  text-transform: uppercase;
  letter-spacing: 0.05em;

  @media (min-width: $break-medium) {
    margin: 6rem 0 1rem;
    font-size: 2rem;
  }
}

ul {
  padding-left: 15px;
  margin: 2rem 0;

  li {
    padding-left: 6px;
    margin: 1rem 0;
    list-style-position: inside;

    ul {
      margin: 0;
    }

    &::marker {
      content: '•';
      letter-spacing: 5px;
      color: $color-yellow;
    }
  }
}

ol {
  padding-left: 30px;
  margin: 2rem 0;

  li {
    padding-left: 6px;
    margin: 1rem 0;
    list-style-position: inside;

    ol {
      margin: 0;
      padding-left: 20px;
    }
  }
}

.wysiwyg {
  color: $color-white;
  background-color: $color-dark-navy;
  margin: 0 -2rem;
  padding: 1px 2rem 4rem;
  position: relative;

  &:before {
    content: "";
    @include fullwidth-with-sidebar();
    background: $color-dark-navy;
  }

  .wysiwyg__inner {
    @media (min-width: $break-large) {
      max-width: 800px;
    }
  }

  @media (min-width: $break-medium) {
    margin: 0 -3rem;
    padding: 1px 3rem 6rem;
  }

  @media (min-width: $break-large) {
    padding-bottom: 10rem;
  } 
}

//## Paragraphs
p {
  margin: 0 0 1em;

  &:empty {
    display: none;
  }
}

strong {
  font-weight: 700;
}
