.home-divisions {
  position: relative;
  background-color: $color-white;
  border: 1px solid $color-white;
  margin: 4rem 0;

  @media screen and (min-width: $break-large) {
    margin: 6rem -1rem;
  }

  &:before {
    content: "";
    @include fullwidth-with-sidebar();
    background: $color-dark-navy;
    height: auto;
    top: -41px;
    bottom: -41px;

    @media screen and (min-width: $break-large) {
      top: -61px;
      bottom: -61px;
    }
  }

  &__eyebrow {
    font-family: $font-roboto-condensed;
    font-size: 1.8rem;
    font-weight: 700;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    display: block;
    overflow: hidden;

    @media screen and (min-width: $break-medium) {
      font-size: 2rem;
      margin-bottom: 1.5rem;
      background-color: $color-white;


      span {
        background-color: $color-white;
        position: relative;
        padding-right: 20px;
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          width: calc(100vw - var(--scrollbar-width));
          left: 100%;
          z-index: 0;
          height: 1px ;
          background-color: $color-dark-navy;
        }
      }

     
    }

    @media screen and (min-width: $break-large) {
      width: 100%;
    } 
  }

  h2 {
    font-size: 2.6rem;
    margin-top: 0;
    margin-bottom: 1.5rem;

    @media screen and (min-width: $break-medium) {
      font-size: 4rem;
      margin-bottom: 2.5rem;
    }

    @media screen and (min-width: $break-large) {
      font-size: 4.8rem;
      width: 50%;
      margin-bottom: 0;
    }
  }

  .cta--button {
    margin-bottom: 0;
  }

  &__intro {
    overflow: hidden;
    padding: 2.5rem 2rem 3rem;

    @media screen and (min-width: $break-medium) {
      padding: 3.5rem 3rem 5.7rem;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        height: 50px;
        background: $color-white;
        width: 30px;
        z-index: 10;

        @media screen and (min-width: $break-large) {
          width: 40px;
        }
      }
    }

    @media screen and (min-width: $break-large) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      padding: 3.5rem 4rem 4.7rem;
    }

    a:focus {
      @include focus-dark-navy();
    }
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    opacity: 0;
    z-index: 5;
    transition: all 300ms;
  }

  h3 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    a {
      @include cta-arrow($color-yellow, $color-white, true);
      letter-spacing: 0.02em;
      background-image: none;

      @media screen and (min-width: $break-large) {
        font-size: 2.4rem;
      }
    }
  }

  &.inview img {
    opacity: 1;
  }

  &.inview .home-divisions__item:before {
    opacity: 1;
  }

  &__items {
    @media screen and (min-width: $break-medium) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__item {
    color: $color-white;
    position: relative;
    background-color: $color-blue;
    display: flex;
    min-height: calc((100vw - 4rem) * 0.7);

    @media screen and (min-width: $break-medium) {
      width: 50%;
      min-height: calc((100vw - 6rem) / 2 * 0.788);
    }

    @media screen and (min-width: $break-large) {
      min-height: calc((100vw - 6rem) / 2 * 0.579);
    }

    @for $i from 1 through 30 {
      &:nth-child(#{$i}) img{
        transition-delay: 200ms * $i;
      }

      &:nth-child(#{$i}):before {
        transition-delay: 100ms * $i;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
      background: linear-gradient(180deg, rgba(27, 62, 148, 0.2) 0%, #090E37 100%);
      mix-blend-mode: multiply;
      opacity: 1;
      transition: all 300ms;

      @media screen and (min-width: $break-large) {
        background: linear-gradient(180deg, rgba(27, 62, 148, 0.3) 0%, #090E37 100%);
      }
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 7.4rem 2rem 2rem;
    z-index: 50;

    @media screen and (min-width: $break-medium) {
      padding: 8.6rem 3rem 3rem;
    }

    @media screen and (min-width: $break-large) {
      padding: 10rem 4rem 4rem;
    }
  }

}

.section-nav + .container {
  .home-divisions {
    margin-top: 40px;

    &:before {
      top: -101px;
    }
  }
  @media screen and (min-width: $break-medium) {
    .home-divisions {
      margin-top: 80px;

      &:before {
        top: -71px;
      }
    }
  }
  @media screen and (min-width: $break-large) {
    .home-divisions {
      margin-top: 100px;
      margin-right: -40px;
      margin-left: 0;

      &:before {
        top: -101px;
        margin-left: -14rem;
      }
    }
    .home-divisions__intro {
      display: block;

      h2 {
        width: 100%;
        margin-bottom: 1.5rem;
      }
    }
  }
}