/**
 * @file
 * Twitter
 *
 * Contains styles for twitter object.
 */

 .twitter {
  color: #FFFFFF;
  position: relative;
  padding: 6rem 0 5rem;
  margin: 0;

  @media (min-width: $break-large) {
    padding: 6rem 0 10rem;
  }

  &:before {
    content: "";
    @include fullwidth-with-sidebar();
    position: absolute;
    height: 100%;
    z-index: -1;
    top: 0;
    background-color: $color-dark-navy;
  }

  h2 {
    color: $color-white;
    margin: 0 0 2.5rem;
    display: flex;
    align-items: baseline;

    @media (min-width: $break-medium) {
      display: block;
      padding-right: 200px;
    }

    &:after {
      content: "";
      width: 30px;
      height: 30px;
      display: inline-block;
      top: 3px;
      position: relative;
      margin-left: 10px;
      background: url(../assets/icons/instagram-yellow.svg) no-repeat center / 100%;

      @media (min-width: $break-medium) {
        width: 50px;
        height: 50px;
        margin-left: 15px;
        position: absolute;
        top: auto;

      }

      @media (min-width: $break-large) {
        margin-top: 13px;
      }
    }
  }

  &__feed {
    @media (min-width: $break-medium) {
      gap: 2rem;
    }
  }

  .column--three .column__col {
    overflow: hidden;
    padding-top: 2rem;
    border-top: 5px solid $color-yellow;
    margin-bottom: 2rem;

    &:nth-child(4),
    &:nth-child(5) {
      display: none;
    }

    @media (min-width: $break-medium) {
      flex-basis: 33.33%;
      margin-bottom: 0;
    }

    time {
      margin-top: 1rem;
      display: block;
    }

    .rt {
      display: block;

      &:before {
        content: "rt";
        display: inline-block;
        vertical-align: middle;
        text-indent: -1000px;
        width: 10px;
        height: 11px;
        margin-right: 10px;
        background: url(../assets/icons/retweet.svg) no-repeat center / 100%;
      }
    }
  }
  &__account {
    @media (min-width: $break-medium) {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }

    @media (min-width: $break-large) {
      margin-bottom: 1rem;
    }
  }

  .cta--button {
    margin: 0;

    @media (min-width: $break-medium) {
      top: 6.5rem;
      bottom: auto;
      right: 0;
      margin: 0 0 1rem 0;
      position: absolute;
    }

    @media (min-width: $break-large) {
      top: 8rem;
    }
  }
}