.learn-at-lawson-intro {
  padding: 2.4rem 0;
  font-size: 1.8rem;
  line-height: 2.3rem;
  color: $color-dark-navy;

  @media (min-width: $break-medium) {
    padding: 5.1rem 0 0;
    font-size: 2rem;
    line-height: 2.6rem;
  }

  @media (min-width: $break-large) {
    font-size: 2.5rem;
    line-height: 3.2rem;
  }

  & + section {
    @media (min-width: $break-medium) {
      padding-top: 7rem;
    }
  }

  .content-box {
    & > p:first-child {
      @media (min-width: $break-medium) {
        grid-area: text;
      }

      @media (min-width: $break-large) {
        margin: 0 0 3.2rem;
      }
    }
  
    & > .left-side-content {
      @media (min-width: $break-medium) {
        grid-area: etc;
        padding: 0 0 1.5rem;
      }

      @media (min-width: $break-large) {
        padding: 0 0 0;
      }
    }
  
    .mini-story {
      @media (min-width: $break-medium) {
        grid-area: mini-story;
      }
    }

    @media (min-width: $break-medium) {
      display: grid;
      grid-template-columns: 1fr 32rem;
      grid-template-rows: auto auto;
      grid-auto-flow: row;
      grid-template-areas:
        "text text "
        "etc mini-story";
    }

    @media (min-width: $break-large) {
      grid-template-columns: 1fr 32.8rem;
      grid-template-rows: auto auto;
    }
  }

  .mini-story {
    @media (min-width: $break-medium) {
      margin-right: -3rem;
      width: 100%;
      margin: -0.1rem -3rem -5.1rem 3rem;
    }

    @media (min-width: $break-large) {
      min-width: 37.3rem;
      margin: -0.5rem -7rem -4rem 3.5rem; 
    }
  }

  .icon__cta {
    display: flex;
    padding: 3.4rem 0;
    margin: 3.3rem 0 0.8rem;
    border-top: 1px solid $color-dark-navy;

    @media (min-width: $break-medium) {
      margin: 3.8rem 0 0.8rem;
      padding: 4rem 0;
    }

    @media (min-width: $break-large) {
      padding: 5rem 0 4.7rem;
      margin: 5.6rem 2rem 0 0;
    }

    .icon {
      display: flex;
      flex-shrink: 0;
      width: 5.5rem;
      height: 5.5rem;
      margin: 0 1.3rem 0 -0.2rem;
      padding: 1.1rem;
      justify-content: center;
      align-items: center;
      background-color: $color-yellow;
      border-radius: 50%;

      @media (min-width: $break-medium) {
        width: 7.5rem;
        height: 7.5rem;
        padding: 1.5rem;
        margin: 0 3rem 0 0;
      }

      @media (min-width: $break-large) {
        width: 9rem;
        height: 9rem;
        padding: 1.7rem 1.5rem 1.3rem;
        margin: 0 3.8rem 0 0;
      }
    }

    .cta__text {
      margin: 0 0 0.2rem;

      @media (min-width: $break-medium) {
        margin: 0 0 0.8rem;
      }
    }

    .cta--link {
      color: $color-blue;
      @include focus-dark-navy;

      &:hover {
        color: $color-dark-navy;

        &::after{
          border-color: $color-dark-navy;
        }
      }

      &::after{
        border-color: $color-blue;
      }
    }
  }
}