.mini-story {
  margin: 0 auto;
  z-index: 1;
  opacity: 0;
  transition: opacity .5s ease;

  @media (min-width: $break-medium) {
    max-width: 32rem;
  }

  @media (min-width: $break-large) {
    max-width: 37.3rem;
  }

  &.visible {
    opacity: 1;

    .mini-story__label {
      opacity: 1;
    }
  }
  
  &__header {
    position: relative;
  }

  &__label {
    opacity: 0;
    transition: opacity .2s ease .5s;
    position: absolute;
    left: 0;
    bottom: 0;
    display: none;
    padding: 0.7rem 1.5rem 0.6rem;
    z-index: 3;
    background-color: $color-white;
    font-size: 1.4rem;
    line-height: 1.6rem;
    font-weight: 500;

    @media (min-width: $break-medium) {
      display: inline-block;
    }
  }

  &__author,
  a.mini-story__author {
    position: relative;
    display: inline-block;
    min-width: 1.3rem;
    max-width: 100%;
    padding: 0.5rem 1.3rem;
    font-weight: 900;
    font-size: 3.8rem;
    line-height: 1;
    background-color: $color-yellow;
    font-family: $font-roboto-condensed;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    word-break: break-word;
    color: $color-dark-navy;
    text-decoration: none;
    z-index: 2;

    @media (min-width: $break-medium) {
      font-size: 4rem;
      padding: 0.6rem 1.3rem;
    }
  }
  
  a.mini-story__author {
    transition: background-color .3s ease;
    font-weight: 900;
    outline-offset: -2px;
    font-family: $font-roboto-condensed;

    &:hover {
      background-color: $color-gray-blue;
      color: $color-dark-navy;
      text-decoration: none;
      background-image: none;
    }
  }

  &__image {
    position: relative;
    overflow: hidden;
    margin-top: -2rem;
  }

  &__background {
    width: 100%;
    position: relative;
    aspect-ratio: 280/150;
    z-index: 1;
    margin-top: -2.2rem;

    @media (min-width: $break-medium) {
      aspect-ratio: 280/158;
    }

    @media (min-width: $break-large) {
      aspect-ratio: 280/162;
    }
  }

  &__foreground {
    position: absolute;
    bottom: 0;
    top: auto;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    height: 98%;
    max-width: 100%;
    object-fit: cover;
    object-position: top;

    @media (min-width: $break-large) {
      aspect-ratio: 280/162;
    }
  }

  &__quote-box {
    position: relative;
    padding: 2.4rem 3rem 1.5rem 4.6rem;
    background-color: $color-blue;
    color: $color-white;

    @media (min-width: $break-large) {
      padding: 2.4rem 3rem 1.5rem 5.2rem;
    }

    &::before {
      content: '“';
      position: absolute;
      left: 1.9rem;
      top: 3.6rem;
      font-family: $font-bitter;
      font-size: 3.8rem;

      @media (min-width: $break-medium) {
        top: 3.4rem;
      }

      @media (min-width: $break-large) {
        top: 3.1rem;
        left: 2.5rem;
      }
    }

    &:after {
      content: '';
      position: absolute;
      left: 2.6rem;
      top: 5.2rem;
      height: calc(100% - 7.4rem);
      width: 1px;
      background-color: $color-white;

      @media (min-width: $break-medium) {
        height: calc(100% - 7.9rem);
      }

      @media (min-width: $break-large) {
        left: 3.2rem;
        height: calc(100% - 7.7rem);
      }
    }
  }

  &__quote {
    margin: 0 0 0.6rem;
    font-size: 1.6rem;
    line-height: 2.2rem;
    font-family: $font-bitter;

    @media (min-width: $break-medium) {
      font-size: 1.8rem;
      line-height: 2.4rem;
    }

    @media (min-width: $break-large) {
      margin: 0 0 1rem;
    }
  }

  &__quote-cta {
    background-color: $color-blue;

    .cta--link {
      @media (min-width: $break-large) {
        letter-spacing: 0.5px;
      }
    }
  }
}