/**
 * Variables
 * Contains global variables available to the theme.
 */

//
// Fonts
//

/// Arial
$font-arial: Arial, Helvetica, sans-serif;
$font-roboto: 'Roboto', sans-serif;
$font-roboto-condensed: 'Roboto Condensed', sans-serif;
$font-bitter: 'Bitter', serif;
$font-karla: 'Karla', serif; // Only quote marks
//
// Breakpoints
//
$break-x-large: 1201px;
$break-large: 1025px;
$break-medium: 700px;

// Content
$content-max: 1800px;

//
// Colors
//
$color-white: #FFFFFF;
$color-black: #000;
$color-blue: #1B3E94;
$color-dark-navy: #090E37;
$color-light-gray: #F0F0F0;
$color-yellow: #FFC424;
$color-gray-blue: #BBC4D9;
$color-red: #CA0000;
$color-input-error: $color-red;

