

.main .pathway-program__wrapper {
  position: relative;
  padding-top: 3rem;
  padding-bottom: 3rem;
  margin-top: -3.5rem;

  @media (min-width: $break-medium) {
    padding-top: 10rem;
    margin-top: -3rem;
  }

  @media (min-width: $break-large) {
    padding-top: 17rem;
    margin-top: -10rem;
  }


  h2 {
    color: $color-white;
  }

  .pathway-program__filters__inner {
    margin: 0 0 3.1rem;
    background-color: $color-white;
    position: relative;

    @media (min-width: $break-medium) {
      margin: 0 0 70px;
      display: flex;
      flex-wrap: wrap;
    }

    @media (min-width: $break-large) {
      flex-direction: column;
    }

    .pathway-programs__tabs {
      @media (min-width: $break-medium) {
        display: flex;
        align-items: stretch;
        width: 50%;
        float: left;
      }

      @media (min-width: $break-large) {
        display: block;
        width: 100%;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          height: 50px;
          background: $color-white;
          width: 30px;
          z-index: 10;
        }
      }
    }

    .pathway-programs__tabs__list {
      @media (max-width: $break-medium - 1px) {
        display: none;
      }

      @media (min-width: $break-large) {
        float: none;
        width: 100%;
      }
    }


    .pathway-programs__panels{
      padding: 10px 0 0;

      @media (min-width: $break-medium) {
        width: 50%;
        float: right;
        padding-top: 0;
        background: $color-light-gray;
        display: flex;
        align-items: center;
      }

      @media (min-width: $break-large) {
        display: block;
        float: none;
        width: 100%;
        position: relative;
        padding: 0;
      }

      .form__item {
        margin: 0;
        padding: 1rem 3rem 2rem;
        background-color: $color-light-gray;
        border-top: 6px solid $color-yellow;
    
        @media (min-width: $break-medium) {
          border-top: 0;
        }
    
        @media (min-width: $break-large) {
          padding: 3rem 3rem 3rem 3.5rem;
          column-count: 2;
          border-top: 0;
        }
      }
    
      .form__checkbox {
        break-inside: avoid;
        margin: 0 0 1rem;
    
        @media (min-width: $break-large) {
          display: inline-block;
          width: 400px;   
        }
    
        label {
          font-weight: 400;
          padding-left: 1rem;
          margin: 0;
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;

          @media (min-width: $break-large) {
            padding-left: 2rem;
          }

          &:before {
            position: static;
            margin-left: -1em;
            margin-right: 10px;
            display: inline-block;
          }

          &:after {
            left: -0.3rem;
            top: 0.4rem;
            @media (min-width: $break-large) {
              left: 0.5rem;
            }
          }

          span.program-type-name {
            margin-bottom: 10px;
            display: inline-block;
            vertical-align: text-top;
            max-width: calc(100% - 30px);
          }

          span.program-type-abbr {
            @include program-type-tag();
            border: 1px solid $color-blue;
            margin: -7px 10px;
          }
    
          &:before {
            top: 0.1rem;
          }
        }
      }
    }

    .filter-label {
      color: $color-dark-navy;
      font-family: $font-roboto-condensed;
      font-size: 1.8rem;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 0.05em;
      padding: 2rem 2rem 0;
      margin: 0;
      background-color: $color-white;

      &:before {
        @media (min-width: $break-large) {
          content: "";
          position: absolute;
          top: 50%;
          width: calc(100vw - 180px - var(--scrollbar-width));
          left: 100%;
          z-index: 0;
          height: 1px ;
          background-color: $color-dark-navy;
        }
      }

      @media (min-width: $break-medium) {
        position: absolute;
        top: 3.5rem;
        padding: 0 3rem;
      }

      @media (min-width: $break-large) {
        padding: 0 1rem 0 3rem;
        top: 3rem;
      }
    }

    .pathway-programs__tabs__list {
      @media (min-width: $break-medium) {
        padding: 7rem 0 2rem;
      }

      @media (min-width: $break-large) {
        display: flex;
        flex-wrap: wrap;
        padding: 8.5rem 7rem 0 0;
      }
    }

    .clear-filters {
      color: $color-gray-blue;
      background: none;
      border: none;
      padding: 0;
      position: absolute;
      top: 2rem;
      right: 1.5rem;
      width: 23px;
      height: 23px;
      overflow: hidden;
      text-indent: -10000px;

      svg {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        &.active {
          color: $color-dark-navy;
        }
      }

      @media (min-width: $break-medium) {
        width: 30px;
        height: 30px;
        left: 50%;
        margin-left: -50px;
        top: 3rem;
      }

      @media (min-width: $break-large) {
        position: absolute;
        display: block;
        opacity: 1;
        left: auto;
        right: 3rem;
        top: auto;
        bottom: 2rem;
        width: 35px;
        height: 35px;
      }
    }

    .pathway-programs__results {
      @include cta-button();
      position: sticky;
      bottom: 0;
      display: block;
      width: 100%;
      margin: 0;

      &:after {
        content: none;
      }
    }

    .pathway-programs__panel__wrapper {
      line-height: 1;

      &:first-child {
        .form__checkbox {
          margin-bottom: 0;
        }

        @media (min-width: $break-medium) {
          .form__item {
            display: block;
          }

        }
      }

      @media (min-width: $break-large) {
        margin: 0;
        position: relative;
      }
    }

    .form__item {
      display: none;
    }

    .form__label {
      position: relative;
      display: block;
      margin: 0 0 12px;
      text-transform: capitalize;

      &:after {
        position: absolute;
        left: 20px;
        bottom: -57px;
        width: calc(100% - 40px);
        height: 1px;
        background: $color-black;
        content: '';

        @media (min-width: $break-large) {
          left: 23px;
          bottom: -60px;
        }
      }
    }

    .toggle-subfilters {
      color: $color-blue;
      font-family: $font-roboto-condensed;
      font-size: 2.2rem;
      font-weight: 300;
      text-transform: uppercase;
      background-color: transparent;
      border: 0;
      padding: 1rem 2rem;
      position: relative;
      @include focus-dark-navy();
      transition: color .3s ease;

      @media (min-width: $break-medium) {
        display: none;
        font-size: 3rem;
        padding: 1.25rem 3rem;
      }

      @media (min-width: $break-large) {
        font-size: 3.6rem;
      }

      &[aria-expanded="true"] {
        color: $color-dark-navy;

        &:after {
          transform: rotate(-135deg);
          bottom: 0.75em;
          border-color: $color-dark-navy;
        }
      }
      
      &:hover {
        color: $color-dark-navy;

        &:after {
          border-color: $color-dark-navy;
        }
      }

      &[aria-selected="true"] {
        color: $color-dark-navy;

        &:after {
          transform: rotate(135deg);
          border-color: $color-dark-navy;

          @media (min-width: $break-large) {
            transform: rotate(-135deg);
            bottom: 1em;
          }
        }

        &:before {
          width: 100%;
        }
      }

      &:after {
        content: "";
        position: absolute;
        bottom: 0.95em;
        right: 0;
        width: 10px;
        height: 10px;
        border-right: 1px solid;
        border-bottom: 1px solid;
        transform: rotate(45deg);
        transition: all 300ms;

        @media (min-width: $break-medium) {
          transform: rotate(-45deg);
          bottom: 0.55em;

          border-right: 2px solid;
          border-bottom: 2px solid;

        }

        @media (min-width: $break-large) {
          bottom: 1.2em;
          transform: rotate(45deg);
        }
      }

      &.tab {
        display: none;

        @media (min-width: $break-medium) {
          display: block;
          padding: 0 25px 0.5rem 0;
          margin:  1.25rem 4rem 0.5rem 3rem;

          &:before {
            content: "";
            width: 0;
            position: absolute;
            left: 0;
            bottom: 0;
            border-bottom: 6px solid transparent;
            transition: all 300ms;
          }

          &[aria-selected="true"] {
            &:before {
              content: "";
              width: 100%;
              position: absolute;
              left: 0;
              bottom: 0;
              border-bottom: 6px solid $color-yellow;
            }
          
          }
        }
        @media (min-width: $break-large) {
          padding: 0px 25px 2.5rem 0;
          margin:  1.25rem 4rem 0 3rem;
        }
      }
    }
  }

  .form {
    padding: 0;
    overflow: hidden;
  }
  
  &:before {
    content: "";
    @include full-width();
    background-color: $color-dark-navy;
    position: absolute;
    height: 100%;
    top: 0;
    z-index: -1;
  }
}

.pathway__program-listing {
  @media (min-width: $break-large) {
    padding: 0 3rem;
  }

  h3 {
    @extend h4;
    color: $color-white;
    text-transform: none;
    margin-bottom: 2rem
  }
}

.similar-programs {
  h3 {
    @extend h4;
    color: $color-white;
  }

  .similar-programs-card {
    opacity: 0;
    transform: translateY(30px);
    transition: all 300ms;

    @for $i from 1 through 30 {
      &:nth-child(#{$i}) {
        transition-delay: 200ms * $i;
      }
    }
  }

  &.inview {
    .similar-programs-card {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &-section {
    margin: 6rem 0 5.2rem;

    @media (min-width: $break-medium) {
      margin: 7rem 0 7.2rem;
    }

    @media (min-width: $break-large) {
      margin: 9.6rem 0 7.8rem;
    }

    &__heading {
      margin: 0 0 1.7rem;

      @media (min-width: $break-medium) {
        margin: 0 0 1.1rem;
      }

      @media (min-width: $break-large) {
        margin: 0 0 2rem;
      }
    }

    &__title {
      margin: 0;
    }

    .similar-programs {
      margin: 0 0 3.4rem;

      @media (min-width: $break-medium) {
        margin: 0 0 4.3rem;
        display: flex;
        flex-wrap: wrap;
        gap: 1.5rem;
      }

      @media (min-width: $break-large) {
        margin: 0 0 6.8rem;
        gap: 3rem;
      }
    }

    // a cta-btn

    &__subheading {
      margin: 0 0 1.6rem;

      @media (min-width: $break-large) {
        margin: 0 0 3.3rem;
      }
    }

    &__subtitle {
      margin: 0;
    }

    .cta-holder {

      a {
        max-width: calc(100% - 6.5rem);
        padding: 1.1rem 1.3rem 0.9rem;
        margin: 0 2rem 1.1rem 0;
        font-size: 1.6rem;
        line-height: 2rem;

        @media (min-width: $break-medium) {
          padding: 1.5rem 2rem 1.3rem;
          margin: 0 1.3rem 1.3rem 0;
        }

        @media (min-width: $break-large) {
          padding: 2rem 2.3rem 1.6rem;
          margin: 0 1.9rem 1.9rem 0;
        }
      }
    }
  }

  &-card {
    position: relative;
    margin: 0 0 1.5rem;
    background: $color-black;
    color: $color-white;
    min-height: calc(100vw - 40px);
    border: 1px solid $color-white;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &:hover {
      .similar-programs-card__image img {
        transform: scale(1.1);
      }
    }

    &.locations-shown {
      .similar-programs-card__title,
      .program--list__type {
        opacity: 0;
        transition: opacity .3s ease;
      }
    }
    

    @media (min-width: $break-medium) {
      flex-shrink: 0;
      margin: 0;
      min-height: 34.5vw;
      width: calc(100% / 3 - 4rem/3);
    }


    @media (min-width: $break-large) {
      flex-shrink: 0;
      min-height: 27.5vw;
      width: calc(100% / 3 - 9rem/3);
    }

    @media (min-width: $content-max) {
      min-height: 540px;
    }

    .program--list__types {
      position: absolute;
      top: 1.5rem;
      left: 1.5rem;
      z-index: 1000;
    }

    .program--list__type {
      cursor: pointer;
      outline-offset: -3px;

      &:focus-visible {
        outline: 1px solid $color-dark-navy;
      }
    }

    .program--list__type__wrap {
      position: relative;
      height: 50px;
      display: inline-block;

      &:hover {
        .program--list__type__tooltip {
          opacity: 1;
          z-index: 10;
          visibility: visible;
        }
      }
    }


    .program--list__type__tooltip {
      color: $color-dark-navy;
      font-size: 1.4rem;
      padding:0.8rem;
      background-color: $color-gray-blue;
      position: absolute;
      white-space: nowrap;
      bottom: 0rem;
      left: 0;
      transform: translate(0, 100%);
      opacity: 0;
      visibility: hidden;
      transition: all 300ms;
      z-index: -1;

      &:before {
        content: "";
        position: absolute;
        top: -11px;
        left: 10px;
        border-style: solid;
        border-width: 0 7px 14px 7px;
        border-color: transparent transparent $color-gray-blue transparent;
      }

      &.show {
        opacity: 1;
        z-index: 10;
        visibility: visible;
      }

      &.left-align {
        left: 0;
        transform: translate(0, 100%);

        &:before {
          left: 10px;
          transform: translateX(0);
        }
      }

      @media (min-width: $break-medium) {
        left: 50%;
        transform: translate(-50%, 100%);

        &:before {
          left: 50%;
          transform: translateX(-50%);
        }
      }

    }

    &__overflow {
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &__location {
      position: absolute;
      top: 1.5rem;
      right: 1.2rem;
      width: 3rem;
      height: 3rem;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background: none;
      border-radius: 50%;
      background-color: $color-yellow;
      transition: background-color .3s ease;
      z-index: 4000;

      &.similar-programs-card__location {
        @include focus-dark-navy;
        outline-offset: -3px;
      }

      svg {
        display: block;
        opacity: 1;
        transition: opacity .3s ease;
      }

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 1.2rem;
        border-top: 1px solid;
        
        opacity: 0;
        transform: translate(-45%, -50%) rotate(45deg);
        transition: opacity .3s ease;

        @media (min-width: $break-large) {
          width: 1.7rem;
        }
      }

      &:after {
        transform: translate(-45%, -50%) rotate(-45deg)
      }


      .program--list__type:hover,
      .program--list__type:focus {
        &+ .program--list__type__tooltip {
          opacity: 0;
        }
      }

      &.program-card--hover {
      
        &:not(.disabled) {
         

          &:before,
          &:after {
            opacity: 1;
            transition: opacity .3s ease .6s;

          }

          svg {
            opacity: 0;
            transition: opacity .3s ease .6s;
          }

          
          & ~ .similar-programs-card__overflow .similar-programs-card__details {
            top: 0;
            transition: top .4s ease .3s;
            opacity: 1;
            visibility: visible;
            
            * {
              opacity: 1;
              transition: opacity .3s ease .6s;
            }
          }
        
        }
      }
    }

    &__image {
      overflow: hidden;
      height: 100%;
      position: absolute;
      width: 100%;
      background-color: $color-blue;

      img {
        display: block;
        width: 100%;
        height: 100%;
        opacity: 0.9;
        object-fit: cover;
        transform: scale(1);
        transition: transform .3s ease-in-out;
      }
    }

    &__body {
      padding: 5rem 1.5rem 0;
      width: 100%;
      bottom: 0;
      z-index: 100;
      position: relative;

      @media (min-width: $break-large) {
        padding: 5rem 2rem 2rem;
      }

      * {
        opacity: 1;
        transition: opacity .3s ease .6s;
      }
    }

    &__title {
      margin: 0 0 1.1rem;
      line-height: 2.6rem;
      font-weight: 400;
      color: $color-white;
      width: 100%;

      @media (min-width: $break-large) {
        line-height: 3.3rem;
        margin-bottom: 0;
      }


      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        overflow: hidden;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: linear-gradient(180deg, rgba(27, 62, 148, 0) 0%, rgba(27, 62, 148, 0.4) 35%, #090E37 100%);
        mix-blend-mode: multiply;
      }

      a {
        display: inline-block;
        padding: 0;
        background: none;
        color: inherit;
        font-weight: inherit;
        @include cta-arrow($color-yellow, $color-white, true);

        @media (min-width: $break-medium) {
          font-size: 2rem;
        } 

        @media (min-width: $break-large) {
          font-size: 2.4rem;
          margin-bottom: 0;
        } 

        &:before {
          content: "";
          position: absolute;
          bottom: -15px;
          left: -15px;
          width: calc(100% + 30px);
          aspect-ratio: 1/1;
          z-index: 2;

          @media (min-width: $break-medium) {
            aspect-ratio: 200/240;
          }

          @media (min-width: $break-large) {
            aspect-ratio: 330/345;
          }
        }
      }
    }

    &__details {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 3rem;
      background: $color-blue;
      z-index: 300;
      transition: top .4s ease .3s;
      visibility: hidden;

      * {
        opacity: 0;
        transition: opacity .3s ease;
      }
    }

    &__subtitle {
      @extend h6;
      margin: 0 0 0.8rem;
      font-size: 1.6rem;
      text-transform: uppercase;

      @media (min-width: $break-medium) {
        font-size: 1.7rem;
      }

      @media (min-width: $break-large) {
        font-size: 2rem;
      }
    }

    &__campuses {
      padding: 0;
      margin:1.5rem 0 0;
      font-size: 1.4rem;
      line-height: 1.7rem;

      @media (min-width: $break-medium) {
        font-size: 1.6rem;
        line-height: 1.9rem;
      }

      @media (min-width: $break-large) {
        font-size: 1.8rem;
        line-height: 2.2rem;
      }

      li {
        margin: 0 0 1rem;
        padding: 0;
        list-style-type: none;

        &::marker {
          content: none;
          display: none;
        }
      }
    }
  }
}