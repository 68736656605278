.pagination {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem;

  @media (min-width: $break-medium) {
    margin-top: 4rem;
  }

  @media (min-width: $break-large) {
    padding: 0 1.5rem;
    margin-top: 5.1rem;
  }

  a {
    text-decoration: none;
    background-image: none;
  }

  &__button {
    position: relative;
    flex-shrink: 0;
    width: 1.1rem;
    height: 2.2rem;
     
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      width: 1.4rem;
      height: 1.4rem;
      border-top: 0.3rem solid $color-yellow;
      border-right: 0.3rem solid $color-yellow;
      transform: rotate(45deg) translateY(-50%);
      transition: border-color .3s ease;
    }

    &:hover {
      &::before {
        border-color: $color-white;
      }
    }

    &.disabled {
      pointer-events: none;

      &::before {
        opacity: 0.5;
        border-color: $color-light-gray;
      }
    }
  }

  .separator {
    font-size: 2.3rem;
    letter-spacing: 0.24rem;
    margin: 0 0.6rem;

    @media (min-width: $break-medium) {
      font-size: 2.6rem;
      letter-spacing: 0.24rem;
      margin: 0 0.7rem;
    }
  }

  &__prev {
    margin: 0.3rem 0.1rem 0 0.7rem;
    transform: scaleX(-1);

    @media (min-width: $break-medium) {
      margin: 0.5rem 1.1rem 0 0.7rem;
    }
  }

  &__next {
    margin: 0.3rem 0 0 -0.7rem;

    @media (min-width: $break-medium) {
      margin: 0.5rem 0 0 -0.3rem;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
  }

  &__item {
    padding: 0;
    margin: 0;
    flex-shrink: 0;
    list-style: none;

    &::marker {
      content: none;
    }

    &:last-child {
      a {
        &.active {
          margin-right: 1rem;
        }
      }
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3.2rem;
      height: 3.2rem;
      margin: 0 0.4rem;
      border-radius: 50%;
      font-weight: 500;

      @media (min-width: $break-medium) {
        width: 4rem;
        height: 4rem;
        margin: 0;
        font-size: 2rem;
        line-height: 2.2rem;
      }

      &.active {
        background-color: $color-white;
        color: $color-dark-navy;
        font-weight: 900;
      }
    }
  }
}