/**
 * Mixins
 * Contains global SCSS mixins
 */

/// Clearfix
@mixin clearfix() {
  &:after {
    display: table;
    content: '';
    clear: both;
  }
}

/// Image replacement
@mixin ir {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 0;                     // Remove the default border from elements like <button>
  font: 0/0 a;                   // Crush the text down to take up no space
  text-shadow: none;             // Remove any text shadows
  color: transparent;            // Hide any residual text in Safari 4 and any mobile devices that may need it
  background-color: transparent; // Hide the default background color on elements like <button>
}

/// Invisible elements
@mixin element-invisible {
  border: 0;
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  clip: rect(1px, 1px, 1px, 1px);
}

/// Show an invisible element
@mixin element-invisible-off {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: auto;
}

/// Show invisible element when focused
@mixin element-focusable {
  @include element-invisible;

  &:active,
  &:focus {
    @include element-invisible-off;
  }
}

/// Full width mixin to break content out into gutters
@mixin full-width() {
  width: calc(100vw - var(--scrollbar-width));
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}


/// Content Gutter
@mixin gutter {
  padding-left: 20px;
  padding-right: 20px;

  @media screen and (min-width: $break-medium) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media screen and (min-width: $break-large) {
    padding-left: 50px;
    padding-right: 50px;
  }
}

/// Maximum width Object
@mixin content-full {
  max-width: $content-max;
  margin-left: auto;
  margin-right: auto;
}

/// Gradient overlay
@mixin gradient-overlay {
  &::after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    height: 100%;
    width: 100%;
    background: linear-gradient(to bottom, rgba($color-white, 0) 0%, rgba($color-white, 0) 45%, $color-black 100%);
    content: '';
  }
}

/// Placeholder mixin
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content
  }

  &:-moz-placeholder {
    @content
  }

  &::-moz-placeholder {
    @content
  }

  &:-ms-input-placeholder {
    @content
  }
}

@mixin cta-button {
  font-family: $font-roboto-condensed;
  color: $color-dark-navy;
  font-size: 2rem;
  text-transform: uppercase;
  padding: 0.5rem 3rem 0.5rem 1.2rem;
  margin: 0.5rem 0;
  font-weight: 700;
  display: inline-block;
  background: $color-yellow;
  position: relative;

  @media screen and (min-width: $break-medium) {
    font-size: 2.4rem;
  }

  &:hover {
    color: $color-dark-navy;
    text-decoration: none;
    background: $color-gray-blue;
  }

  &:after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 9px;
    height: 9px;
    position: absolute;
    margin: 8px 0 0 3px;
    transform: rotate(45deg);
    border-right: 3px solid $color-black;
    border-top: 3px solid $color-black;

    @media screen and (min-width: $break-medium) {
      margin: 8px 0 0 5px;
      width: 11px;
      height: 11px;
    }
  }
}

// Parameters: 
// $color is a hex value, it controls the color of the text and arrow
// Parameter $hover-color is for the hover state text + arrow
// font-styles adds in default text styling for "uppercase 24px Bold Roboto Condensed", can be set to false to just add an arrow
@mixin cta-arrow($color: $color-blue, $hover-color: $color-dark-navy, $font-styles: true) {
  color: $color;
  line-height: 1.2;
  position: relative;
  display: inline-block;
  padding-right: 2rem;
  margin: 0.5rem 0;
  background-image: none;

  @if ($font-styles) {
    font-family: $font-roboto-condensed;
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: 700;

    @media screen and (min-width: $break-medium) {
      font-size: 2.4rem;
    }
  
  }
  
  &:after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    width: 7px;
    height: 7px;
    bottom: 0.4em;
    margin: 0 0 0 3px;
    transform: rotate(45deg);
    border-right: 3px solid $color;
    border-top: 3px solid $color;
    transition: all 300ms;
   
    @media (min-width: $break-medium) {
      width: 9px;
      height: 9px;
    }
  }

  &:hover {
    color: $hover-color;
    text-decoration: none;
    background-image: none;

    &:after {
      border-color: $hover-color;
      transform: translateX(10px) rotate(45deg);
    }
  }
}

// container needs to have position: relative to work
@mixin fullwidth-with-sidebar {
  @include full-width();
  top: 0;
  position: absolute;
  display: block;
  height: 100%;
  z-index: -1;

  @media (min-width: $break-large) {
    .section-nav + .container & {
      margin-left: -12rem;
    }
  }
}


@mixin slide-fade-in {
  opacity: 0;
  transition: transform .3s ease, opacity .3s ease;
  transform: translateY(3rem);

  &.inview {
    opacity: 1;
    transform: translateY(0rem);
  }
}

@mixin program-type-tag() {
  color: $color-blue;
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
  width: 32px;
  height: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: $color-white;
  margin-right: 3.5px; 
}

.program--list__type {
  @include program-type-tag();
}

@mixin focus-white {
  outline-color: $color-white
}

a,
button,
input,
select,
textarea {
  &:focus {
    outline: 1px solid;
    outline-offset: 4px;
    transition: none;
    @include focus-white();
  }
}

@mixin focus-dark-navy {
  outline-color: $color-dark-navy
}

@mixin focus-inset {
  outline-offset: -2px
}