/**
 * @file
 * Generic Block: 3 Column
 *
 * Styles for Generic Block: 3 Column object.
 */

.gen3col {
  margin: 0;
  padding: 6rem 0 5.8rem;
  color: $color-white;
  position: relative;

  @media (min-width: $break-medium) {
    padding: 5.1rem 0 5.9rem;
  }

  @media (min-width: $break-large) {
    padding: 5.1rem 0 4.8rem;
  }

  &:before {
    content: "";
    @include fullwidth-with-sidebar();
    background: $color-dark-navy;
  }

  .scroll-animate-box {
    &.scroll-animate {
      @include slide-fade-in;
    }
  }

  &__intro {
    margin: 0 0 3.3rem;

    @media (min-width: $break-medium) {
      margin: 0 0 2.3rem;
    }

    @media (min-width: $break-large) {
      margin: 0 0 4rem;
    }
  }

  .column__title {
    h3 {
      @media (min-width: $break-medium) {
        font-size: 3rem;
        line-height: 1;
      }

      @media (min-width: $break-large) {
        font-size: 3.6rem;
        line-height: 1.18;
      }
    }

    a, .cta--link {
      @media (min-width: $break-medium) {
        font-size: 3.2rem;
        line-height: 1.1;
      }

      @media (min-width: $break-large) {
        font-size: 3.6rem;
        line-height: 1.18;
      }

      &:after {
        @media (min-width: $break-medium) {
          bottom: 1.2rem;
        }

        @media (min-width: $break-large) {
          width: 1.3rem;
          height: 1.3rem;
          bottom: 1.5rem;
        }
      }
    }
  }
}