.icons-list {
  position: relative;
  padding: 3rem 0 3.7rem;
  color: $color-white;

  @media (min-width: $break-medium) {
    padding: 4rem 0 5.5rem;
  }

  @media (min-width: $break-large) {
    padding: 5.2rem 0 10.4rem;
  }

  &:before {
    content: '';
    @include fullwidth-with-sidebar;
    background-color: $color-dark-navy;
  }

  .lined-header {
    padding: 0;

    &__subtile {
      margin: 0 0 0.7rem;

      @media (min-width: $break-large) {
        margin: 0 0 1.6rem;
      }

      .text-box {
        @media (min-width: $break-large) {
          padding-right: 2.2rem;
        }
      }
    }

    &__title {
      margin: 0 0 1.7rem;

      @media (min-width: $break-medium) {
        margin: 0 0 2.1rem;
      }

      @media (min-width: $break-large) {
        margin: 0 0 4.8rem;
      }
    }
  }

  .content-holder {
    .text-box {
      margin: 0 0 1.9rem;

      @media (min-width: $break-medium) {
        padding: 0.4rem 0 0;
        margin: 0;
        font-size: 1.8rem;
      }

      @media (min-width: $break-large) {
        padding-top: 0;
      }
    }

    @media (min-width: $break-medium) {
      display: flex;
      gap: 3rem;
    }

    @media (min-width: $break-large) {
      gap: 3.3rem;
    }
  }

  &__list {
    @media (min-width: $break-medium) {
      flex-shrink: 0;
      width: 54.1%;
    }

    @media (min-width: $break-large) {
      width: 62.7%;
      padding: 0.3rem 0 0;
    }
  }

  &__item {
    display: flex;
    margin-right: -1rem;
    margin-bottom: 1.1rem;

    @media (min-width: $break-medium) {
      margin-right: 0;
      margin-bottom: 1.5rem;
    }

    :last-child {
      margin-bottom: 0;
    }

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      width: 3rem;
      height: 3rem;
      padding: 0.8rem;
      margin: 0 1rem 0 0;
      background-color: $color-yellow;
      border-radius: 50%;

      @media (min-width: $break-medium) {
        width: 4rem;
        height: 4rem;
        margin: 0 1.2rem 0 0;
      }
    }

    .cta--link {
      margin: 0.3rem 0;
      letter-spacing: 0.04rem;

      @media (min-width: $break-medium) {
        // letter-spacing: 0;
        margin: 0.6rem 0;
      }

      &:after {
        width: 0.7rem;
        height: 0.7rem;

        @media (min-width: $break-medium) {
          width: 0.9rem;
          height: 0.9rem;
        }
      }
    }
  }
}