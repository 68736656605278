/**
 * @file
 * Hero
 *
 * Contains styles for hero object.
 */

 @keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
 }

.hero {
  display: block;
  position: relative;
  width: calc(100vw - var(--scrollbar-width));
  min-height: 160px;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  background-color: $color-blue;
  overflow: hidden;

  @media (min-width: $break-medium) {
    min-height: 174px;
  }

  @media (min-width: $break-large) {
    min-height: 294px;
    @include full-width();
    > * {
      @include content-full();
      width: 100%;
    }
   
  }

  &.has-image {
    min-height: 50vw;

    @media (min-width: $break-medium) {
      min-height: 226px;
    }
  
    @media (min-width: $break-large) {
      min-height: 380px;
    }

    h1 {
      @media (min-width: $break-large) {
        padding: 10rem 5.5rem 5.5rem;
      }
    }
  }

  img {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    object-fit: cover;
    opacity: 0;
    animation: fadeIn 300ms 300ms forwards;

  }

  h1 {
    position: relative;
    z-index: 1;
    padding: 5rem 2rem 3rem;
    opacity: 0;
    animation: fadeIn 300ms forwards;

    @media (min-width: $break-medium) {
      padding: 5rem 3rem 4.5rem;
    }


    @media (min-width: $break-large) {
      padding: 5rem 5.5rem 3.5rem;
    }
  }

  picture {
    background-color: $color-dark-navy;
    height: 100%;
    position: absolute;
    width: 100%;
    max-width: calc(100vw - var(--scrollbar-width));
    &:after {
      content: "";
      position: absolute;
      height: 271px;
      width: 100%;
      min-height: 100%;
      bottom: 0;
      background: linear-gradient(180deg, rgba(9, 69, 218, 0.1) 0%, rgba(9, 69, 218, 0.7) 100%);
      mix-blend-mode: multiply;

      @media (min-width: $break-large) {
        height: 442px;

      }
    }
  }
}