/**
 * @file
 * Section navigation
 *
 * Contains styles for the Section navigation component
 */

// .content-wrapper {
//   display: flex;
//   flex-direction: column;

//   @media (min-width: $break-large) {
//     display: block;
//   }

//   & > {
//     // set order of components according to the design for mobile devices
//     .section-nav {
//       @media (max-width: $break-large - 1px) {
//         width: 100%;
//         order: 1;
//       }
//     }

//     .hero {
//       @media (max-width: $break-large - 1px) {
//         width: 100%;
//         order: 2;
//       }
//     }

//     .container {
//       @media (max-width: $break-large - 1px) {
//         width: 100%;
//         order: 3;
//       }
//     }
//   }
// }

.section-nav {
  z-index: 1;

  @media (min-width: $break-large) {
    margin-top: 5rem;
    margin-bottom: 5rem;
    border-left: 0.1rem solid $color-yellow;
    max-height: calc(100vh - 5rem);
    top: 5rem;
    overflow-y: auto;
  }

  &--mobile {
    @media (min-width: $break-large) {
      display: none;
    }
  }

  &--desktop {
    display: none;
    
    @media (min-width: $break-large) {
      display: block;
    }
  }

  &__toggle {
    position: relative;
    width: 100%;
    padding: 0.9rem 0 0.8rem 3.9rem;
    background: $color-blue;
    border: none;
    border-bottom: 0.3rem solid $color-yellow;
    text-align: left;
    font-size: 1.6rem;
    font-weight: 700;
    color: $color-white;
    font-family: $font-roboto;

    &.section-nav__toggle {
      outline-offset: -6px;
    }

    @media (min-width: $break-medium) {
      padding: 1.6rem 0 1.5rem 6rem;
      font-size: 1.8rem;
    }

    @media (min-width: $break-large) {
      display: none;
    }

    .icon {
      position: absolute;
      top: 50%;
      left: 1.1rem;
      width: 1.5rem;
      border-bottom: 1.5px solid;
      background-color: $color-white;
      transform: translateY(-50%);
      transition: background-color .3s ease;

      @media (min-width: $break-medium) {
        left: 2.6rem;
        width: 2rem;
        border-bottom: 2px solid;
      }

      &:before,
      &:after {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        transition: top .3s ease, transform .3s ease;
        width: 1.5rem;
        border-top: 1.5px solid;
        background-color: $color-white;
        box-sizing: border-box;

        @media (min-width: $break-medium) {
          width: 2rem;
          border-top: 2px solid;
        }
      }

      &:before {
        top: -0.6rem;

        @media (min-width: $break-medium) {
          top: -0.8rem;
        }
      }

      &:after {
        top: 0.6rem;

        @media (min-width: $break-medium) {
          top: 0.8rem;
        }
      }
    }

    &.opened {
      .icon {
        border: 0;

        &:before,
        &:after {
          top: 0;
          transform: translateX(-50%) rotate(-45deg);
        }

        &:after {
          transform: translateX(-50%) rotate(45deg);
        }
      }
    }
  }

  &__list {
    display: none;
    margin: 0;
    padding: 1rem 0 2.7rem;
    background-color: $color-white;

    @media (min-width: $break-medium) {
      padding: 1rem 0 1rem;
    }

    @media (min-width: $break-large) {
      display: block !important;
      padding: 0.7rem 0 1rem;
      background: $color-blue;
    }

    ul {
      margin: 0;
      padding: 0;
    }

    li {
      margin: 0;
      padding: 0;
      list-style: none;

      &::marker {
        color: transparent;
        content: none;
      }
    }

    a {
      display: inline-block;
      width: 100%;
      font-weight: 400;
      padding: 0 2rem;
      color: $color-dark-navy;
      background-image: linear-gradient(to right, transparent 50%, transparent 50%);
      @include focus-dark-navy;

      &:hover {
        text-decoration: underline 1px;
        text-underline-offset: 2px;
        text-decoration-skip-ink: none
      }

      @media (min-width: $break-medium) {
        padding: 0 3rem;
      }

      @media (min-width: $break-large) {
        color: $color-white;
        @include focus-white;
        padding: 0 2rem;
      }

      &:hover {
        color: $color-blue;
        background-image: none;

        @media (min-width: $break-large) {
          color: $color-yellow;
        }
      }
    }

    .parent-page {
      width: 100%;
      display: inline-block;
      padding: 0 3.2rem;
      margin: 0 0 1rem;
      position: relative;
      font-size: 1.4rem;

      @media (min-width: $break-medium) {
        padding: 0 4.2rem;
      }

      @media (min-width: $break-large) {
        color: $color-white;
        padding: 0 3.2rem;
      }
      
      &:hover {
        background-image: none;
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 2.1rem;
        width: 0.4rem;
        height: 0.4rem;
        border-top: 0.15rem solid currentColor;
        border-left: 0.15rem solid currentColor;
        transform: translateY(-50%) rotate(-45deg);

        @media (min-width: $break-medium) {
          left: 3.1rem;
        }

        @media (min-width: $break-large) {
          left: 2rem;
        }
      }
    }

    .current-page {
      position: relative;
      display: inline-block;
      padding: 0.8rem 1.1rem 0.7rem 2rem;
      margin: 0 0 1.2rem;
      font-family: $font-bitter;
      font-size: 2rem;
      line-height: 1.2;
      background-color: $color-yellow;

      @media (min-width: $break-medium) {
        padding: 0.8rem 1.5rem 0.6rem 3rem;
      }
      
      @media (min-width: $break-large) {
        display: block;
        padding: 0.8rem 1.5rem 0.6rem 2rem;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 100%;
        height: 100%;
        width: 2rem;
        background-color: $color-yellow;

        @media (min-width: $break-medium) {
          width: 3rem;
        }

        @media (min-width: $break-large) {
          width: 2rem;
        }
      }
    }
  }

  &__sub-list {
    margin: 0;
    padding: 0;

    @media (min-width: $break-medium) {
      font-size: 1.8rem;
    }

    li {
      margin: 0 0 1.5rem;
    }
  }
}