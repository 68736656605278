.story-highlights {
  padding: 4rem 0 0;
  position: relative;
  color: $color-white;

  &::before {
    content: '';
    @include fullwidth-with-sidebar();
    background-color: $color-dark-navy;
  }

  &__header {
    @media (min-width: $break-large) {
      padding: 0 3rem;
    }
  }

  &__subtile {
    position: relative;
    margin: 0 0 1.3rem;
    font-family: $font-roboto-condensed;
    font-size: 1.8rem;
    line-height: 1.2;
    font-weight: 700;
    letter-spacing: 0.09rem;
    
    &:before {
      content: '';
      display: none;
      position: absolute;
      top: 1.3rem;
      width: 100%;
      height: 1px;
      background-color: $color-white;
      z-index: 1;

      @media (min-width: $break-medium) {
        display: block;
      }

      @media (min-width: $break-large) {
        top: 1.1rem;
      }
    }

    @media (min-width: $break-medium) {
      margin: 0 0 2rem;
      font-size: 2rem;
      line-height: 1.36;
      letter-spacing: 0.1rem;
    }

    @media (min-width: $break-large) {
      margin: 0 0 2.5rem;
    }

    .text-box {
      position: relative;
      display: inline-block;
      padding-right: 1.3rem;
      background-color: $color-dark-navy;
      z-index: 2;
    }
  }

  &__title {
    margin: 0 0 1.5rem;

    @media (min-width: $break-medium) {
      margin: 0 0 2.8rem;
    }

    @media (min-width: $break-large) {
      margin: 0 0 3.6rem;
    }
  }

  &__wrapper {
    margin: 0 -2rem;

    @media (min-width: $break-medium) {
      margin: 0 -3rem;
    }

    @media (min-width: $break-large) {
      margin: 0 -5rem;
    }

    &.inview {
      .story-display__quote-outer:before {
        transform: scaleY(1);
      }
  
      .story-display__caption:before {
        opacity: 1;
      }
    }
  }

  &__item {
    display: block !important;
  }

  // slides transition styles
  .slick-list {
    transition: height .3s ease;
    background-color: $color-blue;
    position: relative;
    height: auto !important;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 5.4rem;
      background-color: $color-dark-navy;
    }
  }

  .slick-slide {
    opacity: 0 !important;
    visibility: hidden;
    transition: opacity .0s linear .6s, visibility .0s linear .6s !important;
  
    .story-display__content {
      opacity: 0;
      transition: opacity .3s ease-in-out;
    }
  
    .story-display__img  {
      opacity: 0;
      transition: opacity .3s ease-in-out .6s;
    }

    .type-text__wrap {
      width: 100%;
      margin-bottom: -23px;
      margin-left: 0;
      height: 0;
      @media (min-width: $break-medium) {
        margin-bottom: -46px;
        margin-left: 0;
      }

      @media (min-width: $break-large) {
        margin-bottom: -69px;
      }
    }

    .type-text {
      color: transparent;
      min-width: 1.3rem;
      min-height: 1.4em;
      max-width: 1.3rem;
      transition: max-width .3s ease .3s, color .3s;
      font-size: 3.8rem;
      z-index: 1;
      font-weight: 900;

      @media (min-width: $break-medium) {
        font-size: 6.5rem;
      }

      @media (min-width: $break-large) {
        padding: 2rem;
        font-size: 10rem;
        margin-bottom: -100px;
      }
    }
  
    &.slick-current {
      opacity: 1 !important;
      visibility: visible;
      transition: opacity .0s linear .6s, visibility .0s linear .6s!important;
  
      .story-display__content {
        opacity: 1;
        transition: opacity .3s ease-in-out .9s;
      }

      .type-text__wrap {
        height: auto;
      }
  
      .story-display__img  {
        opacity: 1;
        transition: opacity .3s ease-in-out .6s;

        img {
          opacity: 1;
          transition: opacity .3s ease-in-out;
        }
      }
      
      .type-text {
        color: $color-dark-navy;
        max-width: 100%;
      }
    }
  }

  .story-display {
    &__wrap {
      margin: 5.5rem 0 0;
  
    }

    &__title {
      .type-text {
        margin-top: -45px;

        @media (min-width: $break-medium) {
          margin-top: -38px;
        }

        @media (min-width: $break-large) {
          margin-top: -30px;
        }
      }
    }

    &__img {
      margin: 0;
      flex: 0 0 100%;

      @media (min-width: $break-medium) {
        flex: 0 0 50%;
        overflow: visible;
      }

      @media (min-width: $break-large) {
        min-height: 54.7rem;
        flex: 0 0 51.2%;
      }

      img {
        transition: all .3s .3s;
        opacity: 0;
      }

      img {
        @media (min-width: $break-large) {
          aspect-ratio: 615/547;
          height: auto;
          min-height: 100%;
          top: 0;
        }
      }

      .foreground-image {
        @media (min-width: $break-medium) {
          top: 26px;
        }

        @media (min-width: $break-large) {
          top: 0;
        }
      }
    }

    &__subtitle {
      padding: 0.4rem 1rem 0.4rem;

      @media (min-width: $break-medium) {
        padding: 0.6rem 2rem;
        font-size: 2rem;
        line-height: 1.15;
      }

      @media (min-width: $break-large) {
        padding: 0.6rem 1.3rem;
        font-size: 1.4rem;
      }
    }

    &__quote {
      padding: 0 1rem 0 4rem;
      font-size: 2.4rem;
      line-height: 3.2rem;
      margin: 0 0 1.6rem;

      @media (min-width: $break-medium) {
        padding: 0 2.2rem 0 7rem;
        margin: 0 0 2.2rem;
        font-size: 2.8rem;
        line-height: 3.78rem;
      }

      @media (min-width: $break-large) {
        padding: 0 4.2rem 0 9.2rem;
        font-size: 3.4rem;
        line-height: 4.6rem;
      }

      &:before {
        margin-top: -0.5rem;

        @media (min-width: $break-medium) {
          margin-top: -2rem;
          font-size: 8rem;
          font-family: $font-bitter;
        }

        @media (min-width: $break-large) {
          margin-top: -2.1rem;
          text-indent: -5.2rem;
        }
      }

      &-outer {
        &:before {
          transform: scaleY(0);
          top: 2.8rem;
          bottom: -0.5rem;
          left: 2rem;

          @media (min-width: $break-medium) {
            top: 4rem;
            bottom: -0.2rem;
            left: 3.9rem;
          }

          @media (min-width: $break-large) {
            top: 3.4rem;
            bottom: -0.5rem;
            left: 5.6rem;
          }
        }
      }
    }

    &__content {
      padding: 2.5rem 0 0;

      @media (min-width: $break-medium) {
        padding: 7.5rem 0 10rem;
      }

      @media (min-width: $break-large) {
        flex: 0 0 51.2%;
        flex: 0 0 48.8%;
        padding: 11.1rem 0 8.7rem;
      }
    }

    &__caption {
      margin: 0 0 0.9rem;
      padding-left: 4rem;
      padding-right: 1rem;

      @media (min-width: $break-medium) {
        padding-left: 7rem;
        padding-right: 2rem;
        margin: 0 0 1.3rem;
        font-size: 1.8rem;
      }

      @media (min-width: $break-large) {
        margin: 0 0 2.6rem;
        padding-left: 9.2rem;
        padding-right: 4.2rem;
      }

      &:before {
        opacity: 0;
        transition: none;
        left: 1rem;
        transition:all 300ms 500ms;

        @media (min-width: $break-medium) {
          left: 2.7rem;
          top: -0.2rem;
        }

        @media (min-width: $break-large) {
          width: 2.6rem;
          height: 2.6rem;
          left: 4.4rem;
        }
      }
    }

    &__cta {
      padding-left: 4rem;
      padding-right: 1rem;
      letter-spacing: 0.04rem;

      @media (min-width: $break-medium) {
        padding-left: 7rem;
        padding-right: 2rem;
      }

      @media (min-width: $break-large) {
        padding-left: 9.2rem;
        padding-right: 4.2rem;
        margin: 0 0 2.6rem;
      }
    }
  }

  .carousel__controls {
    background-color: $color-blue;
    padding: 0.2rem 4rem 3.7rem;

    @media (min-width: $break-medium) {
      font-size: 1.8rem;
      bottom: 0;
      top: auto;
      left: 50%;
    }

    @media (min-width: $break-large) {
      left: 51.8%;
      padding: 0 5.3rem 2.9rem;
      gap: 1.7rem;
    }
    
    .slick-arrow {
      width: 1.6rem;
      height: 1.6rem;
      transition: border-color .3s ease;

      &:hover {
        border-color: $color-white;
      }
    }
  }
}