.learn-more-opportunities {
  position: relative;
  padding: 6rem 0 3rem;

  @media (min-width: $break-medium) {
    padding: 7rem 0 3rem;
  }

  @media (min-width: $break-large) {
    padding: 10rem 0 5rem;
  }

  &::before {
    content: '';
    @include fullwidth-with-sidebar;
    background-color: $color-dark-navy;
  }

  .section-content-holder {
    @media (min-width: $break-large) {
      width: calc(100% + 4rem);
      margin-right: -4rem;
    }
  }

  &__header {
    color: $color-dark-navy;
    background-color: $color-white;

    @media (min-width: $break-large) {
      display: flex;
      padding: 0;
    }

    .text-holder {
      padding: 2.6rem 2rem 3.4rem;

      @media (min-width: $break-medium) {
        padding: 3.7rem 3rem 4rem;
      }

      @media (min-width: $break-large) {
        padding: 4.7rem 2rem 4rem 4rem;
        width: 50%;
        flex-shrink: 0;
      }

      a:focus {
        @include focus-dark-navy();
      }
    }

    .lined-header {
      &__title {
        margin: 0 0 2.5rem;
        font-size: 2.6rem;

        @media (min-width: $break-medium) {
          margin: 0 0 2.8rem;
          font-size: 4rem;
          line-height: 4.8rem;
        }

        @media (min-width: $break-large) {
          font-size: 4.8rem;
          line-height: 6rem;
        }
      }

      &__subtile {
        margin: 0 0 0.5rem;

        @media (min-width: $break-medium) {
          margin: 0 0 0.8rem;
        }

        @media (min-width: $break-large) {
          margin: 0 0 1rem;
        }

        &::before {
          background-color: $color-dark-navy;

          @media (min-width: $break-medium) {
            top: 1rem;
          }
        }

        .text-box {
          background-color: $color-white;
        }
      }
    }

    .cta--button {
      display: inline;
      box-decoration-break: clone;
      line-height: 1.6;
    }

    .image-box {
      @media (min-width: $break-large) {
        width: 50%;
        flex-shrink: 0;
      }

      img {
        width: 100%;
        aspect-ratio: 280/158;
        object-fit: cover;

        @media (min-width: $break-medium) {
          aspect-ratio: 640/302;
        }

        @media (min-width: $break-large) {
          aspect-ratio: auto;
          height: 100%;
        }
      }
    }
  }

  .content-holder {
    padding: 2.4rem 2rem 1.6rem;
    color: $color-white;
    background-color: $color-blue;

    @media (min-width: $break-medium) {
      padding: 3.3rem 3rem 2rem;
    }

    @media (min-width: $break-large) {
      padding: 4.3rem 4rem 5.5rem;
    }
  }

  .opportunities {
    &__list {
      @media (min-width: $break-medium) {
        display: flex;
        gap: 3rem;
      }

      @media (min-width: $break-large) {
        gap: 8rem;
      }
    }

    &__item {
      margin: 0 0 3rem;

      &:last-child {
        margin: 0;
      }

      @media (min-width: $break-medium) {
        width: calc(50% - 1.5rem);
        flex-shrink: 0;
        margin: 0;
      }

      @media (min-width: $break-large) {
        width: calc(50% - 4rem);
        display: flex;
        flex-direction: column;
      }
    }

    &__title {
      margin: 0 0 0.8rem;
      font-size: 2.2rem;

      @media (min-width: $break-medium) {
        margin: 0 0 1.2rem;
        font-size: 3rem;
        line-height: 1;
      }

      @media (min-width: $break-large) {
        margin: 0 0 0.7rem;
        font-size: 3.6rem;
        line-height: 4.2rem;
      }
    }

    &__body {
      margin: 0 0 1.5rem;
      
      @media (min-width: $break-medium) {
        margin: 0 0 1.8rem;
        font-size: 1.8rem;
      }

      @media (min-width: $break-large) {
        margin: 0 0 2.8rem;
      }
    }

    &__cta {
      margin-top: auto;
      letter-spacing: 0.4px;
    }
  }
}