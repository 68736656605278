.table {
  &-block__wrapper {
    margin: 3.5rem 0 6rem;
    clear: both;

    .tablet-controls {
      height: 0;
    }

    &:before {
      content: none;
    }

    .js-scroll--left {

      .left {
         display: none;
      }

      .right {
        display: block;
      }
    }

    .js-scroll--right {

      .right {
          display: none;
        }

      .left {
        display: block;
      }
    }

    .js-scroll--right,
    .js-scroll--left {
      table {
        display: block;
      }

      .table-scroller {
        overflow: auto;
        width: 100%;
        display: block;
      }

      .right,
      .left {
        @media (min-width: $break-medium) {
          display: none;
        }
      }
    }

  }

  &-block__holder {
    tbody {
      &:before {
        margin-top: 5px;
      }
  

      &:after {
        content: none;
      }
    }
  
    thead { 
      z-index: 10;
      background: linear-gradient(180deg, $color-yellow 50%, transparent 51%);
      background-size: 100% 2px;
      background-repeat: repeat-x;
      background-position: 5px 5px;

      & + tbody {
        border-top: 0;
        padding-top: 0;

        &:before {
          content: none;
        }
      }
    }

    &.sticky {
      thead {
        position: sticky;
        top: 0;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
       
        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
          display: none;
        }

        &:before {
          margin-top: 5px;
        }
      }

    }
  }
}

table {
  clear: both;
  max-width: 100%;
  min-width: 100%;
  border-collapse: collapse;
  color: $color-dark-navy;
  background-color: $color-white;
  border-bottom: 2px solid $color-yellow;

  td,
  th {
    text-align: left;
    vertical-align: top;
  }

  th {
    font-weight: 700;
    line-height: 1.2;
    text-align: left;
    padding: 1.2rem 2rem;
    background-color: $color-yellow;
  }

  tbody th {
    padding: 2rem;
    border-bottom: 1px solid;

    @media (min-width: $break-medium) {
      padding: 3rem 2rem;
    }
  }

  tr:last-child td {
    border-bottom: 0
  }

  a:focus {
    @include focus-dark-navy();
  }

  td {
    padding: 2rem;
    border-bottom: 1px solid;

    @media (min-width: $break-medium) {
      padding: 3rem 2rem;
    }
  }

  a {
    color: $color-blue;
    background-image: linear-gradient(to right,$color-blue 50%,$color-blue 50%);

    &:hover {
      color: $color-dark-navy;
      background-image: linear-gradient(to right,$color-dark-navy 50%,$color-dark-navy 50%);
    }
  }
}


.table-block {
  &__holder {
    &.js-scroll--right {
      .table-block__next {
        visibility: visible;
        opacity: 1;
      }
    }
    &.js-scroll--left {
      .table-block__prev {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  &__prev,
  &__next {
    visibility: hidden;
    opacity: 0;
    font-size: 0;
    line-height: 0;
    position: sticky;
    top: 50%;
    transform: translateY(50%);
    left: -10px;
    width: 35px;
    height: 35px;
    z-index: 1000;
    background-color: $color-yellow;
    padding: 0;
    border: 0;
    transition: background-color 300ms;

    @media (min-width: $break-medium) {
      left: -20px;
      width: 45px;
      height: 45px;
    }

    @media (min-width: $break-large) {
      width: 50px;
      height: 50px;
      left: -58px;
    }

    &.table-block__next,
    &.table-block__prev {
      @media (max-width: $break-large - 1px) {
        @include focus-dark-navy;
        outline-offset: -3px;
      }
    }

    &:hover {
      background: $color-gray-blue;
    }

    &:after {
      content: '';
      display: inline-block;
      vertical-align: top;
      width: 8px;
      height: 8px;
      left: 50%;
      border-top: 2px solid $color-black;
      border-right: 2px solid $color-black;
      transform: rotate(45deg);
      line-height: 1;
      margin-left: -3px;
      transition: all 300ms;

      @media (min-width: $break-large) {
        width: 10px;
        height: 10px;      
        margin-left: -1px;

      }
    }
  }

  &__next {
    left: 100%;
    margin-right: -10px;

    @media (min-width: $break-medium) {
      margin-right: -30px;
    }

    @media (min-width: $break-large) {
      right: -58px;
      margin-right: -50px;
    }
  }

  &__prev {
    margin-left: -10px;

    @media (min-width: $break-medium) {
      margin-left: -30px;
    }

    @media (min-width: $break-large) {
      margin-left: -50px;
    }

    &:after {
      transform: rotate(-135deg);
      margin-left: 3px;
    }
  }
}