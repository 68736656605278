/**
 * @file
 * News
 *
 * Contains styles for news and news view modes.
 */

 .news {
  &--full {
    position: relative;
    max-width: 80rem;
    margin: 0 auto;
    padding: 3rem 0 7.3rem;
    color: $color-white;

    @media (min-width: $break-medium) {
      padding: 7rem 0;
      font-size: 1.8rem;
    }

    @media (min-width: $break-large) {
      padding: 8.4rem 0 10.8rem;
    }

    &:before {
      content: "";
      @include fullwidth-with-sidebar();
      background: $color-dark-navy;
    }

    &__hero {
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      color: $color-white;

      h1 {
        padding: 5.6rem 2rem 1.87rem;
        font-size: 3.5rem;
        line-height: 1;

        @media (min-width: $break-medium) {
          padding: 7rem 3rem 2.4rem;
          font-size: 5rem;
        }

        @media (min-width: $break-large) {
          padding: 6.6rem 5.5rem 1.8rem;
          font-size: 8rem;
        }
      }

      &-date {
        padding: 0 2rem 3.2rem;

        @media (min-width: $break-medium) {
          padding: 0 3rem 4.2rem;
        }

        @media (min-width: $break-large) {
          padding: 0 5.7rem 3.7rem;
        }

        .date-label {
          padding: 0.6rem 1.2rem 0.4rem;
          font-size: 2rem;
          line-height: 2.34rem;
          font-weight: 500;
          background-color: $color-yellow;
          color: $color-dark-navy;
          display: inline-block;

          @media (min-width: $break-large) {
            font-size: 2.4rem;
            line-height: 2.813rem;
          }
        }
      }
    }

    .img-inline--full {
      margin: 2.6rem 0 4.2rem;

      @media (min-width: $break-medium) {
        margin: 3rem 0 4rem;
      }

      @media (min-width: $break-large) {
        margin: 3.2rem 0 2.7rem;
      }
      
      figcaption {
        padding-bottom: 0.9rem;
        margin: 1rem 0 0;
        line-height: 1.2;
        border-bottom-width: 1px;

        @media (min-width: $break-medium) {
          padding: 7.6rem 2rem 1.6rem;
        }

        @media (min-width: $break-large) {
          padding: 10rem 2rem 1.8rem;
        }
      }
    }
  }

  // Listing
  &--listing {
    position: relative;
    max-width: 79.8rem;
    padding: 3rem 0 5.4rem;
    margin: 0 auto;
    color: $color-white;

    @media (min-width: $break-medium) {
      padding: 7rem 0;
    }

    @media (min-width: $break-large) {
      padding: 5rem 0 10rem;
    }

    &:before {
      content: '';
      @include fullwidth-with-sidebar;
      background-color: $color-dark-navy;
    }

    .news {
      &__item {
        margin: 0 0 4.6rem;

        @media (min-width: $break-medium) {
          display: flex;
          align-items: flex-start;
          margin: 0 0 3.8rem;
        }

        @media (min-width: $break-large) {
          margin: 0 0 5rem;
        }

        &:last-child {
          margin: 0;
        }
      }

      &__image {
        position: relative;
        margin: 0 0 1.4rem;
        z-index: 1;

        @media (min-width: $break-medium) {
          flex-shrink: 0;
          width: 30.5%;
          margin: 0 2rem 0 0;
        }

        @media (min-width: $break-large) {
          width: 42.6%;
          margin: 0 3rem 0 0;
        }

        img {
          width: 100%;
          aspect-ratio: 280/147;
          object-fit: cover;

          @media (min-width: $break-medium) {
            aspect-ratio: 195/114;
          }

          @media (min-width: $break-large) {
            aspect-ratio: 340/193;
          }
        }
      }

      &__body {
        position: relative;
      }

      &__date {
        position: absolute;
        bottom: calc(100% + 1.4rem);
        color: $color-dark-navy;
        background-color: $color-yellow;
        font-size: 1.4rem;
        line-height: 1.6rem;
        padding: 0.4rem 1rem;
        font-weight: 500;
        z-index: 2;

        @media (min-width: $break-medium) {
          padding: 0.6rem 1rem 0.4rem;
          margin: 0 0 1.4rem;
          font-size: 1.6rem;
          line-height: 1.9rem;
          position: static;
          display: inline-block;
        }

        @media (min-width: $break-large) {
          margin: 0 0 2rem;
        }
      }

      &__title {
        @media (min-width: $break-medium) {
          margin: 0 0 0.4rem;
        }

        @media (min-width: $break-large) {
          margin: 0 0 0.3rem;
        }

        h2 {
          margin: 0;

          @media (min-width: $break-large) {
            line-height: 1;
          }

          a {
            color: $color-white;

            &:hover {
              color: $color-yellow;
            }

            &::after {
              border-color: currentColor;
            }
          }
        }
      }

      &__teaser {
        font-size: 1.8rem;
      }
    }
  }
 }