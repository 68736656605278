/**
 * @file
 * Homepage hero
 *
 * Contains styles for homepage hero object.
 */
 @keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
 }
 
.hero--home {
  position: relative;
  width: 100%;
  min-height: 24.7rem;
  display: flex;
  align-items: flex-end;
  background-color: $color-dark-navy;
  padding-top: 10rem;

  @media (min-width: $break-medium) {
    min-height: 36rem;
  }

  @media (min-width: $break-large) {
    @include full-width();
    min-height: 59.5rem;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(9, 69, 218, 0.1) 0%, rgba(9, 69, 218, 0.7) 100%);
    mix-blend-mode: multiply;
    z-index: 4;
  }

  .container {
    max-width: 191.3rem;
    margin: 0 auto;

    @media (min-width: $break-large) {
      padding-left: 7.5rem;
      padding-right: 3.8rem;
    }
  }

  &__fallback-image,
  .hero__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 2;
    opacity: 0;
    transition: opacity .3s ease .3s;
  }

  .hero__video {
    & ~ .hero--home__fallback-image {
      display: none;
    }
  }

  &.is-loaded {
    .hero--home__fallback-image,
    .hero__video {
      opacity: 1;
    }
  }

  &__decorations {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .hero__video {
    z-index: 3;
  }

  .content-box {
    width: 100%;
    margin-bottom: -2.3rem;
    position: relative;
    z-index: 5;

    @media (min-width: $break-medium) {
      margin-bottom: -3.7rem;
    }

    @media (min-width: $break-large) {
      margin-bottom: -5rem;
    }

    h1 {
      opacity: 0;
      margin: 0 0 2.1rem;
      animation: fadeIn 300ms 300ms forwards;

      @media (min-width: $break-medium) {
        max-width: 42rem;
        margin: 0 0 2.7rem;
      }

      @media (min-width: $break-large) {
        max-width: 70rem;
        margin: 0 0 5.3rem;
      }
    }
  }

  .video-controls {
    position: absolute;
    top: 4.1rem;
    left: 0;
    z-index: 6;
    width: 100%;

    @media (min-width: $break-medium) {
      text-align: right;
      top: auto;
      bottom: 6rem;
    }

    @media (min-width: $break-large) {
      bottom: 5rem;
    }

    .play-video-btn {
      position: relative;
      width: 3rem;
      height: 3rem;
      background-color: $color-white;
      border-radius: 50%;
      border: none;
      padding: 0;
      margin: 0;
      color: $color-black;
      transition: background-color .3s ease;

      @media (min-width: $break-large) {
        width: 4.5rem;
        height: 4.5rem;
      }

      &:hover {
        background-color: $color-yellow;
      }

      svg {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 16px;
        height: 14px;
        margin-top: -0.7rem;
        margin-left: -0.5rem;
        z-index: 2;
        transition: 0.2s all;
        opacity: 0;
      }
  

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -3px;
        border-width: 5px 0 6px 2px;
        border-color: $color-dark-navy;
        border-style: solid;
        transform: translate(-50%, -50%);
        transition: border .3s ease, margin .3s ease;

        @media (min-width: $break-large) {
          margin-left: -4px;
          border-width: 8px 0 8px 2px;
        }
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: 3px;
        border-width: 5px 0 6px 2px;
        border-color: $color-dark-navy;
        border-style: solid;
        transform: translate(-50%, -50%);
        opacity: 1;
        transition: opacity .3s ease;

        @media (min-width: $break-large) {
          margin-left: 5px;
          border-width: 8px 0 8px 2px;
        }
      }

      &.is-paused {

        svg {
          opacity: 1;
        }

        &:after,
        &:before {
          opacity: 0;
        }
      }
    }
  }

  .hero-type-text {
    position: relative;
    min-height: 7rem;
    padding: 0.5rem 1.7rem 0.5rem 0;
    margin-left: -0.2rem;
    font-size: 5rem;
    line-height: 6rem;
    font-weight: 900;
    text-transform: uppercase;
    color: $color-dark-navy;
    background-color: $color-yellow;
    display: inline-block;
    font-feature-settings: 'pnum' on, 'lnum' on;

    @media (min-width: $break-medium) {
      max-width: calc(100% - 7rem);
      padding: 0.5rem 3.6rem 0.5rem 0;
      font-size: 8rem;
      line-height: 9.6rem;
      min-height: 10.6rem;
    }

    @media (min-width: $break-large) {
      font-size: 12rem;
      line-height: 14rem;
      min-height: 15rem;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 100%;
      width: 1.8rem;
      height: 100%;
      background-color: $color-yellow;

      @media (min-width: $break-medium) {
        width: 3rem;
      }

      @media (min-width: $break-large) {
        width: 50vw;
      }
    }
  }

  &__box {
    .hero-links {
      position: relative;
      padding: 8.7rem 0 5.7rem;
      color: $color-white;

      @media (min-width: $break-medium) {
        padding: 10.7rem 0 8.7rem;
      }

      @media (min-width: $break-large) {
        padding: 16.8rem 0 15.2rem;
      }

      &:before {
        content: "";
        @include fullwidth-with-sidebar();
        background: $color-dark-navy;
      }

      &__list {
        @media (min-width: $break-medium) {
          display: flex;
          justify-content: space-between;
        }

        @media (min-width: $break-large) {
          padding: 0 3rem;
        }
      }

      &__item {
        margin: 0 0 3.4rem;

        @media (min-width: $break-medium) {
          margin: 0;
          flex-shrink: 0;
          width: calc(100%/3 - (6rem/3));
        }

        @media (min-width: $break-large) {
          width: calc(100%/3 - (8rem/3));
        }

        &:last-child {
          margin: 0;
        }
      }

      &__title {
        margin: 0 0 0.9rem;
        font-family: $font-roboto-condensed;
        font-size: 2.6rem;
        font-weight: 300;
        text-transform: uppercase;

        @media (min-width: $break-medium) {
          font-size: 3.6rem;
          line-height: 3.96rem;
        }

        @media (min-width: $break-large) {
          margin: 0 0 1.2rem;
          font-size: 4.8rem;
          line-height: 5.28rem;
        }
      }

      &__description {
        margin: 0 0 1.5rem;

        @media (min-width: $break-medium) {
          font-size: 1.8rem;
        }

        @media (min-width: $break-large) {
          margin: 0 0 2.3rem;
        }
      }

      &__cta {
        .cta--button {
          &:hover {
            color: $color-dark-navy;
          }
        }
      }
    }
  }
}