.more-programs {
  margin: 3.5rem 0 3.1rem;

  @media (min-width: $break-medium) {
    margin: 3.5rem 0 2.8rem;
  }

  @media (min-width: $break-large) {
    margin: 5.1rem 0 5.3rem;
  }

  &__wrapper {
    @media (min-width: $break-large) {
      display: flex;
      padding: 0 3.2rem;
    }
  }

  &__header {
    margin: 0 0 1.9rem;

    @media (min-width: $break-medium) {
      margin: 0 0 2.8rem;
    }

    @media (min-width: $break-large) {
      flex-shrink: 0;
      width: 50%;
      padding-right: 2rem;
      margin: 0;
    }

    h2 {
      margin: 0;

      @media (min-width: $break-large) {
        margin: 0 0 1.3rem;
      }
    }

    &--cta {
      display: none;

      a {
        @include focus-dark-navy();
      }

      @media (min-width: $break-large) {
        display: block;
      }
    }
  }

  &__list {
    margin: 0 0 2.4rem;

    @media (min-width: $break-large) {
      flex-shrink: 0;
      width: 50%;
      padding: 3.9rem 0 0 1.8rem;
    }
  }

  &__item {
    display: flex;
    margin: 0 0 0.9rem;

    @media (min-width: $break-medium) {
      margin: 0 0 1.5rem;
    }

    &:last-child {
      margin: 0;
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3rem;
      height: 3rem;
      margin: 0.1rem 1rem 0 0;
      background-color: $color-yellow;
      flex-shrink: 0;
      border-radius: 50%;

      @media (min-width: $break-medium) {
        width: 4rem;
        height: 4rem;
        margin: 0.1rem 1.2rem 0 0;
      }

      img {
        max-width: 1.6rem;

        @media (min-width: $break-medium) {
          max-width: 2.2rem;
        }
      }
    }

    .cta--link {
      margin: 0;
      color: $color-blue;
      letter-spacing: 0.2px;
      @include focus-dark-navy();

      @media (min-width: $break-medium) {
        letter-spacing: 0;
      }

      &:hover {
        color: $color-dark-navy;
      }

      &::after {
        border-color: currentColor;
      }
    }
  }

  &__link-holder {
    @media (min-width: $break-medium) {
      padding: 0.7rem 0 0;
    }
  }

  &__cta {
    @media (min-width: $break-large) {
      display: none;
    }
  }
}