.division-intro .story-display {
  padding: 0 2.5rem;

  @media (min-width: $break-medium) {
    width: 50%;
    padding: 0 2.5rem;
    margin: 0;
  }

  @media (min-width: $break-large) {
    max-width: 375px;
    padding: 0 3rem;
    margin: 0;

  }

  &:before {
    content: none;
  }

  .foreground-image {
    z-index: 12;
    @media (min-width: $break-medium) {
      top: 35px;
      height: calc(100% - 34px);
    }

    @media (min-width: $break-large) {
      top: 20px;
      height: calc(100% - 20px);
    }
  }

  img {
    z-index: -2;
  }

  .story-display__content {
    @media (min-width: $break-medium) {
      padding: 0;
    }

    @media (min-width: $break-large) {
      margin-right: -3rem;
    }
  }

  .story-display__img {
    margin: 0 -2.5rem 2.5rem;

    @media (min-width: $break-medium) {
      margin: 0 -2.5rem 2.5rem;
    }


    @media (min-width: $break-large) {
      margin: 0 -3rem 2.5rem;
    }

    a {
      display: inline-block;
      width: 100%;
      @include focus-inset();
    }

    .type-text {
      width: auto;
    }
  }

  .type-text {
    font-size: 4rem;
    width: 100%;
    padding: 0rem 1.2rem;
    @include focus-dark-navy();

    @media (min-width: $break-medium) {
      position: relative;
      width: auto;
      margin: 0 0 -25px;
    }

    @media (min-width: $break-large) {
      max-width: 320px;
      margin: -25px 0;
    }
  }

  .story-display__wrap {
    margin-top: 5rem;

    @media (min-width: $break-medium) {
      display: block;
      margin: 0;
      padding-bottom: 1.5rem;
    }

    &:before {
      width: auto;
      left: -25px;
      right: -25px;
      transform: translate(0);

      @media (min-width: $break-medium) {
        top: 50px;
        bottom: 0;
        height: auto;
      }

      @media (min-width: $break-large) {
        left: -30px;
        right: -30px;
      }
    }
  }

  .story-display__quote {
    font-size: 1.6rem;
    margin-bottom: 1rem;

    @media (min-width: $break-medium) {
      font-size: 1.8rem;
      padding: 0 0 0 2rem;
      margin-bottom: 0.5rem;
    }

    &:before {
      @media (min-width: $break-medium) {
        font-family: $font-bitter;
        font-size: 4rem;
        margin-top: -2px;
        text-indent: -2.7rem;
      }
    }
  }

  p {
    margin: 1rem 0;
  }

  .story-display__content {
    position: relative;
    background-color: $color-blue;
  }

  .story-display__quote-outer {
    position: static;

    &:before {
      @media (min-width: $break-medium) {
        top: 35px;
      }
    }
  }

  .story-display__caption {
    @media (min-width: $break-medium) {
      padding: 0 0 0 2rem;

      a {
        margin-bottom: 0;
      }
    }

    &:before {
      content: none;
    }
  }
}

.division-intro {
  position: relative;
  z-index: 1;

  @media (min-width: $break-medium) {
    display: flex;
    align-items: flex-start;
    gap: 4rem;
    margin-top: 5rem;
    margin-bottom: -3.5rem;

  }

  @media (min-width: $break-large) {
    margin-top: 7.5rem;
    margin-bottom: -5.5rem;
    margin-right: -50px;
  }

  .page-intro {
    @media (min-width: $break-medium) {
      width: 50%;
      margin: 0;
      padding-bottom: 8.5rem;
    }

    @media (min-width: $break-large) {
      width: calc(100% - 375px);
      padding-bottom: 11.5rem;
      max-width: none;
    }
  }

  .mini-story {
    @media (min-width: $break-medium) {
      margin-right: -3rem;
    }

    @media (min-width: $break-large) {
      margin-right: 0;
      margin-top: -2.5rem;
    }

    &__quote-box {
      &::before {
        top: 2.2rem;
  
        @media (min-width: $break-medium) {
          top: 2.2rem;
        }
  
        @media (min-width: $break-large) {
          top: 2.2rem;
        }
      }
    }
  }
}