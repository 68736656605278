.main-footer {
  color: $color-white;
  background-color: $color-dark-navy;

  @media (min-width: $break-large) {
    display: flex;
    flex-wrap: wrap;
  }

  .no-footer-sock & {
    @media (min-width: $break-large) {
      border-top: 1px solid $color-white;
    }
  }

  .container {
    max-width: 100%;
  }

  &__header {
    padding: 1.5rem 0 2.5rem;
    background-color: $color-white;

    @media (min-width: $break-medium) {
      padding: 1.5rem 0 1.5rem;
    }

    @media (min-width: $break-large) {
      width: 100%;
      max-width: 29.5rem;
      flex-shrink: 0;
      padding: 2.4rem 0 3.3rem;
    }

    .container {
      @media (min-width: $break-large) {
        padding-left: 3.6rem;
        padding-right: 3rem;
      }
    }

    a {
      background: none;
    }

    .content-box {
      @media (min-width: $break-medium) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      @media (min-width: $break-large) {
        flex-direction: column;
      }
    }
  }

  .social-list {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;

    @media (min-width: $break-large) {
      width: 100%;
      justify-content: flex-start;
      padding-left: 0.8rem;
    }

    &__item {
      padding: 0;
      margin: 0 2.8rem 0 0;
      list-style: none;

      @media (min-width: $break-large) {
        margin: 0 2.5rem 0 0;
      }

      &::marker {
        content: none;
        color: transparent;
        display: none;
      }

      &:last-child {
        margin: 0;
      }
    }

    .social-link {
      display: block;
      color: $color-blue;

      &:focus {
        @include focus-dark-navy();
      }

      &:hover {
        color: #1a1f50;
      }

      svg {
        display: block;
        max-width: 2.8rem;

        @media (min-width: $break-large) {
          max-width: 3.3rem;
          width: 3.3rem;
        }
      }
    }
  }

  &__logo {
    width: 120px;
    display: inline-block;
    margin-left: -0.4rem;
    margin-bottom: 0.7rem;
    opacity: 1;
    transition: opacity .3s ease;

    @media (min-width: $break-medium) {
      margin-bottom: 0;
    }

    @media (min-width: $break-large) {
      width: 100%;
      margin-bottom: 2rem;
    }

    &:hover {
      opacity: 0.8;
    }

    &:focus {
      @include focus-dark-navy();
    }


    img {
      @media (min-width: $break-large) {
        width: 100%;
      }
    }
  }

  &__info {
    padding: 3.4rem 0 2.5rem;

    @media (min-width: $break-medium) {
      padding: 2.9rem 0 2.8rem;
    }

    @media (min-width: $break-large) {
      width: 100%;
      max-width: calc(100% - 29.5rem);
      flex-shrink: 0;
      padding: 3.9rem 0 3.3rem;
    }

    .container {
      @media (min-width: $break-large) {
        padding-left: 7rem;
        display: flex;
        justify-content: center;
      }
    }

    .content-box {
      @media (min-width: $break-medium) {
        display: flex;
      }

      @media (min-width: $break-large) {
        width: 100%;
        max-width: 78.5rem;
        justify-content: space-between;
      }
    }

    .location {
      margin: 0 0 2.8rem;

      @media (min-width: $break-medium) {
        margin: 0 5.2rem 0 0;
      }

      @media (min-width: $break-large) {
        margin: 0 2rem 0 0;
      }

      a {
        background: none;
      }

      &__name {
        margin: 0 0 0.9rem;

        @media (min-width: $break-large) {
          margin: 0 0 0.7rem;
        }

        a {
          text-transform: uppercase;
          font-size: 1.6rem;
          line-height: 1.9rem;
          font-weight: 600;

          @media (min-width: $break-large) {
            font-size: 1.8rem;
            line-height: 1.3;
          }
        }
      }

      &__address {
        margin: 0 0 1.8rem;
        font-size: 1.6rem;
        line-height: 1.9rem;
        font-style: normal;
      }

      .contact-info {
        display: flex;
        align-items: center;
        margin: 0 0 1.1rem;

        &__icon {
          flex-shrink: 0;
          margin: 0 10px 0 0;
        }

        a {
          @media (min-width: $break-large) {
            font-size: 1.6rem;
            line-height: 1.9rem;
          }
        }
      }
    }

    .links-list {
      margin: 0;
      padding: 0.1rem 0 0;

      &__item {
        padding: 0;
        margin: 0 0 0.9rem;
        list-style: none;

        @media (min-width: $break-medium) {
          margin: 0 0 0.7rem;
        }

        @media (min-width: $break-large) {
          margin: 0 0 0.62rem;
        }

        &::marker {
          content: none;
          color: transparent;
        }

        a {
          font-weight: 600;
        }
      }
    }
  }

  &__bottom {
    padding: 1.3rem 0 1.4rem;
    border-top: 1px solid $color-white;

    @media (min-width: $break-large) {
      width: 100%;
    }

    .container {
      @media (min-width: $break-large) {
        padding-left: 4rem;
        padding-right: 4rem;
      }
    }

    .copyright {
      font-size: 1.2rem;
      line-height: 1.4rem;
    }
  }
}