/**
 * @file
 * Story Display
 *
 * Contains styles for Story Display object.
 */

.story-display {
  position: relative;
  color: $color-white;
  padding: 6rem 0 0;
  margin: 0;

  @media (min-width: $break-large) {
    padding: 5rem 0 0;
  }

  &:before {
    content: "";
    @include fullwidth-with-sidebar();
    background: $color-dark-navy;
  }

  &__intro {
    margin: 0 0 3.3rem;

    @media (min-width: $break-medium) {
      margin: 0 0 3rem;
    }

    @media (min-width: $break-large) {
      margin: 0 0 3.8rem;
    }
  }

  &__wrap {
    position: relative;
    padding-bottom: 2rem;

    &.inview {
      .story-display__quote-outer:before {
        transform: scaleY(1);
      }
  
      .story-display__caption:before {
        opacity: 1;
      }
    } 

    &.push-down {
      .story-display__content {
        @media (min-width: $break-medium) {
          margin-top: 6.3rem;
        }
      }
    }

    &:before {
      content: "";
      @include fullwidth-with-sidebar();
      background: $color-blue;
    }

    .type-text__wrap {
      width: 100%;
      margin-bottom: -23px;
      margin-left: -2rem;

      @media (min-width: $break-medium) {
        margin-bottom: -46px;
        margin-left: 0;
      }
    }

    @media (min-width: $break-medium) {
      display: flex;
      flex-wrap: wrap;
      margin: 5.5rem -3rem 0;
      padding-bottom: 0;
    }

    @media (min-width: $break-large) {
      margin: 8rem 0 0 0;
    }
  }

  &__img {
    margin: 0 -2rem 2.5rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (min-width: $break-medium) {
      flex: 0 0 50%;
      margin: 0;
    }

    > .story-display__images {
      display: block;
      position: relative;
      flex-grow: 1;
      width: 100%;
    }

    .foreground-image {
      z-index: 11;
      position: absolute;
      top: 11px;
      bottom: 0;
      height: calc(100% - 10px);

      @media (min-width: $break-medium) {
        top: 26px;
        height: calc(100% - 25px);
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @media (min-width: $break-medium) {
        margin-top: -1px;
        height: calc(100% + 1px);
      }
    }
  }

  &__content {
    @media (min-width: $break-medium) {
      flex: 0 0 50%;
      padding: 7.5rem 3rem 5rem 4rem;
    }

    @media (min-width: $break-large) {
      padding: 7.5rem 0rem 3.5rem 3.5rem;
      margin-right: -4rem;
    }
  }

  &__quote {
    margin: 0 0 1.5rem;
    padding: 0 0 0 2rem;
    font-family: $font-bitter;
    font-size: 2.4rem;
    font-weight: 500;
    position: relative;
    z-index: 1;

    @media (min-width: $break-medium) {
      font-size: 2.8rem;
      padding: 0 0 0 3rem;
      margin: 0 0 2.2rem;
    }

    @media (min-width: $break-large) {
      font-size: 3.4rem;
      padding: 0 0 0 2.5rem;
    }

    &:before {
      content: '“';
      line-height: 1.3;
      font-size: 4rem;
      display: inline-block;
      vertical-align: text-top;
      height: 0.5em;
      margin-top: -2px;
      text-indent: -2.7rem;

      @media (min-width: $break-medium) {
        font-family: $font-karla;
        font-size: 9rem;
        margin-top: -0.3em;
        text-indent: -4.6rem;
      }

      @media (min-width: $break-large) {
        font-size: 5.8rem;
        text-indent: -3.6rem;
      }
    }

    &:after {
      content: '”';
      line-height: 1.3;
      display: inline-block;
      vertical-align: text-top;
      height: 0.5em;
      margin-top: -2px;
    }
  }

  &__subtitle {
    color: $color-black;
    font-size: 1.4rem;
    font-weight: 500;
    background-color: $color-white;
    position: absolute;
    z-index: 11000;
    bottom: 0;
    padding: 0.3rem 1rem;

    @media (min-width: $break-large) {
      padding: 0.3rem 1.3rem;

    }
  }

  &__caption {
    padding-left: 2rem;

    @media (min-width: $break-medium) {
      padding-left: 3rem;
      position: relative;
    }

    @media (min-width: $break-large) {
      padding-left: 2.5rem;
    }

    &:before {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      left: -10px;
      opacity: 0;
      transition: all 300ms 500ms;
      background: url(../assets/icons/info.svg) no-repeat center / 100%;

      @media (min-width: $break-medium) {
        width: 26px;
        height: 26px;
        left: -12px;
      }

      @media (min-width: $break-large) {
        width: 16px;
        height: 16px;
        left: -6px;
        top: 2px;
      }
    }
  }

  p {
    margin: 1.5rem 0;

    @media (min-width: $break-large) {
      margin: 2rem 0 3rem;
    }
  }

  &__quote-outer {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 35px;
      bottom: 0;
      left: 0;
      transform: scaleY(0);
      transform-origin: top;
      transition: all 500ms;
      border-right: 1px solid;

      @media (min-width: $break-medium) {
        top: 40px;
      }

      @media (min-width: $break-large) {
        top: 30px;
      }
    }
  }

 
}
