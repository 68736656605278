.main-header {
  position: sticky;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 100vw;
  border-bottom: 1px solid $color-white;
  background-color: $color-dark-navy;
  z-index: 3001;
  transform: translateY(0);
  transition: transform .3s ease;
  
  @media (min-width: $break-large) {
    border-bottom: none;
  }

  &.header-hidden {
    transform: translateY(-100%);

    .main-menu-active & {
      transform: translateY(0);
    }
  }

  &.sticky-header:not(.header-hidden) {
    // header bottom white line developed as :before to prevent header jump on slide animation
    &:before {
      content: '';
      position: absolute;
      bottom: -1px;
      width: 100%;
      height: 1px;
      background-color: $color-white;
      display: none;
    }

    @media (min-width: $break-large) {
      &:before {
        display: block;
      }
    }
  }

  *::before,
  *::after {
    box-sizing: border-box;
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      padding: 0;
      margin: 0;
      list-style: none;

      &::marker {
        content: none;
        color: transparent;
      }
    }
  }

  a {
    background: none;
    text-decoration: none;
    display: inline-block;
  }

  &__content-holder {
    display: flex;
    align-items: center;

    @media (min-width: $break-large) {
      align-items: stretch;
    }

    @media (min-width: 1200px) {
      align-items: flex-start;
    }
  }

  &__logo {
    width: 100%;
    max-width: 11.4rem;
    flex-shrink: 0;
    background-color: $color-white;
    margin-right: auto;
    opacity: 1;
    visibility: visible;
    transition: opacity .3s ease, visibility .3s ease;

    @media (min-width: $break-medium) {
      max-width: 15.5rem;
    }

    @media (min-width: $break-large) {
      max-width: 27.3rem;
      flex-shrink: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media (min-width: 1200px) {
      flex-shrink: 0;
      display: block;
    }

    .search-opened & {
      opacity: 0;
      visibility: hidden;

      @media (min-width: $break-medium) {
        opacity: 1;
        visibility: visible;
      }
    }

    a {
      display: block;
      padding: 0 0.8rem;

      @media (min-width: $break-medium) {
        padding: 0.2rem 2rem 0.1rem 2rem;
      }

      @media (min-width: $break-large) {
        padding: 0.7rem 1.1rem 0.9rem 1.3rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      @media (min-width: 1200px) {
        padding: 0.7rem 3.1rem 0.9rem 3.3rem;
        display: block;
      }
    }

    img {
      width: 100%;
    }
  }

  &__menus {
    position: fixed;
    overflow-y: auto;
    top: var(--main-header-height);
    left: 0;
    width: 100%;
    height: calc(100vh - var(--main-header-bottom-offset));
    background-color: $color-dark-navy;
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    transform: translateX(100%);
    transition: opacity 0s ease .3s , visibility 0s ease 3s, transform .3s ease;

    @media (min-width: $break-medium) {
      left: auto;
      right: 0;
      max-width: 37.5rem;
      padding: 0 3rem;
    }

    @media (min-width: $break-large) {
      max-width: calc(100% - 15.8rem);
      flex-shrink: 0;
      width: 100%;
      position: static;
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-start;
      height: auto;
      padding: 0 2rem 0 2rem;
      background-color: transparent;
      opacity: 1;
      visibility: visible;
      transform: translateX(0);
      z-index: 2;
      overflow: visible;
      transition: opacity .3s ease , visibility .3s ease, transform .3s ease;
    }

    @media (min-width: 1200px) {
      max-width: calc(100% - 27.3rem);
      padding: 0 2rem 0 5rem;
    }

    .search-opened & {
      @media (min-width: $break-large) {
        opacity: 0;
        visibility: hidden;
      }
    }

    .main-menu-active & {
      opacity: 1;
      visibility: visible;
      transform: translateX(0);
      transition: opacity 0s ease 0s , visibility 0s ease 0s, transform .3s ease;
    }
  }

  .menus-opener {
    position: relative;
    width: 3.5rem;
    height: 3.5rem;
    background: $color-blue;
    border: none;
    opacity: 1;
    visibility: visible;
    transition: background-color .3s ease, opacity .3s ease, visibility .3s ease;

    @media (min-width: $break-medium) {
      width: 4rem;
      height: 4rem;
    }

    &__holder {
      @media (min-width: $break-large) {
        display: none;
      }
    }

    &:hover {
      background: $color-gray-blue;

      .icon {
        color: $color-dark-navy;
      }

      &:before,
      &:after {
        color: $color-dark-navy;
      }
    }
    
    .icon {
      color: $color-white;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 1.75rem;
      border-bottom: 1.5px solid;
      background-color: $color-white;
      transition: background-color .3s ease;

      @media (min-width: $break-medium) {
        width: 2rem;
        border-bottom: 2px solid;
      }
    }

    &:before,
    &:after {
      content: '';
      color: $color-white;
      position: absolute;
      left: 50%;
      top: 50%;
      display: block;
      width: 1.75rem;
      border-top: 1.5px solid;
      transition: transform .3s ease, background-color .3s ease;
      z-index: 1;

      @media (min-width: $break-medium) {
        width: 2rem;
        border-top: 2px solid;
      }
    }

    &:before {
      transform: translate(-50%, calc(-50% + 0.7rem));

      @media (min-width: $break-medium) {
        transform: translate(-50%, calc(-50% + 0.8rem));
      }
    }

    &:after {
      transform: translate(-50%, calc(-50% - 0.7rem));

      @media (min-width: $break-medium) {
        transform: translate(-50%, calc(-50% - 0.8rem));
      }
    }

    &.main-menu-active {
      background-color: $color-white;

      &:before,
      &:after {
        color: $color-dark-navy;
        transform: translate(-50%, -50%) rotate(-45deg);
      }

      &:after {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      .icon {
        border: 0;
      }

      &:hover {
        background: $color-gray-blue;
      }
    }

    .search-opened & {
      opacity: 0;
      visibility: hidden;
    }
  }

  // styles for main menu placed in main header

  .main-menu {
    &__list {
      position: relative;
      padding: 3.3rem 0 2.8rem;

      @media (min-width: $break-medium) {
        padding: 5.5rem 0 4.5rem;
      }

      @media (min-width: $break-large) {
        padding: 0 0 0;
        display: flex;
      }

      &:after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0;
        width: calc(100% - 4rem);
        height: 0.2rem;
        background-color: $color-blue;
        transform: translateX(-50%);

        @media (min-width: $break-medium) {
          width: 100%;
        }

        @media (min-width: $break-large) {
          display: none;
        }
      }
    }

    &__item {
      position: relative;

      &:not(:last-child) {
        margin: 0 0 2.5rem;

        @media (min-width: $break-medium) {
          // margin: 0 0 2.3rem;
        }

        @media (min-width: $break-large) {
          margin: 0;
        }
      }

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: -0.2rem;
        width: calc(100% - 3.1rem);
        height: 0.6rem;
        background-color: $color-yellow;
        display: none;
        opacity: 0;
        transition: opacity .3s ease;
        z-index: -1;

        @media (min-width: $break-large) {
          display: block;
        }
      }

      &:nth-last-child(1):not(:nth-child(1)),
      &:nth-last-child(2):not(:nth-child(2)) {
        @media (min-width: $break-large) {
          .submenu {
            left: auto;
            right: 3.3rem;
          }
        }
      }

      &:nth-last-child(1):not(:nth-child(1)) {
        &::before {
          @media (min-width: $break-large) {
            width: 100%;
            right: 0;
            left: auto;
          }
        }

        .main-menu__link {
          @media (min-width: $break-large) {
            padding-right: 0;
          }
        }

        .submenu {
          @media (min-width: $break-large) {
            right: 0;
          }
        }
      }

      &.submenu-show {
        &::before {
          @media (min-width: $break-large) {
            opacity: 1;
          }
        }
        
        .submenu {
          @media (min-width: $break-large) {
            opacity: 1;
            visibility: visible;
            transition: opacity .3s ease .2s, visibility .3s ease .2s;
          }
        }
      }

    }

    &__link {
      width: calc(100% - 55px);
      margin: 0;
      padding: 0 0 0.7rem 2rem;
      font-family: $font-roboto-condensed;
      font-size: 2.2rem;
      line-height: 2.6rem;
      letter-spacing: 0.07rem;
      font-weight: 700;
      text-transform: uppercase;
      color: $color-white;
      transition: color .3s ease;

      @media (min-width: $break-medium) {
        padding: 0 0 0.7rem 0rem;
      }

      @media (min-width: $break-large) {
        width: auto;
        margin: 0;
        padding: 1.5rem 3.5rem 2.1rem 0;
        font-size: 2.1rem;
        line-height: 2.5rem;
      }

      &:hover {
        color: $color-yellow;
      }
    }
  }

  .main-submenu {
    &__opener {
      position: absolute;
      right: 2rem;
      top: -0.2rem;
      border: none;
      background-color: $color-blue;
      width: 3rem;
      height: 3rem;
      transition: background-color .3s ease;

      @media (min-width: $break-medium) {
        right: 0;
        top: -0.5rem;
        width: 3.5rem;
        height: 3.5rem;
      }

      @media (min-width: $break-large) {
        display: none;
      }

      &:before {
        color: $color-white;
        content: '';
        position: absolute;
        top: 1.3rem;
        left: 50%;
        width: 0.9rem;
        height: 0.9rem;
        border-right: 0.15rem solid;
        border-bottom: 0.15rem solid;
        transform: translate(-50%, -50%) rotate(45deg);
        transition: top .3s ease, transform .3s ease, border-color .3s ease;

        @media (min-width: $break-medium) {
          top: 1.6rem;
          width: 1rem;
          height: 1rem;
          border-right: 0.2rem solid;
          border-bottom: 0.2rem solid;
        }
      }

      &.submenu-opened {
        background-color: $color-white;

        &:before {
          color: $color-dark-navy;
          top: 1.6rem;
          border-right: 0.15rem solid;
          border-bottom: 0.15rem solid;
          transform: translate(-50%, -50%) rotate(225deg);

          @media (min-width: $break-medium) {
            top: 1.9rem;
            border-right: 0.2rem solid;
            border-bottom: 0.2rem solid;
          }
        }
      }
    }
  }

  .submenu {
    display: none;
    border-top: 0.6rem solid $color-yellow;
    background-color: $color-white;
    padding: 2rem 0;
    margin: 0 0 3.5rem;

    @media (min-width: $break-medium) {
      margin: 0 0 2.8rem;
    }

    @media (min-width: $break-large) {
      position: absolute;
      margin: 0;
      opacity: 0;
      visibility: hidden;
      top: 100%;
      width: 26.4rem;
      left: -0.2rem;
      border-top: none;
      transition: opacity .3s ease, visibility .3s ease;
    }

    &__item {
      &:not(:last-child) {
        margin: 0 0 1.2rem;
      }
    }

    &__link {
      width: 100%;
      padding: 0 2rem;
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-weight: 400;
      color: $color-white;
      color: $color-dark-navy;
      @include focus-dark-navy();

      @media (min-width: $break-medium) {
        font-size: 1.8rem;
        line-height: 2.4rem;
      }

      &:hover {
        color: $color-blue;
        text-decoration: underline 1px;
        text-underline-offset: 2px;
        text-decoration-skip-ink: none
      }
    }
  }

  // styles for secondary menu placed in main header

  .secondary-menu {
    padding: 2.9rem 0;

    @media (min-width: $break-medium) {
      padding: 5.1rem 0;
    }

    @media (min-width: $break-large) {
      padding: 1.8rem 0 0;
      margin: 0 0 1.5rem;
      border-bottom: 2px solid $color-blue;
    }

    &__list {
      @media (min-width: $break-large) {
        display: flex;
        padding: 0 0 0;
      }
    }

    &__item {
      &:not(:last-child) {
        margin: 0 0 2.5rem;

        @media (min-width: $break-medium) {
          margin: 0 0 1.3rem;
        }

        @media (min-width: $break-large) {
          position: relative;
          margin: 0 3.55rem 0 0;
        }
      }

      .submenu {
        @media (min-width: $break-large) {
         left: 0;
        }
      }

      &:nth-last-child(1):not(:nth-child(1)),
      &:nth-last-child(2):not(:nth-child(2)) {
        @media (min-width: $break-large) {
          .submenu {
            left: auto;
            right: 0;
          }
        }
      }
    }

    &__link,
    .secondary-submenu__opener {
      border: none;
      padding: 0 1.8rem 0 0;
      font-size: 1.8rem;
      line-height: 2.1rem;
      margin: 0 0 0.7rem 2rem;
      font-family: $font-roboto;
      font-weight: 400;
      color: $color-white;
      background: none;
      transition: color .3s ease, border-color .3s ease;

      @media (min-width: $break-medium) {
        margin: 0 0 0.7rem 0;
      }

      @media (min-width: $break-large) {
        margin: 0;
        padding: 0 1.6rem 0.5rem 0;
        font-size: 1.6rem;
        line-height: 1.9rem;
      }

      &:hover {
        color: $color-yellow;

        &:before {
          border-color: $color-yellow;
        }
      }
    }

    &__link {
      @media (min-width: $break-large) {
        padding: 0;
      }
    }

    .submenu {
      margin: 0 0 3rem;
      border-width: 0.4rem;

      @media (min-width: $break-medium) {
        margin: 0 0 2rem;
      }
    }
  }

  .secondary-submenu {
    display: none;
    border-width: 0.4rem;

    @media (min-width: $break-large) {
      position: absolute;
      left: 0;
      top: 100%;
    }

    &__opener {
      display: inline-block;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 0.7rem;
        right: 0.5rem;
        width: 0.6rem;
        height: 0.6rem;
        border-right: 0.15rem solid $color-white;
        border-bottom: 0.15rem solid $color-white;
        transform: rotate(45deg);
        transition: transform .3s ease, top .3s ease, background-color .3s ease;

        @media (min-width: $break-large) {
          border-right: 0.13rem solid $color-white;
          border-bottom: 0.13rem solid $color-white;
          width: 0.5rem;
          height: 0.5rem;
        }
      }

      &::after {
        content: '';
        display: none;
        position: absolute;
        bottom: -0.1rem;
        left: 0;
        opacity: 0;
        transition: opacity .3s ease;
        width: 100%;
        height: 0.4rem;
        background-color: $color-yellow;

        @media (min-width: $break-large) {
          display: block;
        }
      }

      &.submenu-opened {
        &:before {
          top: 0.8rem;
          transform: rotate(225deg);
        }

        &::after {
          @media (min-width: $break-large) {
            opacity: 1;
          }
        }

        & + .submenu {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  // styles for search placed in main header
  .search {
    &__box {
      padding: 0 1.7rem 0 1.5rem;

      @media (min-width: $break-medium) {
        padding: 0 2.2rem 0 2rem;
      }
    }

    &-submit-button {
      color: $color-black;
      position: absolute;
      right: -2.3rem;
      top: 1.6rem;
      transition: right .3s ease, color .3s ease;
      z-index: 10;

      @media (min-width: $break-medium) {
        top: 2.2rem;
        right: -3.1rem;
      }

      @media (min-width: $break-large) {
        top: 2.75rem;
        right: -1.1rem;
        transition: top 0.3s ease 0.3s, right 0.3s ease, background-color 0.3s ease;
      }

      .search-opened & {
        right: 1.25rem;

        @media (min-width: $break-medium) {
          right: 1.75rem;
          top: 2.55rem;
        }

        @media (min-width: $break-large) {
          right: 3.65rem;
          background-size: 1.8rem 1.8rem;
          top: 5.2rem;
          transition: right .3s ease .3s, top .3s ease, background-color .3s ease;
        }
      }


    }


    &__opener,
    &__submit {
      height: 3.5rem;
      width: 3.5rem;
      background-color: $color-yellow;
      transition: background-color .3s ease;
      border: none;
      border-radius: 50%;
      padding: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (min-width: $break-medium) {
        width: 4rem;
        height: 4rem;
        padding: 1.2rem;
      }

      @media (min-width: $break-large) {
        position: absolute;
        top: 1.2rem;
        right: 5.2rem;
        width: 4.5rem;
        height: 4.5rem;
        z-index: 3;
      }

      &:hover {
        background-color: $color-light-gray;
      }

      span,
      img {
        display: block;
      }
    }

    &__opener {
      position: relative;
      transition: none;

      .search__icon {
        display: flex;
        color: $color-black;
      }

      @media (min-width: $break-large) {
        position: absolute;
        transition: top .3s ease .3s, right .3s ease, background-color .3s ease;
      }

      .search-opened & {
        @media (min-width: $break-large) {
          top: 4rem;
          right: 3.1rem;
          transition: top .3s ease, right .3s ease .3s;
        }

        .search__icon {
          display: none;
        }

      
      }

      &:before,
      &:after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        display: block;
        width: 1.75rem;
        border-top: 1.75px solid;
        background-color: $color-white;
        opacity: 0;
        transition: opacity .3s ease;
        z-index: 1;

        @media (min-width: $break-large) {
          width: 2.45rem;
          border-top: 2px solid;
        }
      }

      .search-opened & {
        background-color: transparent;
        color: white;
      }

      &:before,
      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);

        .search-opened & {
          opacity: 1;
        }
      }

      &:after {
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }

    &__form {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: calc(100% - 5rem);
      padding: 1.1rem 4.5rem 0 2rem;
      opacity: 0;
      visibility: hidden;
      transition: opacity .3s ease, visibility .3s ease;
      z-index: 3;

      @media (min-width: $break-medium) {
        left: 15.5rem;
        width: calc(100% - 22rem);
        padding: 2.1rem 5rem 0 2rem;
      }

      @media (min-width: $break-large) {
        left: 27.3rem;
        width: calc(100% - 35rem);
        padding: 4.9rem 2.2rem 0 3rem;
      }

      .search-opened & {
        opacity: 1;
        visibility: visible;
        transition: none;
      }
    }

    &__input {
      width: 100%;
      padding: 0 0 0.5rem;
      border: none;
      border-bottom: 1px solid $color-white;
      background: transparent;
      color: $color-white;
      font-size: 1.8rem;
      line-height: 2.3rem;
      font-family: $font-roboto;
      appearance: none;
      border-radius: 0;

      @media (min-width: $break-large) {
        padding: 0 0 1.1rem;
        font-size: 2.5rem;
        line-height: 3.2rem;
      }

      &::placeholder {
        color: $color-white;
        opacity: 0.85;
      }
    }


    &__submit {
      position: absolute;
      right: -3.3rem;
      top: 0.75rem;
      transition: right .3s ease, background-color .3s ease, background-color .3s ease;
      font-size: 0;

      @media (min-width: $break-medium) {
        top: 1.46rem;
        right: -4.3rem;
      }

      @media (min-width: $break-large) {
        top: 1.46rem;
        right: -2.5rem;
        transition: top 0.3s ease 0.3s, right 0.3s ease, background-color 0.3s ease;
      }


      .search-opened & {
        right: 0.4rem;

        @media (min-width: $break-medium) {
          right: 0.7rem;
          top: 1.45rem;
        }

        @media (min-width: $break-large) {
          right: 2.4rem;
          top: 1.2rem;
          background-size: 1.8rem 1.8rem;
          top: 3.9rem;
          transition: right .3s ease .3s, top .3s ease, background-color .3s ease;
        }
      }
    }
  }
}

.main-menu-active .sticky-header {
  position: fixed;

  .main-header__menus {
    height: calc(100vh - var(--main-header-height));
  }
}
